import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, IconButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Navigator } from 'src/services';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import NoSearchIcon from 'src/components/IconsCommon/NoSearchIcon';
import { LoadingCommon } from 'src/components/common';
import { SYSTEM_SEARCH_LOCALSTORAGE } from 'src/containers/SystemSearch/helpers';
import { ItemSearch } from 'src/queries/SystemSearch/types';
import { useGetSearchAllDocuments } from 'src/queries/SystemSearch/useGetSearchAllDocuments';
import { hideDialog } from 'src/redux/dialog/dialogSlice';
import { isEmpty } from 'src/validations';
import SearchPreviewItem from './SearchPreviewItem';
import { MODULE_CONTENT } from './helpers';
import './styles.scss';
import { useProfile } from 'src/queries';

enum QUERY_KEY {
  SEARCH = 'gSearch',
}

const GlobalSearch: React.FC<Props> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('xs'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenContent, setIsOpenContent] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchText = query.get(QUERY_KEY.SEARCH);

  const [searchValue, setSearchValue] = useState(searchText ?? '');
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);

  dayjs.extend(relativeTime);

  const { searchDocuments, setParams, isFetching } = useGetSearchAllDocuments({
    onSuccess(data) {},
  });

  const combineContentList = searchDocuments
    ?.filter((module) => MODULE_CONTENT.includes(module.moduleName))
    ?.reduce((result, item) => result.concat(item.data), []);
  const userList = searchDocuments?.find((module) => module.moduleName === 'user');

  const showContentList: ItemSearch[] = combineContentList?.slice(0, 10);
  const showUserList: ItemSearch[] = userList?.data?.slice(0, 10);

  const handleOpenSearch = () => {
    setIsOpen(true);
    inputRef.current.focus();
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpenContent(false);
    }
  };

  const handleClearSearchValue = () => {
    setSearchValue('');
    localStorage.removeItem(SYSTEM_SEARCH_LOCALSTORAGE);
  };

  const handleTextChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    debounceValue(value, query);
  };

  const onSearch = (value: string, queryKey) => {
    setIsOpenContent(true);
    if (!isEmpty(value)) {
      setParams({ search: value });
    }
  };

  const debounceValue = useCallback(debounce(onSearch, 300), []);
  const { profile } = useProfile();
  const handleClickSearchFilter = (id: string) => {
    if (id === profile?.id) {
      Navigator.navigate(`${PATHS.myProfile}`);
    } else {
      Navigator.navigate(`${PATHS.admin}${PATHS.userListing}/${id}`);
    }
    setIsOpenContent(false);
  };

  const getSearchTitle = (item: ItemSearch) => {
    const { _source } = item;
    switch (item._index) {
      case 'team':
      case 'lead':
        return _source.name;
      case 'deal':
      case 'member':
      case 'er_guard_user':
        return _source.fullName;
      default:
        return _source.name;
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isEmpty(searchValue)) return;
      setIsOpenContent(false);
      query.delete(QUERY_KEY.SEARCH);
      query.set(QUERY_KEY.SEARCH, searchValue.trim());
      history.push({ search: query.toString() });
      Navigator.navigate(`${PATHS.systemSearch}?${QUERY_KEY.SEARCH}=${searchValue}`, {
        previousPath: location.pathname,
      });
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      setIsOpen(true);
    } else {
      location.pathname !== PATHS.systemSearch && setIsOpen(false);
      localStorage.removeItem(SYSTEM_SEARCH_LOCALSTORAGE);
    }
  }, [searchValue, location.pathname]);

  return (
    <div style={{ position: 'relative', display: 'flex' }}>
      <div
        id="search-preview"
        ref={wrapperRef}
        style={{
          opacity: isOpen ? 1 : 0,
          transform: isOpen ? 'translateX(30px)' : 'translateX(-999px)',
          zIndex: 999,
        }}
      >
        <Paper
          component={'form'}
          style={{
            width: isXl ? 600 : isLgDesktop ? 500 : isMd ? 300 : isMobile ? 250 : 250,
            border: '1px solid #cccccc',
            boxShadow: 'none',
            borderRadius: '8px',
          }}
        >
          <IconButton type="button" sx={{ p: '7px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <input
            style={{ width: isXl ? '90%' : isLgDesktop ? '80%' : isMobile ? '70%' : '50%' }}
            ref={inputRef}
            id="input-search"
            placeholder="Search"
            value={searchValue}
            onChange={handleTextChange}
            // inputProps={{ 'aria-label': 'search' }}
            onKeyDown={handleKeyDown}
          />
          {searchValue && (
            <IconButton
              type="button"
              sx={{ p: 0 }}
              aria-label="close"
              onClick={handleClearSearchValue}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Paper>
        {!isEmpty(searchValue) && isOpenContent && (
          <Paper className="content-search">
            {isFetching ? (
              <div
                style={{
                  minHeight: 400,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LoadingCommon loadingStyle={6} size="normal" style={{ minHeight: '28px' }} />
              </div>
            ) : userList?.totalRecord + combineContentList?.length ? (
              <>
                <div className="content-data">
                  <div className="title">{`CONTENT ${
                    combineContentList.length > 99 ? '99+' : combineContentList.length
                  }`}</div>
                  <ul className="list">
                    {showContentList?.map((item) => (
                      // <div
                      //   role="button"
                      //   tabIndex={0}
                      //   className="card-search"
                      //   onClick={handleClickSearchFilter}
                      //   onKeyDown={handleClickSearchFilter}
                      // >
                      //   <ReceiptIcon />
                      //   <div className="content-des">
                      //     <Typography fontSize={14} fontWeight={400}>
                      //       {getSearchTitle(item)}
                      //     </Typography>
                      //     <div className="des">{`${convertText(item._index)} - ${
                      //       item._source.updatedAt
                      //         ? dayjs(item._source.updatedAt).fromNow()
                      //         : dayjs(item._source.createdAt).fromNow()
                      //     }`}</div>
                      //   </div>
                      // </div>
                      <SearchPreviewItem data={item} setOpen={setIsOpenContent} />
                    ))}
                  </ul>
                </div>
                <div className="content-people">
                  <div className="title">{`PEOPLE ${
                    userList?.totalRecords > 99 ? '99+' : userList?.totalRecord
                  }`}</div>
                  <ul className="content">
                    {showUserList?.map((item) => (
                      <div
                        className="people-item"
                        role="button"
                        tabIndex={0}
                        onClick={() => handleClickSearchFilter(item._id)}
                        onKeyDown={() => handleClickSearchFilter(item._id)}
                      >
                        <Avatar
                          src={IMAGES.defaultUser}
                          variant="rounded"
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        <Typography>{item?._source?.fullName}</Typography>
                      </div>
                    ))}
                  </ul>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: '50px',
                }}
              >
                <NoSearchIcon />
                <Typography color={'#6D7176'}>
                  We couldn't find anything matching your search.
                </Typography>
                <Typography color={'#6D7176'}>Try again with a different term.</Typography>
              </div>
            )}
          </Paper>
        )}
      </div>
      <IconButton
        className="btn-search"
        onClick={handleOpenSearch}
        style={{
          visibility: !isOpen ? 'visible' : 'hidden',
          opacity: !isOpen ? 1 : 0,
        }}
      >
        <SearchIcon />
      </IconButton>
    </div>
  );
};

type Props = typeof mapDispatchToProps & { children?: any };

const mapDispatchToProps = {
  onCloseDialog: hideDialog,
};

export default connect(null, mapDispatchToProps)(GlobalSearch);
