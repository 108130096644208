import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { StaffUser, User } from './types';

export function useStaffUser(
  options?: UseQueryOptions<ApiResponseType<{ data: StaffUser }>, Error, StaffUser> & {
    userId: User['id'];
  }
) {
  const {
    data,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetStaffUserById,
  } = useQuery<ApiResponseType<{ data: StaffUser }>, Error, StaffUser>(
    [API_QUERIES.STAFF_USER, { id: options.userId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ data: StaffUser }>>(
          apiClient.getStaffUser,
          params
        );
      },
      select(data) {
        return data.data.data;
      },
      enabled: !!options.userId,
      staleTime: 2000,
      ...options,
    }
  );

  return {
    data,
    error,
    isError,
    isLoading,
    onGetStaffUserById,
  };
}
