import { CancelToken } from 'apisauce';
import cn from 'classnames';
import Compressor from 'compressorjs';
import dayjs from 'dayjs';
import { parse } from 'qs';
import {
  formatPhoneNumber as formatLocalPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import shortid from 'shortid';

import { Location } from 'history';
import appConfig from 'src/appConfig';
import { isEmpty } from 'src/validations';
// import { AccountStatus, StatementStatus } from 'src/redux/account/types';
import _ from 'lodash';
import { GOVERNMENT_LIST } from 'src/containers/SaleContainers/DealManagement/CRUDealContainer/DealGeneralInfo/helpers';
import { Deal } from 'src/queries/Deals/types';
import { Lead } from 'src/queries/Leads/types';

export const handleGetError = (touched, errors, prefix) =>
  _.get(touched, prefix) ? _.get(errors, prefix) : '';

export const waiter = (time: number = 100) =>
  new Promise<Array<any>>((res) => setTimeout(() => res([]), time));
// import vnLocale from 'dayjs/locale/vi';
// var updateLocale = require('dayjs/plugin/updateLocale');
// export const updateVnLocale = () => {
//   dayjs.extend(updateLocale);
//   const newLocale = {
//     ...vnLocale,
//     meridiem: (hour, minute, isLowercase) => {
//       return hour >= 12 ? 'CH' : 'SA';
//     },
//   };
//   dayjs?.['updateLocale']('vi', newLocale);
// };

export function newCancelToken(timeout = appConfig.CONNECTION_TIMEOUT) {
  const source = CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);

  return { cancelToken: source.token };
}

export const getRandomId = (): string => shortid.generate();

export const generateArray = (length: number, initial = '') => Array(length).fill(initial);

export const stringify = (params: { [key: string]: number | string | string[] }) => {
  let result = '';

  Object.keys(params).forEach((key) => {
    if (!isEmpty(params[`${key}`])) {
      if (Array.isArray(params[`${key}`])) {
        let array = params[`${key}`] as string[];
        array.forEach((param: string) => {
          result += `&${key}=${encodeURIComponent(param)}`;
        });
      } else {
        result += `&${key}=${encodeURIComponent(params[`${key}`].toString())}`;
      }
    }
  });

  result = result.replace(/^&/, '');

  return result;
};

export const getYesNo = (value: boolean, highLightValue = 'YES') => {
  if (isEmpty(value)) return '';
  const result = value ? 'YES' : 'NO';
  const isHighLight = highLightValue === result;
  return <span className={cn({ 'has-text-danger': isHighLight })}>{result}</span>;
};

export const getLocationState = (location: Location<string>) => {
  const locationState = location.state;
  const state = parse(locationState, { ignoreQueryPrefix: true });

  return state;
};

export const formatPhoneNumber = (mobile: string) => {
  if (!mobile) return '';
  try {
    if (mobile.startsWith('+1')) return `+1 ${formatLocalPhoneNumber(mobile)}`;
    return formatPhoneNumberIntl(mobile);
  } catch (error) {
    return '';
  }
};

export const formatPhoneNumberWithCountryCode = (mobile: string) => {
  if (!mobile) return '';
  try {
    if (mobile.startsWith('+1')) {
      return `+1 ${formatLocalPhoneNumber(mobile)}`;
    } else {
      const cutPhone = formatPhoneNumberIntl(mobile).split(' ');
      const result = `(${formatPhoneNumberIntl(mobile).split(' ')[0]}) ${cutPhone
        .slice(1, cutPhone.length)
        .join(' ')}`;
      return result;
    }
  } catch (error) {
    return '';
  }
};

export const formatDate = (value: string, format: string = 'MM/DD/YYYY') => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const formatDateWithTimeZone = (value: string, format: string = 'MM/DD/YYYY') => {
  var customDayjs = require('dayjs');
  var timezone = require('dayjs/plugin/timezone');
  customDayjs.extend(timezone);
  return customDayjs().tz('US/Hawaii').format(format);
};

export const formatShowSSN = (value: string, from: number = 6) => {
  return `*******${value.slice(from)}`;
};

export const getWeekDay = (value: string) => {
  if (!value) return '';
  return dayjs(value).format('dddd');
};

export const getClassNameByStatus = (status) => {
  switch (status) {
    case 'Pending':
      return 'is-status-pending ';
    case 'Completed':
    case 'Approved':
    case 'Active':
      return 'is-status-active';
    case 'Rejected':
      return 'is-status-rejected';
    default:
      return '';
  }
};

export const getYesNoText = (value: boolean) => (value ? 'Yes' : 'No');

export const formatMoney = (value: number) =>
  value
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    })
    .replace(/\./g, ',');

export const formatMoneyInput = (value: number) => {
  if (!value) return '';
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};

export const hourDateFormat = 'h:mm:ss a, MMMM DD, YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:MM:ss A';
export const monthFormat = 'MMMM DD, YYYY';

export const emptyFunction = () => {};

export const compressFile = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(file?.type);

    if (isImage) {
      new Compressor(file, {
        quality: 0.7,
        maxWidth: 900,
        maxHeight: 900,
        convertSize: 0,
        success(result: File) {
          resolve(result);
        },
        error(err: Error) {
          reject(err);
        },
      });
    } else {
      resolve(file);
    }
  });
};

export const trimUrlHasEndDate = (url: string) => {
  const trimUrl = url.split('?')[0];
  const items = trimUrl.split('_');
  return items.slice(0, items.length - 1).join('');
};

export const trimUrl = (url: string) => {
  if (!url) return null;
  return url.split('?')[0];
};

export const getFileType = (file: File) => {
  if (!!file.type) return file.type;
  if (file.name.includes('.rar')) return 'application/x-rar-compressed';
  if (file.name.includes('.7z')) return 'application/x-7z-compressed';
  return 'image/png';
};

export const getNavigateUrl = (url: string) => (url.includes('http') ? url : `https://${url}`);

export const isURLImage = (url: string) => {
  if (isEmpty(url)) return false;

  const hasExtensionImage = [
    '.png',
    '.jpeg',
    '.jpg',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/svg',
  ].some((ext) => url?.includes(ext));

  if (hasExtensionImage) {
    return true;
  }

  const state = parse(url?.split('?')[1], { ignoreQueryPrefix: false });
  const contentType = state?.['Content-Type'];
  const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(contentType as string);

  return isImage;
};

export const capitalizeWords = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const getStartCase = (value: string) => (value ? _.startCase(value.toLowerCase()) : '');

export const getTitleCase = (str: string): string => {
  if (!str) return '';
  return _.startCase(_.toLower(str));
};

export const handleClick = (callback) => (event: React.MouseEvent<any>) => {
  event.stopPropagation();
  event.preventDefault();
  if (callback) callback(event);
};

//link https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
export const deepKeys = (t, path = []) => {
  const res =
    Object(t) === t
      ? Object.entries(t) // 1
          .flatMap(([k, v]) => deepKeys(v, [...path, k]))
      : [path.join('.')]; // 2
  return res?.filter((x) => !/\d$/.test(x));
};

export const scrollToTopError = (error: string[]) => {
  if (!isEmpty(error)) {
    const input = document?.querySelector(`[name='${error[0]}']`);
    input?.parentElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const moneyReg = /[\d,]+\.{0,1}\d{0,2}/;

export const MoneyInputDetect = (value) => `${value}`.match(moneyReg)?.[0] || '';

export const removeSpecialCharacterFromString = (value: string) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const formatStringToNumber = (value: string) => {
  if (isEmpty(value)) return null;
  return Number(value);
};

export const objectToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.key,
        label: x.name,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
export const objectToRegionOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id.toString(),
        key: x.id.toString(),
        label: x.name,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
export const objectToSaleAgentOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.id,
        label: x.fullName,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const objectUserToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.id,
        label: x.fullName,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const objectCompanyToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.value,
        key: x.value,
        label: x.label,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const objectTeamsToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => {
        return {
          value: x.id,
          key: x.id,
          label: x.name,
          // code: x.code || '',
          // searchField: x.searchField || '',
        };
      })) ||
    []
  );
};
export const objectAddressToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => {
        return {
          value: x.id,
          key: x.id,
          label: x.name,
          province: x.province,
          region: x.region,
          // code: x.code || '',
          // searchField: x.searchField || '',
        };
      })) ||
    []
  );
};
export const objectAgentFilterToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => {
        return {
          value: x.id,
          key: x.id,
          label: x.name,
          // code: x.code || '',
          // searchField: x.searchField || '',
        };
      })) ||
    []
  );
};

export const objectToSaleManagerOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.key,
        label: x.fullName,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const calcAgeFromDate = (dateString: string) => {
  //YYYY/MM/DD
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const objectToUserOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.id,
        label: x.fullName,
      }))) ||
    []
  );
};

export const objectToCompanyOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        label: x.name,
      }))) ||
    []
  );
};

export const objectToMemberOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.user.id,
        key: x.user.id,
        label: x.user.fullName,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
export const objectToUserIsApproval = (items) => {
  return (
    items &&
    items.map &&
    items.map((x) => ({
      value: x.id,
      key: x.id,
      label: x.fullName,
    }))
  );
};

export const objectToFileOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: { fileLink: x.link, fileName: x.name },
        key: x.id,
        label: x.name,
      }))) ||
    []
  );
};

export const objectToCompaniesOptions = (items) => {
  return (
    items &&
    items.map &&
    items.map((x) => ({
      value: x?.id?.toString(),
      key: x?.id?.toString(),
      label: x?.name,
    }))
  );
};
export const objectToGovernmentOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.value,
        key: x.key,
        label: x.label,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const objectToSaleAgentMemberRecordOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.id,
        key: x.id,
        label: x.fullName,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const objectToBenefitCategoriesOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.code,
        key: x.code,
        label: x.name,
      }))) ||
    []
  );
};

export const getPlanTypeText = (type) => {
  switch (type) {
    case 'SECURITY_GUARD':
      return 'Security Guard';
    case 'INDIVIDUAL':
      return 'Individual';
    case 'FAMILY':
      return 'Family';
    case 'PHILIPPINES_NATIONAL_POLICE':
      return 'Philippines National Police';
    case 'CORPORATE':
      return 'Corporate';
    case 'GOVERNMENT':
      return 'Government';
    default:
      return type;
  }
};
export const getSourceText = (type: string) => {
  switch (type) {
    case 'PERSONAL':
      return 'Personal';
    case 'PHONE':
      return 'Phone';
    case 'EMAIL':
      return 'Email';
    case 'FACEBOOK':
      return 'Facebook';
    case 'WEBSITE':
      return 'Website';
    case 'REFERRAL':
      return 'Referral';
    case 'INSTAGRAM':
      return 'Instagram';
    case 'OTHER':
      return 'Other';
    default:
      return type;
  }
};
export enum STATUS_LEAD {
  NEW_LEAD = 'NEW_LEAD',
  CALLED = 'CALLED',
  PRESENTED = 'PRESENTED',
  PROPOSAL_SENT = 'PROPOSAL_SENT',
  FIRST_FOLLOW_UP = 'FIRST_FOLLOW_UP',
  SECOND_FOLLOW_UP = 'SECOND_FOLLOW_UP',
  THIRD_FOLLOW_UP = 'THIRD_FOLLOW_UP',
  SIGNED_PROPOSAL = 'SIGNED_PROPOSAL',
  LOST = 'LOST',
}
export enum STATUS_DEAL {
  NEW_DEAL = 'NEW_DEAL',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_APPROVALS = 'PENDING_APPROVALS',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  COMPLETE = 'COMPLETE',
  REJECTED = 'REJECTED',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  ACTIVATED = 'ACTIVATED',
}
export const getDealStatusText = (type) => {
  switch (type) {
    case STATUS_DEAL.NEW_DEAL:
      return 'New Deal';
    case STATUS_DEAL.IN_PROGRESS:
      return 'In progress';
    case STATUS_DEAL.PENDING_APPROVALS:
      return 'Pending Approvals';
    case STATUS_DEAL.PENDING_PAYMENT:
      return 'Pending payment';
    case STATUS_DEAL.COMPLETE:
      return 'Completed';
    case STATUS_DEAL.REJECTED:
      return 'Rejected';
    case STATUS_DEAL.PENDING_ACTIVATION:
      return 'Pending Activation';
    case STATUS_DEAL.ACTIVATED:
      return 'Activated';
    default:
      return type;
  }
};

export enum UPD_STATUS {
  NEED_REVIEW = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const getUPDStatusText = (status: string) => {
  switch (status) {
    case UPD_STATUS.APPROVED:
      return 'Approved';
    case UPD_STATUS.NEED_REVIEW:
      return 'Need Review';
    case UPD_STATUS.REJECTED:
      return 'Rejected';
    default:
      return status;
  }
};
export const scrollToError = (pos: ScrollLogicalPosition = 'center') => {
  const errorEle = document.querySelector(`[class*='cmp-'][class*='--error']`) as HTMLElement;
  let parentSection = errorEle;
  while (parentSection?.parentElement && parentSection?.nodeName !== 'SECTION') {
    parentSection = parentSection.parentElement;
  }
  if (parentSection?.style?.height === '0px') return false;
  if (!errorEle) return false;
  errorEle.scrollIntoView({
    block: pos,
    behavior: 'smooth',
  });
  return true;
};
export function exportToCSV(filename, rows, headerRow) {
  var processHeader = function (headerRow) {
    var finalVal = '';
    for (var j = 0; j < headerRow.length; j++) {
      const value = headerRow[j].label;
      var innerValue = value === null ? '' : value.toString();
      if (value instanceof Date) {
        innerValue = value.toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < headerRow.length; j++) {
      const value = row[headerRow[j].key];
      var innerValue = !value ? '' : value.toString();
      if (value instanceof Date) {
        innerValue = value.toLocaleString();
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  csvFile += processHeader(headerRow);
  for (var i = 0; i < rows.length; i++) {
    try {
      csvFile += processRow(rows[i]);
    } catch (e) {}
  }
  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  var link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
export const getSelectValue = (
  list: { value: string | number | boolean; label: string }[],
  value: string | boolean
) => {
  return list.find((item) => item.value === value)?.label || '';
};

//Deals Permission
export const getDealsPermission = (
  deal: Deal,
  isUpdateUnassign: boolean,
  isUpdateDeal?: boolean
) => {
  if (!deal?.saleAgent && !deal?.saleManager) {
    if (isUpdateUnassign) {
      return !isUpdateUnassign;
    } else {
      return !isUpdateDeal;
    }
  } else {
    return !isUpdateDeal;
  }
};

export const getLeadsPermission = (
  lead: Lead,
  isUpdateUnassign: boolean,
  isUpdateLead?: boolean
) => {
  if (!lead?.saleAgent && !lead?.saleManager) {
    if (isUpdateUnassign) {
      return !isUpdateUnassign;
    } else {
      return !isUpdateLead;
    }
  } else {
    return !isUpdateLead;
  }
};
export const convertGovernment = (type) => {
  return GOVERNMENT_LIST.find((x) => x.value === type)?.label ?? '';
};
export const getFileTypeCustom = (fileType: string) => {
  switch (fileType) {
    case '.xls' || '.xlsx':
      return 'application/vnd.ms-excel';
    case '.doc' || '.docx':
      return 'application/msword';
    case '.png' || '.jpg' || 'jpeg' || '.gif':
      return 'image/png';
    case '.pdf':
      return 'application/pdf';
    case '.ppt' || '.pptx':
      return 'application/vnd.ms-powerpoint';
  }
};
export const objectToProductOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x.code,
        key: x.id,
        label: x.name,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
export const convertText = (text: string) => {
  var convertToArray = text?.replaceAll('_', ' ')?.toLowerCase()?.split(' ');
  var result = convertToArray?.map(function (val) {
    return val?.replace(val?.charAt(0), val?.charAt(0)?.toUpperCase());
  });
  return result?.join(' ');
};
export const objectToCitiesOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x,
        key: x?.id,
        label:
          isEmpty(x?.province) || x.isDuplicateName === null
            ? x.name
            : `${x.name} (${x?.province?.name})`,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};

export const parseFullAddress = (address: any) => {
  if (!isEmpty(address)) {
    let optionalAddress = Object.values(
      Object.keys({
        floor: !isEmpty(address?.floor) ? address?.floor : '',
        building: !isEmpty(address?.building) ? address?.building : '',
        bLK: !isEmpty(address?.blk) ? address?.blk : '',
        street: !isEmpty(address?.street) ? address?.street : '',
      })
        .filter((k) => address[k] != null && address[k] !== 0 && address[k] !== '')
        .reduce((a, k) => ({ ...a, [k]: address[k] }), {})
    ).join(' ');

    let requiredAddress = {
      optionalAddress,
      barangay: address?.barangay && `Barangay ${address?.barangay}`,
      municipality: address?.municipality && `${address?.municipality} City`,
      province: address?.province && `${address?.province} Province`,
      region:
        address?.region &&
        `${address?.region}${address?.region?.includes('Region') ? '' : ' Region'}`,
      country: address?.zipCode ? `Philippines ${address?.zipCode}` : '',
    };
    var fullAddress = Object.values(
      Object.keys(requiredAddress)
        .filter(
          (k) => requiredAddress[k] != null && requiredAddress[k] !== 0 && requiredAddress[k] !== ''
        )
        .reduce((a, k) => ({ ...a, [k]: requiredAddress[k] }), {})
    ).join(', ');
    if (fullAddress) {
      return fullAddress;
    }
  } else return;
};
export const parseFullAddressERGuard = (address: any, contents) => {
  if (!isEmpty(address)) {
    let optionalAddress = Object.values(
      Object.keys({
        floor: !isEmpty(address?.floor) ? address?.floor : '',
        building: !isEmpty(address?.building) ? address?.building : '',
        bLK: !isEmpty(address?.blk) ? address?.blk : '',
        street: !isEmpty(address?.street) ? address?.street : '',
      })
        .filter((k) => address[k] != null && address[k] !== 0 && address[k] !== '')
        .reduce((a, k) => ({ ...a, [k]: address[k] }), {})
    ).join(' ');
    const city = contents && contents?.cities?.data.find((x) => x.id === address?.cityId)?.name;
    const region =
      contents && contents?.regions?.data.find((x) => x.id === address?.regionId)?.name;
    const province =
      contents && contents?.provinces?.data.find((x) => x.id === address?.provinceId)?.name;
    let requiredAddress = {
      optionalAddress,
      barangay: address?.barangay && `Barangay ${address?.barangay}`,
      municipality: city && `${city} City`,
      province: province && `${province} Province`,
      region: region && `${region}${region?.includes('Region') ? '' : ' Region'}`,
      country: address?.zipCode ? `Philippines ${address?.zipCode}` : '',
    };
    var fullAddress = Object.values(
      Object.keys(requiredAddress)
        .filter(
          (k) => requiredAddress[k] != null && requiredAddress[k] !== 0 && requiredAddress[k] !== ''
        )
        .reduce((a, k) => ({ ...a, [k]: requiredAddress[k] }), {})
    ).join(', ');
    if (fullAddress) {
      return fullAddress;
    }
  } else return;
};
const calcTotalPremium = (totalPremium) => {
  let numberParts = Number(totalPremium)?.toFixed(2)?.split('.');
  let integerPart = numberParts[0];
  let fractionalPart = numberParts.length > 1 ? numberParts[1] : '0';
  const roundedTotal = Number(integerPart) * 100 + Number(fractionalPart);
  return roundedTotal;
};
export const calculatePremiumPayments = (term, totalPremium) => {
  const roundedTotal = calcTotalPremium(totalPremium);
  const premiumInstallment = Math.floor(roundedTotal / term);
  const premiumFirstPayment = premiumInstallment + (roundedTotal % term);

  return { premiumInstallment, premiumFirstPayment };
};

export function _calculateAge(birthday) {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); //milliseconds from epoch
  return ageDate.getUTCFullYear() - 1970;
}

export function daysFromToday(inputDate) {
  const startDate = new Date(inputDate);
  const today = new Date();
  const differenceInTime = today.getTime() - startDate.getTime();
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
}

export function convertInchesToFeetInches(inches) {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return { feet, inches: remainingInches };
}
