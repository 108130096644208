import { Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Input, InputPhone, Select } from 'src/components/common';
import Checkbox from 'src/components/common/Checkbox';
import Group from 'src/components/common/RadioButton';
import { sourceOptions } from 'src/containers/SaleContainers/LeadInbox/TableList/CustomFilter/helpers';
import { useGetListCities } from 'src/queries/Interest/useGetListCities';
import { useGetListProvinces } from 'src/queries/Interest/useGetListProvinces';
import { useGetListRegion } from 'src/queries/Interest/useGetListRegion';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { IRootState } from 'src/redux/rootReducer';
import { objectToCitiesOptions } from 'src/utils';
import { isEmpty } from 'src/validations';
import { objectToAddressOptions } from '../../DealManagement/CRUDealContainer/DealGeneralInfo/helpers';
import { CRUInterestFormikProps, CRU_INTEREST_KEY, getErrorMessage } from '../helpers';

const InterestFormInfo = (props: Props) => {
  const { formikProps } = props;

  const { values, errors, touched, getFieldProps, setFieldValue, setFieldTouched } = formikProps;

  const _getErrorMessage = (fieldName: CRU_INTEREST_KEY) => {
    return getErrorMessage(fieldName, { touched, errors });
  };

  const { regions: listRegions, onGetListRegions } = useGetListRegion({
    onSuccess(data) {
      setRegions(data?.data?.data);
    },
  });
  const { provinces: listProvinces, onGetListProvinces } = useGetListProvinces({
    onSuccess(data) {
      setProvinces(data?.data?.data);
    },
  });
  const { cities: listCities, onGetListCities } = useGetListCities({
    onSuccess(data) {
      setCities(data?.data?.data);
    },
  });

  const [cities, setCities] = useState(listCities?.data?.data);
  const [provinces, setProvinces] = useState<any>(listProvinces?.data?.data);
  const [regions, setRegions] = useState<any>(listRegions?.data?.data);

  useEffect(() => {
    onGetListRegions();
    onGetListCities();
    onGetListProvinces();
  }, [onGetListCities, onGetListProvinces, onGetListRegions]);

  const handleOnchangeCity = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(CRU_INTEREST_KEY.MUNICIPALITY, null);
    } else {
      if (value.regionId || value.region.id) {
        setRegions(listRegions?.data?.data.filter((x) => x.id === value.regionId));
        setCities(
          listCities?.data?.data.filter(
            (x) => x.regionId === listRegions?.data?.data.find((x) => x.id === value.regionId).id
          )
        );
      }
    }
    if (value?.provinceId || value?.province?.id) {
      setProvinces(
        listProvinces?.data?.data?.filter((item) =>
          item?.city?.filter((city) => city?.id === value?.id)
        )
      );
      setCities(listCities?.data?.data?.filter((item) => item?.provinceId === value?.provinceId));
    }
    if (!isEmpty(values.region) && !isEmpty(values.province)) {
      setCities(
        listCities?.data?.data.filter(
          (x) => x.regionId === values.region.id && x.provinceId === values.province.id
        )
      );
    }
  };

  const handleOnchangeProvince = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(CRU_INTEREST_KEY.MUNICIPALITY, null);
    }
  };

  const handleOnchangeRegion = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(CRU_INTEREST_KEY.MUNICIPALITY, null);
      setFieldValue(CRU_INTEREST_KEY.PROVINCE, null);
      setCities(listCities?.data?.data);
      setProvinces(listProvinces?.data?.data);
      setRegions(listRegions?.data?.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(values.municipality) && !isEmpty(values.region)) {
      if (values.municipality.province === null) {
        setProvinces([]);
      } else {
        setProvinces(
          listProvinces?.data?.data.filter(
            (x) =>
              x.id ===
              listProvinces?.data?.data
                .filter((x) => x.regionId === values.region.id)
                .map((x) => x.city)
                .flat(1)
                .find((x) => x.id === values.municipality.id).provinceId
          )
        );
        setFieldValue(
          CRU_INTEREST_KEY.PROVINCE,
          listProvinces?.data?.data.find(
            (x) =>
              x.id ===
              listProvinces?.data?.data
                .filter((x) => x.regionId === values.region.id)
                .map((x) => x.city)
                .flat(1)
                .find((x) => x.id === values.municipality.id).provinceId
          )
        );
      }
    } else if (!isEmpty(values.province) && !isEmpty(values.region)) {
      setCities(
        listCities?.data?.data.filter(
          (x) => x.regionId === values.region.id && x.provinceId === values.province.id
        )
      );
    } else if (!isEmpty(values.municipality) && !isEmpty(values.province)) {
      setRegions(listRegions?.data?.data.filter((x) => x.id === values.municipality.id));
      setFieldValue(
        CRU_INTEREST_KEY.REGION,
        listRegions?.data?.data.find((x) => x.id === values.municipality.id)
      );
    } else if (isEmpty(values.municipality) && isEmpty(values.region) && isEmpty(values.province)) {
      setCities(listCities?.data?.data);
      setProvinces(listProvinces?.data?.data);
      setRegions(listRegions?.data?.data);
    } else if (
      isEmpty(values.municipality) &&
      isEmpty(values.province) &&
      !isEmpty(values.region)
    ) {
      setProvinces(listProvinces?.data?.data.filter((x) => x.regionId === values.region.id));
      setCities(listCities?.data?.data.filter((x) => x.regionId === values.region.id));
    } else if (
      isEmpty(values.municipality) &&
      !isEmpty(values.province) &&
      isEmpty(values.region)
    ) {
      setCities(listCities?.data?.data.filter((x) => x.provinceId === values?.province.id));
      setRegions(
        listRegions?.data?.data.filter((x) =>
          x.province.map((x) => x.id).includes(values?.province?.id)
        )
      );
      setFieldValue(
        CRU_INTEREST_KEY.REGION,
        listRegions?.data?.data.find((x) =>
          x.province.map((x) => x.id).includes(values?.province?.id)
        )
      );
    } else if (
      !isEmpty(values.municipality) &&
      isEmpty(values.province) &&
      isEmpty(values.region)
    ) {
      setFieldValue(
        CRU_INTEREST_KEY.REGION,
        listRegions?.data?.data.find((x) => x.id === values?.municipality?.regionId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.region, values.municipality, values.province]);
  const planTypeOptions = [
    { value: 'INDIVIDUAL', label: 'Individual' },
    { value: 'FAMILY', label: 'Family' },
    { value: 'CORPORATE', label: 'Corporate' },
  ];
  return (
    <>
      <Grid container spacing={2}>
        <>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Select
              menuPosition=""
              name="planType"
              options={planTypeOptions}
              hideSearchIcon={true}
              label="Plan Type"
              required
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.PLAN_TYPE)}
              value={values.planType}
              {...getFieldProps(CRU_INTEREST_KEY.PLAN_TYPE)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              label="First Name"
              required
              placeholder="First Name"
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.FIRST_NAME)}
              {...getFieldProps(CRU_INTEREST_KEY.FIRST_NAME)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              label="Middle Name"
              placeholder="Middle Name"
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.MIDDLE_NAME)}
              {...getFieldProps(CRU_INTEREST_KEY.MIDDLE_NAME)}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              label="Last Name"
              required
              placeholder="Last Name"
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.LAST_NAME)}
              {...getFieldProps(CRU_INTEREST_KEY.LAST_NAME)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <DatePicker
              required
              label="Birthday"
              formatISODate
              placeholder="MM/DD/YYYY"
              name={CRU_INTEREST_KEY.BIRTHDAY}
              maxDate={new Date(Date.now())}
              selected={values.birthday ? new Date(values.birthday) : undefined}
              {...getFieldProps(CRU_INTEREST_KEY.BIRTHDAY)}
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.BIRTHDAY)}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              label={'Email'}
              required
              placeholder="Email"
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.EMAIL)}
              {...getFieldProps(CRU_INTEREST_KEY.EMAIL)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <InputPhone
              label="Phone Number"
              required
              placeholder="Phone Number"
              name={CRU_INTEREST_KEY.PHONE_NUMBER}
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.PHONE_NUMBER)}
              {...getFieldProps(CRU_INTEREST_KEY.PHONE_NUMBER)}
              onChange={setFieldValue}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h6" className="heading-xs" marginBottom={1}>
              Address
            </Typography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.BLK)}
              className="cs-input-cls"
              label={'Blk/Lot No.'}
              {...getFieldProps(CRU_INTEREST_KEY.BLK)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.STREET)}
              label={'Street'}
              placeholder=""
              {...getFieldProps(CRU_INTEREST_KEY.STREET)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.BARANGAY)}
              label={'Barangay'}
              {...getFieldProps(CRU_INTEREST_KEY.BARANGAY)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Select
              required
              name={CRU_INTEREST_KEY.MUNICIPALITY}
              hideSearchIcon
              label="City/Municipality"
              options={objectToCitiesOptions(cities)}
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.MUNICIPALITY)}
              {...getFieldProps(CRU_INTEREST_KEY.MUNICIPALITY)}
              onChange={handleOnchangeCity}
              onBlur={setFieldTouched}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Select
              required
              name={CRU_INTEREST_KEY.PROVINCE}
              hideSearchIcon
              label="Province"
              options={objectToAddressOptions(provinces)}
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.PROVINCE)}
              {...getFieldProps(CRU_INTEREST_KEY.PROVINCE)}
              onChange={handleOnchangeProvince}
              onBlur={setFieldTouched}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Select
              required
              name={CRU_INTEREST_KEY.REGION}
              hideSearchIcon
              label="Region"
              options={objectToAddressOptions(regions)}
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.REGION)}
              {...getFieldProps(CRU_INTEREST_KEY.REGION)}
              onChange={handleOnchangeRegion}
              onBlur={setFieldTouched}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Input
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.ZIP_CODE)}
              label="Zip Code"
              {...getFieldProps(CRU_INTEREST_KEY.ZIP_CODE)}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Group
              required
              label="How did you know about us?"
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.SOURCE)}
              {...getFieldProps(CRU_INTEREST_KEY.SOURCE)}
              options={sourceOptions}
              columns={1}
              onChange={setFieldValue}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />

          {values.source === 'OTHER' && (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Input
                name="sourceOther"
                label="Other"
                placeholder="Other"
                value={values.sourceOther}
                errorMessage={_getErrorMessage(CRU_INTEREST_KEY.SOURCE_OTHER)}
                {...getFieldProps(CRU_INTEREST_KEY.SOURCE_OTHER)}
              />
            </Grid>
          )}
          {values.source === 'REFERRAL' && (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Input
                label="Referral Name"
                placeholder="Enter"
                errorMessage={_getErrorMessage(CRU_INTEREST_KEY.REFERRAL)}
                {...getFieldProps(CRU_INTEREST_KEY.REFERRAL)}
              />
            </Grid>
          )}
          {(values.source === 'REFERRAL' || values.source === 'OTHER') && (
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
          )}
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <Checkbox.Item
              // checked={isMarkAComplete}
              required
              errorMessage={_getErrorMessage(CRU_INTEREST_KEY.MARK_CONFIRM)}
              label="By using this form you agree with the storage and handling of your data by this website in accordance with our Privacy Policy."
              {...getFieldProps(CRU_INTEREST_KEY.MARK_CONFIRM)}
            />
          </Grid>
        </>
      </Grid>
    </>
  );
};

export interface InterestFormInfoProps {
  formikProps: CRUInterestFormikProps;
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & InterestFormInfoProps;

const mapStateToProps = (state: IRootState) => ({
  contents: state.common.contents,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
};
export default connect(mapStateToProps, mapDispatchToProps)(InterestFormInfo);
