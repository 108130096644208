import { useEffect, useMemo } from 'react';
import { useGetAllTeams } from 'src/queries/Teams/useGetAllTeams';
import { useGetAllUsers } from 'src/queries/Users';
import { objectTeamsToOptions, objectUserToOptions } from 'src/utils';
import { isEmpty } from 'src/validations';

export const MergeArrayHook = () => {
  const { teams, onGetAllTeams } = useGetAllTeams();
  const { users, onGetAllUsers } = useGetAllUsers();

  const convertTeam = objectTeamsToOptions(teams);
  const convertUser = objectUserToOptions(users);

  const finalResult = useMemo(() => {
    if (!isEmpty(teams) && !isEmpty(users)) {
      var template = {
        Group: convertTeam,
        User: convertUser,
      };
    }
    return template;
  }, [convertTeam, convertUser, teams, users]);

  useEffect(() => {
    onGetAllTeams();
    onGetAllUsers();
  }, [onGetAllTeams, onGetAllUsers]);

  return { finalResult, convertTeam, convertUser };
};
