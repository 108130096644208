import { IconButton, Tooltip, Typography } from '@mui/material';

import './styles.scss';
import { NotificationItem } from 'src/queries/Notification/types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Navigator } from 'src/services';
import { connect } from 'react-redux';
import { useMarkReadNotificationById } from 'src/queries/Notification/useMarkReadNotificationById';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QUERY_KEY } from './helpers';

const NotificationDetail: React.FC<Props> = ({ item, onCloseDialog, onChangeNotificationList }) => {
  const [isHover, setIsHover] = useState(false);
  dayjs.extend(relativeTime);

  const { updateMarkReadNotification, isLoading } = useMarkReadNotificationById({
    onSuccess() {
      onChangeNotificationList();
    },
  });

  const handleChangeStatusNoti = () => {
    if (!item.isRead) {
      updateMarkReadNotification({
        id: item.id,
        isRead: !item.isRead,
      });
    }

    if (!item.pathType) return;
    onCloseDialog();

    if (item.pathType === 'ER_GUARD' && item.path === 'retail-order-payment-request') {
      Navigator.navigate(
        `/${item.pathType.replaceAll('_', '-').toLowerCase()}/${item.path}/${
          item.detailId ? item.detailId : ''
        }?${QUERY_KEY.showPopUp}=${item.detailId}`
      );
    } else {
      Navigator.navigate(
        `/${(item.pathType === 'ACCOUNT_MEMBER' ? 'ACCOUNT_AND_MEMBER' : item.pathType)
          .replaceAll('_', '-')
          .toLowerCase()}/${item.path}/${item.detailId ? item.detailId : ''}`
      );
    }
  };

  const handleChangeMarkNotification = (e) => {
    e.stopPropagation();
    updateMarkReadNotification({
      id: item.id,
      isRead: !item.isRead,
    });
  };

  const handleMouseOver = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className="noti-item"
      onClick={() => handleChangeStatusNoti()}
      aria-hidden="true"
      id={item?.id}
      onFocus={handleMouseOver}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <div className="left">
        <Typography>{item?.description}</Typography>
        <div className="date" style={{ color: !item.isRead ? '#4684e7' : '#91979E' }}>
          {dayjs(item?.createdAt).fromNow()}
        </div>
      </div>
      <div className="right">
        <Tooltip
          arrow={true}
          title={`Mark as ${!item.isRead ? 'read' : 'unread'}`}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                maxWidth: 170,
                fontSize: 12,
                fontWeight: 100,
              },
            },
          }}
        >
          <IconButton style={{ padding: 0 }} onClick={handleChangeMarkNotification}>
            {/* <CheckMarkIcon color={!item.isRead ? '#4684E7' : '#91979E'} /> */}
            <div
              className="dot-wrapper"
              style={{
                display: 'block',
                width: 16,
                height: 16,
                border: '1px solid #CFD4D9',
                borderColor: isLoading ? '#4684E7' : '#CFD4D9',
                backgroundColor: 'transparent',
                borderRadius: '50%',
                position: 'relative',
                visibility: isHover ? 'visible' : 'hidden',
              }}
            >
              <div
                className="dot"
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: '#4684E7',
                  position: 'absolute',
                  top: 'calc(50% - 4px)',
                  left: 'calc(50% - 4px)',
                  visibility: item.isRead ? 'hidden' : 'visible',
                }}
              />
            </div>
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

type Props = typeof mapDispatchToProps & {
  item: NotificationItem;
  onCloseDialog: any;
  onChangeNotificationList: any;
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(NotificationDetail);
