import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatPhoneNumber, getPlanTypeText } from 'src/utils';
import { ACCOUNT_SEARCH_KEY } from './helpers';
import ChipCommon from 'src/components/common/Chip';
import dayjs from 'dayjs';
import { PATHS } from 'src/appConfig/paths';
import { isPNPSecurityGuard } from 'src/containers/SaleContainers/DealManagement/DealDetail/components/ToolbarAction/helpers';
import { diffDays } from 'src/utils/handleUtils';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: ACCOUNT_SEARCH_KEY.ACCOUNT_NUMBER,
    label: 'Account Number',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <Link
            to={{
              pathname: `${PATHS.accountAndMembers}${PATHS.accountRecords}/${rowData.id}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? ''}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.PLAN_TYPE,
    label: 'Plan Type',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{getPlanTypeText(value) ?? ''}</div>;
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.ACCOUNT_NAME,
    label: 'Account Name',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value}</div>;
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.ACCOUNT_STATUS,
    label: 'Account Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return <ChipCommon typeOfChip={rowData.status} text={rowData.status} />;
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.SUSPENSION_DATE,
    label: 'Suspension Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.CURRENT_POLICY_DATE,
    label: 'Current Policy Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.EXPIRY_DATE,
    label: 'Expiry Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ACCOUNT_SEARCH_KEY.DAY_TO_EXPIRY,
    label: 'Days to Expiry',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        const expiryDate = rowData.expiryDate;
        return (
          <div className={'body__medium'}>
            {expiryDate && !isPNPSecurityGuard(rowData) ? diffDays(expiryDate, dayjs()) : null}
          </div>
        );
      },
    },
  },
];
