import { Typography } from '@mui/material';
import React from 'react';
import { Accordion } from 'src/components/common';

const NoPermissionSystemSearch: React.FC<Props> = ({ totalItems, index, isView }) => {
  return (
    <Accordion
      title={
        <span style={{ fontSize: '18px', fontWeight: '600' }}>{`${index} (${
          isView ? totalItems ?? 0 : 0
        })`}</span>
      }
      children={
        <div
          style={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={14} fontWeight={600} color="#6D7176" mb={1}>
            Insufficient Privileges
          </Typography>
          <Typography fontSize={12} color={'#6D7176'} textAlign={'center'} maxWidth={550}>
            You do not have the level of access necessary to perform the operation you requested.
            Please contact your administrator for access.
          </Typography>
        </div>
      }
    />
  );
};
type Props = {
  totalItems: number;
  index: string;
  isView: boolean;
};
export default NoPermissionSystemSearch;
