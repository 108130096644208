import cn from 'classnames';
import React, { useRef, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import dayjs from 'dayjs';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import './styles.scss';

export type DateRange = [Date, Date];

const DateRangePicker: React.FC<Props> = ({
  label,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'MM/DD/YYYY - MM/DD/YYYY',
  dateFormat = 'MM/DD/YYYY',
  selecteds,
  placement,
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dates, setDates] = useState<DateRange>(selecteds as DateRange);

  const handleDateChange = (dates: DateRange) => {
    setDates(dates);

    if (!!dates[0] && !!dates[1]) {
      onChange(dates);
    }
  };

  const formatDates = (): string => {
    if (isEmpty(selecteds)) return '';
    return selecteds?.map((x) => dayjs(x).format(dateFormat as string)).join(' - ') || '';
  };

  const handleClickOutside = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsOpen(false);
  };

  const hasError = !isEmpty(errorMessage);
  const startDate = dates?.[0];
  const endDate = dates?.[1];

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn(
        !placement ? 'placement-datepicker' : '',
        'cmp-datepicker',
        'date-range-picker',
        containerClassName
      )}
    >
      <DatePicker
        isClearable={true}
        popperPlacement={placement ? placement : 'auto'}
        shouldCloseOnSelect={false}
        monthsShown={2}
        id={id.current}
        onChange={handleDateChange}
        placeholderText={placeholder}
        className={cn(
          'cmp-datepicker__input',
          { 'cmp-datepicker__input--error': hasError },
          classNames
        )}
        showPopperArrow={false}
        {...props}
        value={formatDates()}
        selectsRange
        startDate={startDate}
        endDate={endDate}
        open={isOpen}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={handleClickOutside}
      />
    </Element>
  );
};

type Props = ReactDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  label?: string;
  onChange?: (value: DateRange) => void;
  selecteds?: [Date, Date];
  placement?: ReactDatePickerProps['popperPlacement'];
};

export default DateRangePicker;
