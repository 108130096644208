import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  PROFILE = '/me',
  CONTENT = '/content',
  AGENT_FOR_FILTER = '/agent-for-filter',
  ZIP_CODE = '/zip-code',
  USER_ID = '/user-id',
  PERMISSIONS = '/permissions',
  DEAL = '/deal/:id',
  DEALS_DETAIL = '/deals-inbox',
  DEALS = '/deals',
  DEALS_KANBAN = 'deals-kanban',
  ASSIGN_AGENT = '/assign-agent',
  GET_LIST_ACCOUNT_RECORD = '/get-list-account-record',
  // Users Management
  USERS = '/users',
  USER = '/users/:id',
  SEARCH_USER = 'users/search',
  SEARCH_PROJECTS = 'projects/search',
  STAFF_USER = '/users/staff/:id',

  // Teams Management
  TEAMS = '/teams',
  TEAM = '/teams/:id',

  // Leads Management
  LEADS = '/leads',
  LEADS_DETAIL = '/lead-detail',
  LEAD = '/leads/:id',
  LEADS_KANBAN = '/leads-kanban',

  // share
  SHARE = '/share',
  EXPORT_SAF = '/export-saf',
  DOWNLOAD_URL = '/download-url',

  // Get list select
  SALES_MANAGER_LIST = '/sales-manager-list',
  SALES_AGENT_LIST = '/sales-agent-list',
  SALES_LIST_AGENT = '/sales-list-agent',
  SALES_LIST_LEAD = '/sales-list-lead',
  SALES_LIST_MANAGER = '/sales-list-manager',
  ROLES_LIST = '/roles-list',

  //Plan data
  PLAN_DATA = '/plan-data',
  PLAN_DATA_SELECT_LIST = '/plan-data-select',
  PLAN_DATA_COVEAGE = '/plan-data-coverage',
  PLAN_DATA_COVEAGE_DEFAULT = '/plan-data-coverage-default',

  //Health questionaries

  HEALTH_QUESTIONARIES = '/health-questionarie',
  SAF_FORM = '/SAF-Form',

  //Sales Dashboard
  LEADS_INFORMATION = '/leads-information',
  DEALS_INFORMATION = '/deal-information',
  PAYMENT_INFORMATION = '/payments-information',
  BIRTHDAY_INFORMATION = '/birthdays-information',
  SALE_DASHBOARD = '/sales-dashboard',
  SALE_PERFORMANCE = '/sales-performance',

  //UPD Dashboard
  UPD_DASHBOARD = '/upd-dashboard',
  UPD_DEALS = '/upd-deals',

  COMPANIES = '/companies',
  COMPANIES_DETAIL = '/companies-detail',
  BRANCH_DETAIL = '/branch-detail',
  PRODUCTS = '/products',

  //ERGuard: Branch
  LIST_BRANCH = '/list-branch',
  LIST_USERS = '/user-management',
  //User Activity
  USER_ACTIVITY = '/get-user-activity/:id',

  //ROLE
  LIST_ROLES_ERG = '/role',

  LIST_REGION = '/region',
  LIST_PROVINCE = '/province',
  LIST_CITY = '/city',
  BRANCH_OVERVIEW = '/branch-overview',
  ORDERS = '/orders',
  EXPORT_DIGITAL_CARD = 'export-digital-card',
  ORDER_DETAIL = '/order-detail',

  //Account
  ACCOUNT_DETAIL = '/account-detail',
  ACCOUNT_ACTIVITY_LOGS = '/account-activity-logs',
  ORDER_MANAGEMENT_USERS = '/order-management-users',
  ORDER_ACTIVITY_LOGS = 'activity-logs-order',

  VOID_REQUEST = '/void-request',

  //member record
  MEMBER_RECORDS = '/member-record',
  MEMBER_RECORD_DETAIL = '/member-record-detail',
  MEMBER_ACTIVITY_LOGS = '/member-activity-logs',
  VALIDATE_HMO = '/validate-hmo',

  //medical dashboard
  MEDICAL_DASHBOARD = 'medical-dashboard',
  MEDICAL_DEALS = 'medical-deals',
  MEMBER_BILLING_DETAIL = '/billing-details',

  ACCOUNT_BILLING_DETAIL = 'account-member-detail',
  CONFIRM_PAYMENT_LIST = 'confirm-payment-list',
  // Finance Dashboard
  FINANCE_DASHBOARD = '/finance-dashboard',
  FINANCE_DEALS = '/finance-deals',
  FINANCE_PAYMENTS = '/finance-payments',
  FINANCE_INITIAL_PAYMENTS = '/finance-initial-payments',
  FINANCE_TERMS = '/finance-terms',

  ERG_PAYMENT = '/payment',
  ERG_PAYMENT_REQUEST = '/payment-request',
  ERG_PAYMENT_USER = '/payment-user',
  ERG_PAYMENT_REQUEST_DETAIL = '/payment-request-detail',

  CONFIGURATION_PRODUCTS = '/configuration-products',

  //Benefits
  BENEFIT_CATEGORY = '/benefit-category',
  BENEFIT_ITEM = '/benefit-item',
  BENEFIT_LIST = '/benefit-list',
  BENEFIT_DETAIL = '/benefit-detail',

  //Notifications
  NOTIFICATION = '/notification',

  //System Search
  SYSTEM_SEARCH = '/system-search',
  MODULE_SEARCH = '/module-search',

  //product detail by product code
  PRODUCT_DETAIL_BY_PRODUCT_CODE = '/product-detail-by-product-code',
  ONLINE_ORDER_PAYMENT = '/online-order-payments',
  ONLINE_ORDER_PAYMENT_DETAIL = '/online-order-payment-detail',
  PRODUCT_DETAIL = '/product-detail',
  //Matrix
  MATRIX_PLAN_TYPE = '/matrix-plan-type',
  MATRIX_DETAIL = '/matrix-detail',
  MATRIX_HISTORY = '/matrix-history',

  //online order
  ONLINE_ORDER = '/online-order',
  ONLINE_ORDER_DETAIL = '/online-order-detail',

  ER_GUARD_USER_DETAIL = '/erguard-user-detail',
  MATRIX_HISTORY_BY_VERSION = '/matrix-history-by-version',

  ER_GUARD_DASHBOARD = '/er_guard_dashboard',
  TOP_TEN_BRANCHES = '/top-ten-branches',
  ER_GUARD_ORDERS = '/er_guard_order',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };
