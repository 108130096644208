import {
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  IconButton,
  Grow,
} from '@mui/material';
import React, { Fragment } from 'react';
import { isEmpty } from 'src/validations';
import { Text, View } from '..';
import { REASON_CLOSE_DIALOG } from './helpers';
import './styles.scss';
import cn from 'classnames';
import { Callback } from 'src/redux/types';
import { IoClose } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';
import WarningIcon from '@mui/icons-material/Warning';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
const Dialog: React.FC<
  Omit<DialogProps, 'onClose'> & {
    dialogType?: string;
    isWarningIcon?: boolean;
    iconTitle?: React.ReactNode;
    title: string;
    dialogActions?: React.ReactNode;
    dialogContentClasses?: DialogContentProps['classes'];
    fullScreen?: boolean;
    loading?: boolean;
    overflowVisible?: boolean;
    disabledButton?: boolean;
    hideTitle?: boolean;
    hideFooter?: boolean;
    onClose?: Callback;
    titleColor?: string;
    isOverflowHidden?: boolean;
  }
> = ({
  dialogType,
  isWarningIcon,
  iconTitle,
  children,
  title,
  dialogActions,
  onClose,
  dialogContentClasses,
  fullScreen,
  loading,
  overflowVisible,
  disabledButton,
  hideTitle,
  hideFooter,
  open,
  titleColor,
  isOverflowHidden = false,
  ...dialogProps
}) => {
  return (
    <MuiDialog
      open={open}
      // onClose={onClose}
      {...dialogProps}
      fullScreen={fullScreen}
      // disableBackdropClick={loading || disabledButton}
      classes={{
        paper: cn('cmp-dialog', {
          'cmp-dialog__content--visible': overflowVisible,
        }),
        container: 'cmp-dialog__container',
      }}
      TransitionComponent={Grow}
      BackdropProps={{
        transitionDuration: 0.4,
      }}
      {...dialogProps}
    >
      {!disabledButton && (
        <IconButton
          className="cmp-dialog__close-icon"
          onClick={(e) => {
            // e.stopPropagation();
            // e.preventDefault();
            onClose({}, REASON_CLOSE_DIALOG.CLOSE_ICON_CLICK);
          }}
        >
          <IoClose color={COLOR_CODE.PRIMARY_500} />
        </IconButton>
      )}
      {!hideTitle && (
        <DialogTitle className="cmp-dialog__title">
          <View isRow align="center" justify="space-between">
            <View isRow align="center">
              {iconTitle && <i className="mr-8 pt-2">{iconTitle}</i>}
              <Text
                size={20}
                className="fw-bold mr-8"
                style={{
                  paddingLeft: `${iconTitle && '5px'}`,
                  color: `${titleColor ? titleColor : 'black'}`,
                }}
              >
                {isEmpty(title) ? ` ` : title}
              </Text>
              {loading && <CircularProgress size={25} />}
            </View>
          </View>
        </DialogTitle>
      )}
      <DialogContent
        sx={
          dialogType === DIALOG_TYPES.YESNO_DIALOG
            ? { paddingRight: '20px', paddingLeft: '25px' }
            : { overflow: isOverflowHidden ? 'hidden' : 'auto' }
        }
        classes={{
          root: cn('cmp-dialog__content', {
            'cmp-dialog__content--visible': overflowVisible,
          }),
        }}
      >
        {children}
      </DialogContent>
      {hideFooter ? <br /> : ''}
      {!isEmpty(dialogActions) && (
        <DialogActions
          sx={{
            display: hideFooter ? 'none' : '',
          }}
          classes={{
            root: 'cmp-dialog__footer',
          }}
        >
          {dialogActions}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
