import { Typography } from '@mui/material';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import { Button, View } from 'src/components/common';
import { setSelectedLink, setSelectedLinkName } from 'src/redux/common/commonSlice';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator } from 'src/services';
import { Image } from '../../../components/common';

const NoPermission: React.FC<Props> = ({ myProfile }) => {
  const dispatch = useDispatch();
  return (
    <View
      style={{ width: '100vw', height: '100vh', marginBottom: 64 }}
      className="flex-center justify-center full-width full-height text-align-center "
    >
      <Image src={IMAGES.permission} />
      <Typography variant="h3" marginTop={10}>
        Insufficient Privileges
      </Typography>

      <Typography variant="body2" marginTop={2}>
        You do not have the level of access necessary to perform the operation you requested.
      </Typography>

      <Typography variant="body2" marginBottom={2}>
        Please contact your administrator if access is neccessary.
      </Typography>

      {myProfile?.permissions.length ? (
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(setSelectedLink(PATHS.dashboard));
            dispatch(setSelectedLinkName('Dashboard'));
            Navigator.navigate(PATHS.root);
          }}
        >
          Click here to return to the previous page
        </Button>
      ) : null}
    </View>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  myProfile: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NoPermission);
