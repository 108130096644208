import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

type PathHistoryType = string[];

export default () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pathHistory, setPathHistory] = useState<PathHistoryType>([]);
  const history = useHistory();

  const appendPath = (path: string, historyArray: PathHistoryType): PathHistoryType => {
    const lastPath = historyArray[historyArray.length - 1];
    if (path === lastPath) {
      // Don't add duplicate consecutive paths
      return historyArray;
    }
    return [...historyArray, path];
  };

  useEffect(() => {
    // Initial addition of the current path
    setPathHistory((prevHistory) =>
      appendPath(`${history.location.pathname}${history.location.search}`, prevHistory)
    );

    const unListen = history.listen((location, action) => {
      if (action === 'POP') {
        // User clicked the back button
        setPathHistory((prevHistory) => {
          if (prevHistory.length <= 1) return ['/']; // Default path if history is empty

          const newHistory = prevHistory.slice(0, -1);
          const previousPath = newHistory[newHistory.length - 1] || '/';
          history.push(previousPath); // Navigate to the previous path
          return newHistory;
        });
      } else {
        // For other navigation actions, add the new path to the history
        setPathHistory((prevHistory) =>
          appendPath(`${location.pathname}${location.search}`, prevHistory)
        );
      }
    });

    return () => unListen();
  }, [history]);
};
