import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { MarkReadNotificationPayload } from './types';

export function useMarkReadNotificationById(
  options?: UseMutationOptions<any, Error, MarkReadNotificationPayload>
) {
  const { mutate: updateMarkReadNotification, isLoading } = useMutation<
    any,
    Error,
    MarkReadNotificationPayload
  >({
    mutationFn: (payload: MarkReadNotificationPayload) => {
      return authResponseWrapper(apiClient.markReadNotification, [payload]);
    },
    ...options,
  });

  return {
    updateMarkReadNotification,
    isLoading,
  };
}
