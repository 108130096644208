import cn from 'classnames';
import React, { useEffect, useMemo } from 'react';

import { Callback } from 'src/redux/types';
import { View } from '../';
import Icon from '../Icon';
import Text from '../Text';
import './styles.scss';

const ValidatePassword: React.FC<Props> = ({
  className,
  password,
  oldPassword = '',
  confirmPassword = '',
  onChange,
  isSignupPage = false,
  isConfirmPW = true,
  defaultValidate = [
    { message: 'Must have at least 8 character', isValid: (password) => /.{8,}/.test(password) },
    { message: 'Must have at least 1 lowercase', isValid: (password) => /[a-z]/.test(password) },
    { message: 'Must have at least 1 uppercase', isValid: (password) => /[A-Z]/.test(password) },
    { message: 'Must have at least 1 number', isValid: (password) => /[0-9]/.test(password) },
    {
      message: 'Must have at least 1 special characters',
      isValid: (password) => /.*[!@#$%^*&]/.test(password),
    },
  ],
}) => {
  const validateSchemas: Array<{
    message: string;
    isValid: (value: string) => boolean;
  }> = useMemo(() => {
    const oldPasswordCheck = oldPassword
      ? [
          {
            message: 'Must not be the same as current password',
            isValid: (password) => oldPassword !== password,
          },
        ]
      : [];

    const confirmPasswordCheck = confirmPassword
      ? [
          {
            message: 'Confirm password be the same as password',
            isValid: (password) => password === confirmPassword,
          },
        ]
      : [];

    return [
      ...defaultValidate,
      ...(isConfirmPW ? oldPasswordCheck : []),
      ...(isConfirmPW ? confirmPasswordCheck : []),
    ];
  }, [confirmPassword, defaultValidate, isConfirmPW, oldPassword]);

  useEffect(() => {
    if (onChange) {
      onChange(validateSchemas?.every((x) => x?.isValid(password)));
    }
  }, [password, confirmPassword, onChange, validateSchemas]);

  const isShow = !!password;

  return (
    <View
      style={{
        display: `${isSignupPage ? 'grid' : 'flex'}`,
        gridTemplateColumns: 'auto auto',
      }}
      className={cn('cmp-validate-password', className, { 'cmp-validate-password--show': isShow })}
    >
      {validateSchemas.map((item, idx) => {
        const isValid = item?.isValid(password);
        return (
          <View
            key={`validate-password__item-${idx}`}
            className={cn(
              'cmp-validate-password__item',
              { 'cmp-validate-password__item--valid': isValid },
              { 'cmp-validate-password__item--invalid': !isValid }
            )}
            isRowWrap
            align="center"
            justify="flex-start"
          >
            <Icon name={isValid ? 'ic_check' : 'ic_close'} size={16} />
            <Text size={12} className="ml-8">
              {item.message}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

type Props = {
  className?: string;
  onChange?: Callback;
  password: string;
  oldPassword?: string;
  isSignupPage?: boolean;
  confirmPassword?: string;
  defaultValidate?: any[];
  isConfirmPW?: boolean;
};

export default ValidatePassword;
