import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { IRootState } from 'src/redux/rootReducer';
import NoPermission from '../StartupContainers/NoPermission';
import { useProSidebar } from 'react-pro-sidebar';

const NotPermission: React.FC<Props> = ({ myProfile }) => {
  const { collapsed, broken } = useProSidebar();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: broken ? '100vw' : collapsed ? 'calc(100vw - 90px)' : 'calc(100vw - 300px)',
        height: '100vh',
      }}
    >
      <Grid container spacing={1}>
        <NoPermission />
      </Grid>
    </Box>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  myProfile: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NotPermission);
