/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable security/detect-object-injection */
import { flatten } from 'lodash';
import {
  Display,
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
  MUISortOptions,
} from 'mui-datatables';
import React, { memo, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { TableBasic } from 'src/components/common';
import { isEmpty } from 'src/validations';
import './styles.scss';

export enum TableQueryParams {
  SEARCH = 'search',
  ROWS_PER_PAGE = 'rowsPerPage',
  PAGE = 'page',
  SORT = 'sort',
  FILTER = 'filter',
}
export enum LOCAL_KEY {
  KEY = 'key',
}

const Table: React.FC<Props> = ({
  isLoading,
  title,
  data,
  tableOptions,
  columns,
  refresh = true,
  noColorStyle = false,
  defaultSortOrder,
  emptyComponent,
  viewColumns,
  onAction,
  isShowCountSelected = false,
  grayHeader = false,
  isEditMode = false,
  customRowsPerPageOptions = null,
  customRowsPerPage = null,
  loadingComponent = null,
  forceUpdate,
  rowSelectedBackgroundColor,
  isFilterColumn = false,
  isLocalStorage = false,
  keySortLocalStorage = null,
  ...props
}) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const tableStateRef = useRef<MUIDataTableState>();

  useEffect(() => {
    if (refresh) {
      handleTriggerAction();
    }
  }, [refresh, location]);

  const getInitialTableState = (queryParams: URLSearchParams): Partial<MUIDataTableOptions> => {
    let sortOrder;
    if (queryParams?.get('sort')?.includes(':')) {
      const sortOrderSplit = queryParams?.get('sort')?.split(':');
      if (sortOrderSplit.length === 2 && ['asc', 'desc'].includes(sortOrderSplit[1])) {
        sortOrder = {
          name: sortOrderSplit[0],
          direction: sortOrderSplit[1],
        };
      }
    }

    return {
      searchText: queryParams?.get('search'),
      sortOrder,
      rowsPerPageOptions: appConfig.ROWS_PER_PAGE_OPTIONS,
      rowsPerPage: queryParams?.has('rowsPerPage')
        ? Number(queryParams.get('rowsPerPage'))
        : customRowsPerPage
        ? customRowsPerPage
        : appConfig.ROWS_PER_PAGE,
      page: queryParams?.has('page') ? Number(queryParams.get('page')) : 0,
    };
  };

  const currentState: Partial<MUIDataTableOptions> = getInitialTableState(query);
  const currentFilterList = query?.getAll('filter')?.map((f) => (f ? f.split(',') : []));

  const getFilterParams = (filterList?: string[][]) => {
    if (!filterList) return {};
    const params: any = {};

    filterList.forEach((filter: string[], idx: number) => {
      if (filter.length > 0) {
        const column = columns[idx];
        const name = column?.name;
        params[name] = filter;
      }
    });

    return params;
  };

  const getActionParams = () => {
    const rowsPerPage = currentState?.rowsPerPage;
    const page = currentState?.page;
    const searchText = currentState?.searchText;

    const filterTableParams = getFilterParams(currentFilterList);
    const params = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: currentState?.sortOrder?.name || defaultSortOrder?.name,
      order: currentState?.sortOrder?.direction || defaultSortOrder?.direction,
      search: searchText,
      ...filterTableParams,
    };

    return params;
  };

  const setQueryParams = () => {
    const tableState = tableStateRef.current;

    const filterTableParams = getFilterParams(currentFilterList);
    if (!isLocalStorage) {
      query.delete(LOCAL_KEY.KEY);
    }
    if (tableState?.searchText) {
      query.set(TableQueryParams.SEARCH, tableState.searchText);
    } else {
      query.delete(TableQueryParams.SEARCH);
    }

    if (tableState?.rowsPerPage) {
      const rowsPerPage = tableState.rowsPerPage.toString();
      query.set(TableQueryParams.ROWS_PER_PAGE, rowsPerPage);
    } else {
      query.delete(TableQueryParams.ROWS_PER_PAGE);
    }

    if (tableState?.page) {
      const page = tableState.page.toString();
      query.set(TableQueryParams.PAGE, page);
    } else {
      query.delete(TableQueryParams.PAGE);
    }

    if (tableState?.sortOrder.name && tableState?.sortOrder.direction) {
      const sort = `${tableState?.sortOrder.name}:${tableState?.sortOrder.direction}`;
      query.set(TableQueryParams.SORT, sort);
    } else {
      query.delete(TableQueryParams.SORT);
    }

    if (
      tableState?.filterList &&
      flatten(tableState.filterList).length > 0 &&
      !isEmpty(filterTableParams) &&
      isFilterColumn
    ) {
      query.delete(TableQueryParams.FILTER);
      tableState.filterList.forEach((f) => {
        query.append(TableQueryParams.FILTER, f.join(','));
      });
    } else {
      // query.delete(TableQueryParams.FILTER);
    }
    if (!isLocalStorage) {
      history.push({ search: query.toString() });
    } else {
      query.delete(LOCAL_KEY.KEY);
      query.append(LOCAL_KEY.KEY, keySortLocalStorage);
      history.push({ search: query.toString() });
    }
  };

  useEffect(() => {
    const { name, direction } = defaultSortOrder || {};
    if (name && direction) {
      const sort = `${name}:${direction}`;
      query.set(TableQueryParams.SORT, sort);
      history.push({ search: query.toString() });
    }
  }, []);

  const handleTriggerAction = () => {
    const params = getActionParams();
    onAction(params);
  };

  const handleTableChange = async (action: any, tableState: MUIDataTableState) => {
    tableStateRef.current = tableState;
    switch (action) {
      case 'sort':
      case 'filterChange':
      case 'changeRowsPerPage':
      case 'changePage':
      case 'search':
      case 'resetFilters':
        setQueryParams();
        break;
      default:
        break;
    }
  };

  return (
    <TableBasic
      noColorStyle={noColorStyle}
      isEditMode={isEditMode}
      isShowCountSelected={isShowCountSelected}
      title={title}
      data={data}
      grayHeader={grayHeader}
      columns={columns?.map((c, index) => ({
        ...c,
        options: {
          ...c.options,
          filterList: currentFilterList[index],
          display:
            isEmpty(viewColumns) || !c.name
              ? 'true'
              : (`${viewColumns?.includes(c.name)}` as Display),
        },
      }))}
      options={{ ...tableOptions, ...currentState }}
      onTableChange={handleTableChange}
      containerClassName="cmp-table"
      isLoading={isLoading}
      loadingComponent={loadingComponent}
      emptyComponent={emptyComponent}
      customRowsPerPageOptions={customRowsPerPageOptions}
      forceUpdate={forceUpdate}
      rowSelectedBackgroundColor={rowSelectedBackgroundColor}
      isFilterColumn={isFilterColumn}
      {...props}
    />
  );
};

type Props = {
  title?: React.ReactNode;
  data: any[];
  tableOptions: MUIDataTableOptions;
  columns: MUIDataTableColumn[];
  noColorStyle?: boolean;
  refresh?: boolean | number | string;
  onAction: (...args: any[]) => void;
  defaultSortOrder?: MUISortOptions;
  isLoading?: boolean;
  emptyComponent?: React.ReactNode;
  viewColumns?: string[];
  isShowCountSelected?: boolean;
  grayHeader?: boolean;
  isEditMode?: boolean;
  customRowsPerPageOptions?: number[];
  customRowsPerPage?: number;
  loadingComponent?: React.ReactNode;
  forceUpdate?: {};
  rowSelectedBackgroundColor?: string;
  isFilterColumn?: boolean;
  isLocalStorage?: boolean;
  keySortLocalStorage?: string;
} & MUIDataTableProps;

export default memo(Table);
