import { Box } from '@mui/material';
import React from 'react';

const PipelineIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <>
      <Box style={{ borderRadius: '16px', padding: '5px', alignItems: 'center', display: 'flex' }}>
        <svg width="17" height="16" viewBox="0 0 18 16" fill={color} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" >
          <path d="M2.34245 0C1.73894 0 1.16014 0.210714 0.733389 0.585786C0.30664 0.960859 0.0668945 1.46957 0.0668945 2V14C0.0668945 14.5304 0.30664 15.0391 0.733389 15.4142C1.16014 15.7893 1.73894 16 2.34245 16H14.858C15.4615 16 16.0403 15.7893 16.4671 15.4142C16.8938 15.0391 17.1336 14.5304 17.1336 14V2C17.1336 1.46957 16.8938 0.960859 16.4671 0.585786C16.0403 0.210714 15.4615 0 14.858 0L2.34245 0ZM8.03134 2H9.16912C9.47087 2 9.76027 2.10536 9.97365 2.29289C10.187 2.48043 10.3069 2.73478 10.3069 3V6C10.3069 6.26522 10.187 6.51957 9.97365 6.70711C9.76027 6.89464 9.47087 7 9.16912 7H8.03134C7.72958 7 7.44018 6.89464 7.22681 6.70711C7.01343 6.51957 6.89356 6.26522 6.89356 6V3C6.89356 2.73478 7.01343 2.48043 7.22681 2.29289C7.44018 2.10536 7.72958 2 8.03134 2ZM2.34245 3C2.34245 2.73478 2.46232 2.48043 2.6757 2.29289C2.88907 2.10536 3.17847 2 3.48023 2H4.61801C4.91976 2 5.20916 2.10536 5.42254 2.29289C5.63591 2.48043 5.75578 2.73478 5.75578 3V10C5.75578 10.2652 5.63591 10.5196 5.42254 10.7071C5.20916 10.8946 4.91976 11 4.61801 11H3.48023C3.17847 11 2.88907 10.8946 2.6757 10.7071C2.46232 10.5196 2.34245 10.2652 2.34245 10V3ZM12.5824 2H13.7202C14.022 2 14.3114 2.10536 14.5248 2.29289C14.7381 2.48043 14.858 2.73478 14.858 3V13C14.858 13.2652 14.7381 13.5196 14.5248 13.7071C14.3114 13.8946 14.022 14 13.7202 14H12.5824C12.2807 14 11.9913 13.8946 11.7779 13.7071C11.5645 13.5196 11.4447 13.2652 11.4447 13V3C11.4447 2.73478 11.5645 2.48043 11.7779 2.29289C11.9913 2.10536 12.2807 2 12.5824 2Z"
            fill={color} />
        </svg>
      </Box>
    </>
  )

}

export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};

export default PipelineIcon


