//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const InboxIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M5.625 18.125C5.45924 18.125 5.30027 18.0592 5.18306 17.9419C5.06585 17.8247 5 17.6658 5 17.5V15H4.0625C3.31683 14.9992 2.60194 14.7026 2.07468 14.1753C1.54741 13.6481 1.25083 12.9332 1.25 12.1875V4.6875C1.25083 3.94183 1.54741 3.22694 2.07468 2.69968C2.60194 2.17241 3.31683 1.87583 4.0625 1.875H15.9375C16.6832 1.87583 17.3981 2.17241 17.9253 2.69968C18.4526 3.22694 18.7492 3.94183 18.75 4.6875V12.1875C18.7492 12.9332 18.4526 13.6481 17.9253 14.1753C17.3981 14.7026 16.6832 14.9992 15.9375 15H9.59922L6.02539 17.9801C5.91292 18.0736 5.77128 18.1249 5.625 18.125V18.125Z"
          fill={color} />
      </svg>

    </Box>

  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default InboxIcon;
