import { TableParams } from 'src/redux/types';

export enum PRODUCT_SEARCH_KEY {
  PRODUCT_NAME = 'name',
  PRODUCT_CODE = 'code',
  PRICE = 'price',
  COVERAGE = 'coverage',
  STATUS = 'status',
  CREATED_AT = 'createdAt',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};

export const KEY_LOCAL_ER_GUARD_PRODUCT = 'ER_GUARD_PRODUCT';
