import styled from '@emotion/styled';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import { Image } from '../../common';

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div<{ rtl?: boolean }>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `}
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children, ...rest }) => {
  const { rtl, collapseSidebar, collapsed } = useProSidebar();
  // const dispatch = useDispatch();

  return (
    <StyledSidebarHeader {...rest}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: !collapsed ? 'center' : 'normal',
        }}
      >
        {collapsed && (
          <StyledLogo rtl={rtl}>
            <Image src={IMAGES.logo} />
          </StyledLogo>
        )}
        {/* <Typography variant="subtitle1" fontWeight={700} color="#0098e5">
          Medicare
        </Typography> */}
        <Toolbar>
          <Link to={PATHS.root}>
            <Image src={IMAGES.logo} />
          </Link>
        </Toolbar>
        <Box
          className="toggle-dt"
          sx={{
            color: '#6D7176',
            position: 'absolute',
            right: '-10px',
            zIndex: '9',
            //border: '1px solid #EDEFF1',
            boxShadow: '1px 2px 4px #8d8d8d',
            lineHeight: '20px',
            borderColor: '#EDEFF1',
            borderRadius: '50%',
            fontWeight: 'bold',
            fontSize: '22px',
            width: '32px',
            height: '32px',
            backgroundColor: '#ffffff',
            top: '55px',
            cursor: 'pointer',
          }}
          onClick={() => {
            collapseSidebar(!collapsed);
            // dispatch(setShowSidebar(!collapsed));
          }}
        >
          {/* changing menu collapse icon on click */}
          {collapsed ? (
            <KeyboardArrowRightIcon
              sx={{
                top: '5px',
                left: '5px',
                position: 'relative',
              }}
            />
          ) : (
            <KeyboardArrowLeftIcon
              sx={{
                top: '5px',
                left: '5px',
                position: 'relative',
              }}
            />
          )}
        </Box>
      </div>
    </StyledSidebarHeader>
  );
};
