import { ReactNode } from 'react';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import TextsmsIcon from '@mui/icons-material/Textsms';
import WindowIcon from '@mui/icons-material/Window';
import { Permission } from 'src/redux/auth/types';
import appConfig from '.';
import { PATHS } from './paths';

export const isActive = (href: string) => {
  return window.location.pathname === href;
};
export type MenuType = {
  title: string;
  name?: string;
  icon: ReactNode;
  subMenu?: { title: string; href: string }[];
  href?: string;
  permissions: Permission[];
  otherHref?: string;
  groupHref?: string[];
};

export const Other: MenuType[] = [
  {
    title: 'Branch Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.branchManagement}`,
    permissions: appConfig.DEAL_MANAGEMENT,
  },
  {
    title: 'Company Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.companyManagement}`,
    permissions: appConfig.DEAL_MANAGEMENT,
  },
];
export const OrderOther: MenuType[] = [
  {
    title: 'Online Order',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.onlineOrder}`,
    permissions: appConfig.ORDER_MANAGEMENT,
  },
  {
    title: 'Retail Order',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.retailOrder}`,
    permissions: appConfig.ORDER_MANAGEMENT,
  },
  {
    title: 'Void Request',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.voidRequest}`,
    permissions: appConfig.ORDER_MANAGEMENT,
  },
  {
    title: 'Create Order',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.createOrder}`,
    permissions: appConfig.ORDER_MANAGEMENT,
  },
];
export const PaymentOther: MenuType[] = [
  {
    title: 'Online Order Payment',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.onlineOrderPayment}`,
    permissions: appConfig.PAYMENT_MANAGEMENT,
  },
  {
    title: 'Retail Order Payment',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.retailOrderPayment}`,
    permissions: appConfig.PAYMENT_MANAGEMENT,
  },
  {
    title: 'Retail Order Payment Request',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.retailOrderPaymentRequest}`,
    permissions: appConfig.PAYMENT_MANAGEMENT,
  },
];
export const DashboardMenu: MenuType[] = [
  {
    title: 'Sale Dashboard',
    icon: <WindowIcon />,
    href: `${PATHS.dashboard}${PATHS.saleDashboard}`,
    permissions: appConfig.SALES_DASHBOARD,
  },
  {
    title: 'UPD Dashboard',
    icon: <WindowIcon />,
    href: `${PATHS.dashboard}${PATHS.uPDDashboard}`,
    permissions: appConfig.UPD_DASHBOARD,
  },
  {
    title: 'Medical Dashboard',
    icon: <WindowIcon />,
    href: `${PATHS.dashboard}${PATHS.medDashboard}`,
    permissions: appConfig.MEDICAL_DASHBOARD,
  },
  {
    title: 'Finance Dashboard',
    icon: <WindowIcon />,
    href: `${PATHS.dashboard}${PATHS.financeDashboard}`,
    permissions: appConfig.FINANCE_DASHBOARD,
  },
];

export const CustomerMenu: MenuType[] = [
  {
    title: 'Lead Inbox',
    icon: <TextsmsIcon />,
    href: `${PATHS.sale}${PATHS.leadInbox}`,
    otherHref: `${PATHS.leadInbox}/:leadId`,
    permissions: appConfig.LEAD_MANAGEMENT,
  },
  {
    title: 'Deals Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.sale}${PATHS.dealsManagement}`,
    permissions: appConfig.DEAL_MANAGEMENT,
  },
];

export const AccountAndMemberMenu: MenuType[] = [
  {
    title: 'Account Records',
    icon: <TextsmsIcon />,
    href: `${PATHS.accountAndMembers}${PATHS.accountRecords}`,
    permissions: appConfig.ACCOUNT_RECORD_MANAGEMENT,
  },
  {
    title: 'Member Records',
    icon: <TextsmsIcon />,
    href: `${PATHS.accountAndMembers}${PATHS.memberRecords}`,
    permissions: appConfig.MEMBER_RECORD_MANAGEMENT,
  },
];

export const ERGuardMenu: MenuType[] = [
  {
    title: 'Dashboard',
    icon: <TextsmsIcon />,
    href: `${PATHS.erGuard}${PATHS.erGuardDashBoard}`,
    permissions: appConfig.DASHBOARD_ERGUARD,
  },
  {
    title: 'Order Management',
    icon: <TextsmsIcon />,
    href: `${PATHS.erGuard}${PATHS.onlineOrder}`,
    groupHref: [
      `${PATHS.erGuard}${PATHS.onlineOrder}`,
      `${PATHS.erGuard}${PATHS.retailOrder}`,
      `${PATHS.erGuard}${PATHS.createOrder}`,
      `${PATHS.erGuard}${PATHS.voidRequest}`,
    ],
    permissions: appConfig.ORDER_MANAGEMENT,
  },
  {
    title: 'Company Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.branchManagement}`,
    groupHref: [
      `${PATHS.erGuard}${PATHS.branchManagement}`,
      `${PATHS.erGuard}${PATHS.companyManagement}`,
    ],
    permissions: appConfig.COMPANY_MANAGEMENT,
  },
  {
    title: 'Product Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.productManagement}`,
    permissions: appConfig.PRODUCT_MANAGEMENT,
  },
  {
    title: 'User Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.eRGuardUserManagement}`,
    permissions: appConfig.ER_USERMANAGEMENT,
  },
  {
    title: 'Payment Management',
    icon: <CalendarMonthIcon />,
    href: `${PATHS.erGuard}${PATHS.onlineOrderPayment}`,
    permissions: appConfig.PAYMENT_MANAGEMENT,
    groupHref: [
      `${PATHS.erGuard}${PATHS.onlineOrderPayment}`,
      `${PATHS.erGuard}${PATHS.retailOrderPayment}`,
      `${PATHS.erGuard}${PATHS.retailOrderPaymentRequest}`,
    ],
  },
];

export const UserMenu: MenuType[] = [
  {
    title: 'Users Listing',
    icon: <PersonIcon />,
    href: `${PATHS.admin}${PATHS.userListing}`,
    permissions: appConfig.USER_MANAGEMENT,
  },
  {
    title: 'Roles & Permissions',
    icon: <AccountCircleIcon />,
    href: `${PATHS.admin}${PATHS.userPermissions}`,
    permissions: appConfig.ROLE_MANAGEMENT,
  },

  {
    title: 'Team Management',
    icon: <PeopleIcon />,
    href: `${PATHS.admin}${PATHS.teamManagement}`,
    permissions: appConfig.TEAM_MANAGEMENT,
  },
];
export const ConfigurationMenu: MenuType[] = [
  {
    title: 'Products',
    icon: <PersonIcon />,
    href: `${PATHS.configuration}${PATHS.products}`,
    permissions: appConfig.CONFIGURATION_PRODUCT,
  },
  {
    title: 'Benefits',
    icon: <PersonIcon />,
    href: `${PATHS.configuration}${PATHS.benefits}`,
    permissions: appConfig.CONFIGURATION_BENEFITS,
  },
  {
    title: 'Matrix',
    icon: <PersonIcon />,
    href: `${PATHS.configuration}${PATHS.matrix}`,
    permissions: appConfig.CONFIGURATION_MATRIX,
  },
];
export const SidebarMenu = [
  ...UserMenu,
  ...DashboardMenu,
  ...ERGuardMenu,
  ...CustomerMenu,
  ...AccountAndMemberMenu,
  ...Other,
  ...OrderOther,
  ...PaymentOther,
  ...ConfigurationMenu,
].map((data) => ({ ...data, name: data.name ?? data.permissions[0]?.split(':')?.[0] }));
