import {
  AccountManagementPermission,
  AccountRecordPermission,
  ApprovalPermission,
  BenefitsPermission,
  BillingPermission,
  BranchManagement,
  CompanyManagement,
  DashboardERGuardPermission,
  DealManagementPermission,
  FinanceDashboard,
  LeadManagementPermission,
  MatrixPermission,
  MedicalDashboard,
  MemberRecordPermission,
  OrderManagement,
  OverridePremiumPermission,
  PaymentManagement,
  ProductManagement,
  ProductsPermission,
  SalesDashboard,
  SystemRoleAndPermissionPermission,
  TeamManagementPermission,
  UPDDashboard,
  UnassignedRecords,
  UnvoidPermission,
  UserManagementERGuard,
} from 'src/queries/Profile/types';

let _permissions: string[] = [];

const setPermissions = async (payload: string[]) => {
  try {
    // const permissions = JSON.parse(payload?.['permissions']);
    _permissions = payload;
  } catch {
    return null;
  }
};

const clearPermission = () => {
  _permissions = [];
};

const getPermissions = () => {
  return _permissions;
};

const saleDashboard = () => ({
  canView: _permissions?.includes(SalesDashboard.Read),
});

const updDashboard = () => ({
  canView: _permissions?.includes(UPDDashboard.Read),
});

const medDashboard = () => ({
  canView: _permissions?.includes(MedicalDashboard.Read),
});

const financeDashboard = () => ({
  canView: _permissions?.includes(FinanceDashboard.Read),
});
const lead = () => ({
  canCreate: _permissions?.includes(LeadManagementPermission.Create),
  canView: _permissions?.includes(LeadManagementPermission.Read),
  canUpdate: _permissions?.includes(LeadManagementPermission.Update),
  canDelete: _permissions?.includes(LeadManagementPermission.Delete),
});

const deal = () => ({
  canCreate: _permissions?.includes(DealManagementPermission.Create),
  canView: _permissions?.includes(DealManagementPermission.Read),
  canUpdate: _permissions?.includes(DealManagementPermission.Update),
  canDelete: _permissions?.includes(DealManagementPermission.Delete),
});

const overridePremium = () => ({
  canUpdate: _permissions?.includes(OverridePremiumPermission.Update),
});

const team = () => ({
  canCreate: _permissions?.includes(TeamManagementPermission.Create),
  canView: _permissions?.includes(TeamManagementPermission.Read),
  canUpdate: _permissions?.includes(TeamManagementPermission.Update),
  canDelete: _permissions?.includes(TeamManagementPermission.Delete),
});

const roleAndPermission = () => ({
  canCreate: _permissions?.includes(SystemRoleAndPermissionPermission.Create),
  canView: _permissions?.includes(SystemRoleAndPermissionPermission.Read),
  canUpdate: _permissions?.includes(SystemRoleAndPermissionPermission.Update),
  canDelete: _permissions?.includes(SystemRoleAndPermissionPermission.Delete),
});

const account = () => ({
  canCreate: _permissions?.includes(AccountManagementPermission.Create),
  canView: _permissions?.includes(AccountManagementPermission.Read),
  canUpdate: _permissions?.includes(AccountManagementPermission.Update),
  canDelete: _permissions?.includes(AccountManagementPermission.Delete),
});

const accountRecord = () => ({
  canCreate: _permissions?.includes(AccountRecordPermission.Create),
  canView: _permissions?.includes(AccountRecordPermission.Read),
  canUpdate: _permissions?.includes(AccountRecordPermission.Update),
  canDelete: _permissions?.includes(AccountRecordPermission.Delete),
});

const memberRecord = () => ({
  canCreate: _permissions?.includes(MemberRecordPermission.Create),
  canView: _permissions?.includes(MemberRecordPermission.Read),
  canUpdate: _permissions?.includes(MemberRecordPermission.Update),
  canDelete: _permissions?.includes(MemberRecordPermission.Delete),
});

const billing = () => ({
  canCreate: _permissions?.includes(BillingPermission.Create),
  canView: _permissions?.includes(BillingPermission.Read),
  canUpdate: _permissions?.includes(BillingPermission.Update),
  canDelete: _permissions?.includes(BillingPermission.Delete),
});

const approval = () => ({
  canUpdateSalesManager: _permissions?.includes(ApprovalPermission.UpdateSaleManager),
  canUpdateSeniorSalesManager: _permissions?.includes(ApprovalPermission.UpdateSeniorSalesManager),
  canUpdateHeadOfSales: _permissions?.includes(ApprovalPermission.UpdateHeadOfSales),
  canUpdateUPDSupervisor: _permissions?.includes(ApprovalPermission.UpdateUPDSupervisor),
  canUpdateUPDManager: _permissions?.includes(ApprovalPermission.UpdateUPDManager),
  canUpdateUPDOfficer: _permissions?.includes(ApprovalPermission.UpdateUPDOfficer),
  canUpdateFinance: _permissions?.includes(ApprovalPermission.UpdateFinance),
  canUpdateMedical: _permissions?.includes(ApprovalPermission.UpdateMedical),
});

const unassignrecords = () => ({
  canView: _permissions?.includes(UnassignedRecords.Read),
  canUpdate: _permissions?.includes(UnassignedRecords.Update),
  canDelete: _permissions?.includes(UnassignedRecords.Delete),
});

const userManagementERGuard = () => ({
  canCreate: _permissions?.includes(UserManagementERGuard.Create),
  canUpdate: _permissions?.includes(UserManagementERGuard.Update),
  canView: _permissions?.includes(UserManagementERGuard.View),
});
// Er Guard
const orderManagement = () => ({
  canView: _permissions?.includes(OrderManagement.Read),
  canCreate: _permissions?.includes(OrderManagement.Create),
  canUpdate: _permissions?.includes(OrderManagement.Update),
  canDelete: _permissions?.includes(OrderManagement.Delete),
});

const branchManagement = () => ({
  canView: _permissions?.includes(BranchManagement.Read),
  canCreate: _permissions?.includes(BranchManagement.Create),
  canUpdate: _permissions?.includes(BranchManagement.Update),
});

const productManagement = () => ({
  canView: _permissions?.includes(ProductManagement.Read),
  canCreate: _permissions?.includes(ProductManagement.Create),
  canUpdate: _permissions?.includes(ProductManagement.Update),
  canDelete: _permissions?.includes(ProductManagement.Delete),
});

const companyManagement = () => ({
  canView: _permissions?.includes(CompanyManagement.Read),
  canCreate: _permissions?.includes(CompanyManagement.Create),
  canUpdate: _permissions?.includes(CompanyManagement.Update),
});
const paymentManagement = () => ({
  canView: _permissions?.includes(PaymentManagement.Read),
  canCreate: _permissions?.includes(PaymentManagement.Create),
  canUpdate: _permissions?.includes(PaymentManagement.Update),
});
const unvoidPermission = () => ({
  canCreate: _permissions?.includes(UnvoidPermission.Create),
  canView: _permissions?.includes(UnvoidPermission.Read),
  canUpdate: _permissions?.includes(UnvoidPermission.Update),
  canDelete: _permissions?.includes(UnvoidPermission.Delete),
});

const productsPermission = () => ({
  canCreate: _permissions?.includes(ProductsPermission.Create),
  canView: _permissions?.includes(ProductsPermission.Read),
  canUpdate: _permissions?.includes(ProductsPermission.Update),
  canDelete: _permissions?.includes(ProductsPermission.Delete),
});
const benefitsPermission = () => ({
  canCreate: _permissions?.includes(BenefitsPermission.Create),
  canView: _permissions?.includes(BenefitsPermission.Read),
  canUpdate: _permissions?.includes(BenefitsPermission.Update),
  canDelete: _permissions?.includes(BenefitsPermission.Delete),
});
const matrixPermission = () => ({
  canCreate: _permissions?.includes(MatrixPermission.Create),
  canView: _permissions?.includes(MatrixPermission.Read),
  canUpdate: _permissions?.includes(MatrixPermission.Update),
  canDelete: _permissions?.includes(MatrixPermission.Delete),
});

const dashboardERGuard = () => ({
  canView: _permissions?.includes(DashboardERGuardPermission.Read),
});
export default {
  unassignrecords,
  setPermissions,
  clearPermission,
  getPermissions,
  lead,
  deal,
  team,
  roleAndPermission,
  account,
  accountRecord,
  approval,
  saleDashboard,
  updDashboard,
  userManagementERGuard,
  orderManagement,
  branchManagement,
  paymentManagement,
  companyManagement,
  productManagement,
  overridePremium,
  medDashboard,
  memberRecord,
  billing,
  financeDashboard,
  unvoidPermission,
  productsPermission,
  benefitsPermission,
  matrixPermission,
  dashboardERGuard,
};
