import { TableParams } from 'src/redux/types';

export enum ERQUARD_USERS_KEY {
  NAME = 'name',
  USERNAME = 'username',
  EMAIL = 'email',
  STATUS = 'status',
  ROLE = 'role',
  BRANCH = 'branch',
  COMPANY = 'company',
  CREATED_DATE = 'createdDate',
  LAST_LOGIN = 'lastLogin',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
