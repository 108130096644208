import { Avatar, Backdrop, Box, CircularProgress, Grid, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useDisabledPushNotification } from 'src/queries/Notification/useDisabledPushNotification';
import { LOCAL_STORAGE_FIREBASE_TOKEN } from 'src/services/content';
import { useLogout, useProfile } from 'src/queries';
import { TokenService, Navigator } from 'src/services';
import { PATHS } from 'src/appConfig/paths';
import { useQueryUrl } from 'src/queries/File/useQueryUrl';

const UserProfile = () => {
  const userProfileRef = useRef(null);
  const [isShowPopoverProfile, setIsShowPopoverProfile] = useState(false);
  const [isLoadingLogout, setIsLoadingLogout] = useState(false);
  const { disableNotification } = useDisabledPushNotification();
  const { logout } = useLogout();
  const { profile } = useProfile();
  const { profile: information, fullName, firstName, lastName } = profile || {};
  const { jobTitle, avatarUrl } = information || {};
  const handleShowPopover = () => {
    setIsShowPopoverProfile(true);
  };
  const handleClickOutside = (event) => {
    if (userProfileRef.current && !userProfileRef.current.contains(event.target)) {
      setIsShowPopoverProfile(false);
    }
  };
  const handleLogout = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const firebaseToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FIREBASE_TOKEN));
        if (firebaseToken) {
          const tokenPayload = {
            token: firebaseToken,
          };
          disableNotification(tokenPayload);
        }
      }
    });
    setIsLoadingLogout(true);
    logout();
    TokenService.clearToken();
    localStorage.removeItem(LOCAL_STORAGE_FIREBASE_TOKEN);
  };
  const handleNavigationToMyProfile = () => {
    Navigator.navigate(`${PATHS.myProfile}`);
    setIsShowPopoverProfile(false);
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const { url, isLoading } = useQueryUrl({ filePath: information?.avatarUrl });
  return (
    <React.Fragment>
      {isLoadingLogout ? (
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      ) : (
        <div className="user-profile-popover" ref={userProfileRef}>
          <div aria-hidden className="user-profile-button" onClick={handleShowPopover}>
            {isLoading ? (
              <Skeleton variant="circular" className="avatar" />
            ) : (
              <>
                {url ? (
                  <Avatar className="avatar" src={url} />
                ) : (
                  <Avatar className="avatar">{`${firstName?.substring(0, 1)}${lastName?.substring(
                    0,
                    1
                  )}`}</Avatar>
                )}
              </>
            )}
          </div>
          <div
            className="popover-user"
            style={{ display: !isShowPopoverProfile ? 'none' : 'block' }}
          >
            <div className="popover-user-container">
              <div className="user-profile">
                {isLoading ? (
                  <Skeleton variant="circular" className="avatar" />
                ) : (
                  <>
                    {url ? (
                      <Avatar className="avatar" src={url} />
                    ) : (
                      <Avatar className="avatar">{`${firstName?.substring(
                        0,
                        1
                      )}${lastName?.substring(0, 1)}`}</Avatar>
                    )}
                  </>
                )}
                <div className="information">
                  <p className="full-name">{fullName}</p>
                  <p className="job-title">{jobTitle ?? '--'}</p>
                </div>
              </div>
              <div className="menu-list">
                <div aria-hidden className="menu-item" onClick={handleNavigationToMyProfile}>
                  <p>My Profile</p>
                </div>
              </div>
              <div style={{ borderTop: '1px solid var(--other-stroke, #CFD4D9)' }} />
              <div className="menu-list">
                <div aria-hidden className="menu-item" onClick={handleLogout}>
                  <p>Log out</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserProfile;
