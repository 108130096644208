import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { PATHS } from 'src/appConfig/paths';
import { Link } from 'src/components/common';
import ChipCommon from 'src/components/common/Chip';
import { formartCurrency } from 'src/containers/ERGuardContainers/components/OrderManagement/components/RetailOrder/CreateOrder/helpers';
import navigator from 'src/services/navigator';
import { stringify } from 'src/utils';
import { PRODUCT_SEARCH_KEY } from './helpers';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: PRODUCT_SEARCH_KEY.PRODUCT_NAME,
    label: 'Product Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        const queryString = stringify({ uuid: rowData.code });
        const handleClick = (e) => {
          e.preventDefault();
          navigator.navigate(`${PATHS.erGuard}${PATHS.productManagement}?${queryString}`);
        };
        return (
          <Link
            href={`${PATHS.erGuard}${PATHS.productManagement}?${queryString}`}
            onClick={handleClick}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? '--'}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: PRODUCT_SEARCH_KEY.PRODUCT_CODE,
    label: 'Product Code',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ?? '--'}</div>;
      },
    },
  },
  {
    name: PRODUCT_SEARCH_KEY.PRICE,
    label: 'Product Price',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ? formartCurrency(value) : '--'}</div>;
      },
    },
  },
  {
    name: PRODUCT_SEARCH_KEY.COVERAGE,
    label: 'Product Coverage',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ? formartCurrency(value) : '--'}</div>;
      },
    },
  },
  {
    name: PRODUCT_SEARCH_KEY.STATUS,
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return <ChipCommon typeOfChip={rowData.status} text={rowData.status} />;
      },
    },
  },
  {
    name: PRODUCT_SEARCH_KEY.CREATED_AT,
    label: 'Created Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{dayjs(value).format('MM/DD/YYYY')}</div>;
      },
    },
  },
];
