import { GetPropertiesParams } from 'src/queries/Leads/types';

import { isEmpty } from 'src/validations';

export enum CUSTOM_FILTER_LEADS_KEY {
  PLAN_TYPES = 'planTypes',
  SOURCE = 'sources',
  STATUS = 'status',
  IS_VVIP = 'isVVIP',
  SALE_AGENTS = 'assignAgentIds',
  ASSIGN_TO = 'assignToValue',
}

export type CustomFilterLeadsFormValue = {
  planTypes: string[];
  sources: string[];
  isVVIP: boolean;
  // classifications: string[];
  status: string[];
  assignAgentIds?: string[];
  assignToValue?: string[];
};

export const FILTER_LEADS_INDEX = {
  PLAN_TYPE: 0,
  SOURCE: 1,
  IS_VVIP: 2,
  STATUS: 3,
  SALE_AGENTS: 4,
  ASSIGN_TO: 5,
  length: 6,
};

export enum PREFIX_FILTER_LEADS {
  PLAN = 'Plan: ',
  SOURCE = 'Source: ',
  STATUS = 'Status: ',
  SHOW_VIP = 'Is_VVIP: ',
  SALE_AGENTS = 'sale_agent: ',
  ASSIGN_TO = 'assign_to: ',
}

export const planTypeOptions = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'SECURITY_GUARD', label: 'Security Guard' },
  { value: 'CORPORATE', label: 'Corporate' },
  { value: 'PHILIPPINES_NATIONAL_POLICE', label: 'Philippines National Police', width: '50%' },
  { value: 'GOVERNMENT', label: 'Government' },
];

export const sourceOptions = [
  { value: 'PERSONAL', label: 'Personal' },
  { value: 'PHONE', label: 'Phone' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'INSTAGRAM', label: 'Instagram' },
  { value: 'WEBSITE', label: 'Website' },
  { value: 'REFERRAL', label: 'Referral' },
  { value: 'OTHER', label: 'Other' },
];

export const leadStatusOptions = [
  { value: 'NEW_LEAD', label: 'New Lead' },
  { value: 'CALLED', label: 'Called' },
  { value: 'PRESENTED', label: 'Presented' },
  { value: 'PROPOSAL_SENT', label: 'Proposal Sent' },
  { value: 'FIRST_FOLLOW_UP', label: '1st Follow up' },
  { value: 'SECOND_FOLLOW_UP', label: '2nd Follow up' },
  { value: 'THIRD_FOLLOW_UP', label: '3rd Follow up' },
  { value: 'SIGNED_PROPOSAL', label: 'Signed Proposal' },
  { value: 'LOST', label: 'Lost' },
];

export interface GetPropsParamLeads {
  planTypes?: string;
  sourceKeys?: string;
  statusKeys?: string;
}

export const getLeadsUpdatedParams = (
  params: GetPropertiesParams,
  options?: {
    planTypeKeys?: string[];
    sourceKeys?: string[];
    statusLeadKeys?: string[];
    show_vipKeys: string[];
    isArchive?: any[];
    sale_agentKeys: string[];
    assign_ToKeys: string[];
    assignTo_TeamIdsKeys: string[];
    assignTo_UserIdsKeys: string[];
  }
): GetPropertiesParams => {
  const newParams = { ...params };

  const planTypeParam = options.planTypeKeys;
  const sourceParam = options.sourceKeys;
  const statusParam = options.statusLeadKeys;
  const archiveParam = options.isArchive;
  const showVipParam = options.show_vipKeys;

  const salesAgentParam = options.sale_agentKeys;
  const assignToParam = options.assign_ToKeys;
  const assignToTeamIdsParam = options.assignTo_TeamIdsKeys;
  const assignToUserIdsParam = options.assignTo_UserIdsKeys;

  if (assignToParam?.includes('unassigned')) {
    newParams['isUnAssignTo'] = 'true';
  }

  if (salesAgentParam?.includes('Unassigned')) {
    newParams['isUnAssignAgent'] = 'true';
  }

  if (!isEmpty(planTypeParam)) {
    // newParams['planTypes'] = planTypeParam;
    newParams['planTypes'] = planTypeParam
      .map((r) => r.replace(/\s/g, '_').toUpperCase())
      .join(',');
  }
  if (!isEmpty(sourceParam)) {
    newParams['sources'] = sourceParam.map((r) => r.replace(/\s/g, '_').toUpperCase()).join(',');
  }

  if (!isEmpty(statusParam)) {
    newParams['statusLead'] = statusParam.map((r) => r.replace(/\s/g, '_').toUpperCase()).join(',');
  }

  if (!isEmpty(showVipParam)) {
    newParams['isVVIP'] = showVipParam[0];
  }
  if (!isEmpty(archiveParam)) {
    newParams['isArchive'] = archiveParam.map((r) => r.replace(/\s/g, '_')).join(',');
  }

  if (!isEmpty(salesAgentParam)) {
    newParams['assignAgentIds'] = salesAgentParam
      .filter((item) => item !== 'Unassigned')
      .map((r) => r.toLowerCase().replace(/\s/g, '_'))
      .join(',');
  }

  if (!isEmpty(assignToParam)) {
    newParams['assignToParam'] = assignToParam
      .map((r) => r.toLowerCase().replace(/\s/g, '_'))
      .join(',');
  }

  if (!isEmpty(assignToTeamIdsParam)) {
    newParams['assignToTeamIds'] = assignToTeamIdsParam
      .map((r) => r.toLowerCase().replace(/\s/g, '_'))
      .join(',');
  }

  if (!isEmpty(assignToUserIdsParam)) {
    newParams['assignToUserIds'] = assignToUserIdsParam
      .map((r) => r.toLowerCase().replace(/\s/g, '_'))
      .join(',');
  }

  delete newParams['organization'];
  delete newParams['phoneNumber'];
  delete newParams['fullname'];
  delete newParams['assignToParam'];
  delete newParams['email'];
  delete newParams[''];
  delete newParams['leadInbox'];
  return newParams;
};
