import { useQuery, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useGetListSalesManager(
  options?: UseQueryOptions<ApiResponseType<any>, Error, any>  & {
    roles?: string;
  }
) 
{
  const {
    data: listSalesManager,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetSelectedList,
  } = useQuery<ApiResponseType<any>, Error, any>(
    [API_QUERIES.SALES_MANAGER_LIST,{
     roles: options.roles
    },], {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
          return  responseWrapper<ApiResponseType<any>>(apiClient.getSalesManagerList,params);
      },
      select(data) {
        return data.data;
      },
      enabled:  !isEmpty(options.roles),
      ...options,
    }
  );



  return {
    listSalesManager,
    error,
    isError,
    isLoading,
    onGetSelectedList,
  };
}
