import { Container, Stack } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { Button } from 'src/components/common';
import { useCreateActivationForm } from 'src/queries/ERGuard/useCreateActiovationForm';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, Toastify } from 'src/services';
import FormActivation from './FormForActivation/FormActivation';
import {
  ERGuardActivationFormSchema,
  ERGuardActivationFormValue,
  ERGuardActivationFormikProps,
  formatPayloadSubmit,
  initialERGUARDActivationFormValue,
} from './helpers';

const ERGuardActivationForm: React.FC<Props> = () => {
  const [token, setToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const formRef = React.useRef<FormikProps<ERGuardActivationFormValue>>(null);
  const { createActivationForm, isLoadingCreateActivationForm } = useCreateActivationForm({
    onSuccess() {
      Navigator.jumpToWebShopMedicare('/erguard-thank-you');
    },
    onError(error, variables, context) {
      Toastify.error(`${error.message}`);
    },
  });

  const handleFormSubmit = (values: ERGuardActivationFormValue) => {
    const payload = formatPayloadSubmit(values);
    if (token) {
      setRefreshReCaptcha((r) => !r);
      createActivationForm({ ...payload, reCaptchaToken: token });
    }
  };
  const initialFormValues = React.useMemo(() => {
    return {
      ...initialERGUARDActivationFormValue,
    };
  }, []);

  const {
    values,
    setFieldValue,
    errors,
    touched,
    getFieldProps,
    setFieldTouched,
    handleSubmit,
    setErrors,
  } = useFormik<ERGuardActivationFormValue>({
    initialValues: initialFormValues,
    onSubmit: handleFormSubmit,
    validationSchema: ERGuardActivationFormSchema,
    innerRef: formRef,
    enableReinitialize: true,
  });

  const formikProps: ERGuardActivationFormikProps = {
    values,
    setFieldValue,
    errors,
    touched,
    getFieldProps,
    setFieldTouched,
    setErrors,
  };

  const handleReCaptchaVerify = useCallback(() => {
    if (!executeRecaptcha) {
      return;
    }
    executeRecaptcha('enquiryFormSubmit')
      .then((gReCaptchaToken) => {
        setToken(gReCaptchaToken);
      })
      .catch((error) => Toastify.error(error));
  }, [executeRecaptcha]);

  return (
    <Container maxWidth="md">
      <>
        <FormActivation formikProps={formikProps} />
        <GoogleReCaptcha onVerify={handleReCaptchaVerify} refreshReCaptcha={refreshReCaptcha} />
        <Stack mt={4} flexDirection={'row'} justifyContent="center" mb={8}>
          <Button
            style={{ backgroundColor: '#F89837', borderRadius: '20px' }}
            variant="secondary"
            isLoading={isLoadingCreateActivationForm}
            disabled={isLoadingCreateActivationForm}
            onClick={() => {
              handleSubmit();
            }}
          >
            {'SUBMIT'}
          </Button>
        </Stack>
      </>
    </Container>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  contents: state.common.contents,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
};
export default connect(mapStateToProps, mapDispatchToProps)(ERGuardActivationForm);
