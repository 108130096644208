import React, { ReactNode, ReactSVGElement, useEffect, useState } from 'react';
import cn from 'classnames';
import './styles.scss';
import { View, Icon, Text } from 'src/components/common';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Chip from '@mui/material/Chip';

const Accordion: React.FC<Props> = ({
  title,
  className,
  children,
  isExpanded = false,
  subTitle,
  onToggle,
  customAction,
  isSubTitle,
  typeSubTitle = 'custom',
  subTitlePlan,
  ...props
}) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  const handleToggle = () => {
    // if (disabledExpand) return;
    if (!!onToggle) {
      onToggle(!expanded);
    } else {
      setExpanded(!expanded);
    }
  };

  return (
    <View
      className={cn(
        'cmp-accordion-item',
        {
          'cmp-accordion-item--collapsed': expanded === false,
          'cmp-accordion-item--expanded': expanded === true,
        },
        className
      )}
    >
      <motion.header initial={false}>
        <View
          isRow
          style={{ borderRadius: '8px 8px 0 0' }}
          justify="space-between"
          align="center"
          className={cn(
            'cmp-accordion-item__header',
            { 'cmp-accordion-item__header--expanded': expanded === true },
            { 'cmp-accordion-item__header--collapsed': expanded === false }
          )}
        >
          <Grid container>
            <Grid
              sx={{ display: 'flex' }}
              item
              xl={8}
              lg={8}
              md={8}
              xs={8}
              sm={8}
              onClick={handleToggle}
            >
              <Icon className="mr-16 center">
                {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </Icon>
              {typeof title === 'string' ? (
                <Grid container spacing={2} display={'flex'} alignItems={'center'}>
                  <Grid item>
                    <Text className="fw-bold center" size={16}>
                      {title}
                    </Text>
                  </Grid>
                  {isSubTitle && typeSubTitle === 'standard' && (
                    <Grid item>
                      <motion.div
                      // initial={{ y: '-5px' }}
                      // animate={{ y: 0 }}
                      // transition={{ type: 'spring', stiffness: 250, delay: 0.1 }}
                      >
                        <Chip
                          label="Standard Plan"
                          sx={{
                            fontFamily: 'Poppins',
                            height: '26px',
                            fontSize: '12px',
                            fontWeight: 500,
                          }}
                        />
                      </motion.div>
                    </Grid>
                  )}
                  {isSubTitle && typeSubTitle === 'custom' && (
                    <Grid item>
                      <motion.div
                      // initial={{ y: '-5px' }}
                      // animate={{ y: 0 }}
                      // transition={{ type: 'spring', stiffness: 250, delay: 0.1 }}
                      >
                        <Chip
                          label={subTitlePlan ?? 'Custom Plan'}
                          sx={{
                            fontFamily: 'Poppins',
                            backgroundColor: '#DAEDFD',
                            height: '26px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#163485',
                          }}
                        />
                      </motion.div>
                    </Grid>
                  )}
                  {/* {isSubTitle && typeSubTitle === 'external' && (
                    <Grid item>
                      <motion.div
                      // initial={{ y: '-5px' }}
                      // animate={{ y: 0 }}
                      // transition={{ type: 'spring', stiffness: 250, delay: 0.1 }}
                      >
                        <Chip
                          label="External"
                          sx={{
                            fontFamily: 'Poppins',
                            height: '26px',
                            fontSize: '12px',
                            fontWeight: 500,
                          }}
                        />
                      </motion.div>
                    </Grid>
                  )}
                  {isSubTitle && typeSubTitle === 'regular' && (
                    <Grid item>
                      <motion.div
                      // initial={{ y: '-5px' }}
                      // animate={{ y: 0 }}
                      // transition={{ type: 'spring', stiffness: 250, delay: 0.1 }}
                      >
                        <Chip
                          label="Regular"
                          sx={{
                            fontFamily: 'Poppins',
                            backgroundColor: '#DAEDFD',
                            height: '26px',
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#163485',
                          }}
                        />
                      </motion.div>
                    </Grid>
                  )} */}
                </Grid>
              ) : (
                title
              )}
            </Grid>
            <Grid
              sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
              item
              xl={4}
              lg={4}
              md={4}
              xs={4}
              sm={4}
            >
              {expanded ? customAction : null}
            </Grid>
          </Grid>

          <View isRow justify="flex-end" align="center">
            {subTitle && <View className="mr-16">{subTitle}</View>}
          </View>
        </View>
      </motion.header>
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial={isExpanded ? 'open' : 'collapsed'}
          animate={expanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          {...props}
        >
          <View className="cmp-accordion-item__body" style={{ borderRadius: '0 0 8px 8px' }}>
            {children}
          </View>
        </motion.section>
      </AnimatePresence>
    </View>
  );
};

type Props = MotionProps & {
  title: string | ReactNode;
  children: ReactNode;
  customAction?: ReactNode;
  subTitle?: string | ReactNode;
  subTitlePlan?: string;
  className?: string;
  isExpanded?: boolean;
  customIcon?: ReactSVGElement;
  isSubTitle?: boolean;
  typeSubTitle?: 'custom' | 'standard' | 'external' | 'regular';
  onToggle?: (value: boolean) => void;
};

export default Accordion;
