import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import ChipCommon from 'src/components/common/Chip';
import { convertText } from 'src/utils';
import { ERQUARD_ORDER_KEY } from './helpers';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: ERQUARD_ORDER_KEY.ORDER_ID,
    label: 'Order ID',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <Link
            to={{
              pathname: `${PATHS.erGuard}${PATHS.retailOrder}/${rowData.id}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? ''}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: ERQUARD_ORDER_KEY.TYPE,
    label: 'Type',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{convertText(value)}</div>;
      },
    },
  },

  {
    name: ERQUARD_ORDER_KEY.PURCHASED_DATE,
    label: 'Purchsed Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ERQUARD_ORDER_KEY.CARD_ACTIVATION_DATE,
    label: 'Card Activation Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ERQUARD_ORDER_KEY.EFFECTIVE_DATE,
    label: 'Effective Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: ERQUARD_ORDER_KEY.TELLER,
    label: 'Teller',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ?? '--'}</div>;
      },
    },
  },
  {
    name: ERQUARD_ORDER_KEY.STATUS,
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return <ChipCommon typeOfChip={rowData.status} text={rowData.status} />;
      },
    },
  },
];
