import { Box, Card, Container } from '@mui/material';
import BenefitsTableList from './TableList';

const NotificationContainer = () => {
  return (
    <Box py={2} sx={{ height: '100vh' }}>
      <Container maxWidth="xl">
        <Card>
          <BenefitsTableList />
        </Card>
      </Container>
    </Box>
  );
};

export default NotificationContainer;
