import { TableParams } from 'src/redux/types';

export enum ERQUARD_COMPANY_KEY {
  COMPANY_NAME = 'company',
  EMAIL = 'email',
  STATUS = 'status',
  CREATED_DATE = 'createdDate',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
