import { Box } from '@mui/material';
import React from 'react';

const TagIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17790_49683)">
          <path
            d="M17.4999 7.17969V2.375C17.5007 2.14528 17.456 1.91768 17.3685 1.70527C17.2811 1.49285 17.1525 1.2998 16.9902 1.1372C16.828 0.974602 16.6352 0.845652 16.4229 0.757755C16.2107 0.669857 15.9832 0.624743 15.7535 0.625001H10.9515C10.6366 0.625649 10.3347 0.750429 10.1113 0.972267L0.511268 10.5695C0.183852 10.898 0 11.3429 0 11.8066C0 12.2704 0.183852 12.7153 0.511268 13.0438L5.08158 17.6141C5.40993 17.9415 5.85475 18.1255 6.31849 18.1255C6.78224 18.1255 7.22706 17.9415 7.55541 17.6141L17.1527 8.01992C17.3746 7.79652 17.4994 7.49457 17.4999 7.17969V7.17969ZM13.7499 5.625C13.5027 5.625 13.261 5.55169 13.0555 5.41434C12.8499 5.27699 12.6897 5.08176 12.5951 4.85336C12.5005 4.62495 12.4757 4.37361 12.524 4.13114C12.5722 3.88866 12.6912 3.66593 12.8661 3.49112C13.0409 3.3163 13.2636 3.19725 13.5061 3.14902C13.7486 3.10079 13.9999 3.12554 14.2283 3.22015C14.4567 3.31476 14.6519 3.47498 14.7893 3.68054C14.9266 3.8861 14.9999 4.12777 14.9999 4.375C14.9999 4.70652 14.8682 5.02446 14.6338 5.25888C14.3994 5.4933 14.0815 5.625 13.7499 5.625Z"
            fill="#6D7176"
          />
          <path
            d="M19.3746 2.5C19.2088 2.5 19.0499 2.56585 18.9327 2.68306C18.8154 2.80027 18.7496 2.95924 18.7496 3.125V8.10039L8.54217 18.3082C8.48169 18.3657 8.43333 18.4346 8.39993 18.5111C8.36653 18.5875 8.34878 18.6699 8.34771 18.7533C8.34664 18.8367 8.36228 18.9195 8.39371 18.9968C8.42514 19.0741 8.47173 19.1443 8.53071 19.2033C8.5897 19.2622 8.6599 19.3088 8.73718 19.3403C8.81446 19.3717 8.89724 19.3873 8.98066 19.3863C9.06407 19.3852 9.14643 19.3674 9.22288 19.334C9.29932 19.3006 9.36831 19.2523 9.42577 19.1918L19.6601 8.95742C19.769 8.84805 19.8551 8.71815 19.9133 8.57525C19.9716 8.43236 20.0009 8.27931 19.9996 8.125V3.125C19.9996 2.95924 19.9337 2.80027 19.8165 2.68306C19.6993 2.56585 19.5404 2.5 19.3746 2.5V2.5Z"
            fill="#6D7176"
          />
        </g>
        <defs>
          <clipPath id="clip0_17790_49683">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default TagIcon;
