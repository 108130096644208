//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const DeleteIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color = '#6D7176',
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.5 4.6H13.25V3.3C13.25 2.95522 13.113 2.62456 12.8692 2.38076C12.6254 2.13696 12.2948 2 11.95 2H8.05C7.70522 2 7.37456 2.13696 7.13076 2.38076C6.88696 2.62456 6.75 2.95522 6.75 3.3V4.6H3.5V5.9H4.8V15.65C4.8 16.1672 5.00545 16.6632 5.37114 17.0289C5.73684 17.3946 6.23283 17.6 6.75 17.6H13.25C13.7672 17.6 14.2632 17.3946 14.6289 17.0289C14.9946 16.6632 15.2 16.1672 15.2 15.65V5.9H16.5V4.6ZM8.05 3.3H11.95V4.6H8.05V3.3ZM13.9 15.65C13.9 15.8224 13.8315 15.9877 13.7096 16.1096C13.5877 16.2315 13.4224 16.3 13.25 16.3H6.75C6.57761 16.3 6.41228 16.2315 6.29038 16.1096C6.16848 15.9877 6.1 15.8224 6.1 15.65V5.9H13.9V15.65Z"
          fill={color}
        />
        <path d="M9.3498 8.5H8.0498V13.7H9.3498V8.5Z" fill={color} />
        <path d="M11.9504 8.5H10.6504V13.7H11.9504V8.5Z" fill={color} />
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default DeleteIcon;
