import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useContents(
  options?: UseQueryOptions<ApiResponseType<any>, Error, any>
) {
  const {
    data: contents,
    error,
    isError,
    isFetching,
    refetch: getContents,
  } = useQuery<ApiResponseType<any>, Error, any>([API_QUERIES.CONTENT], {
    queryFn: () => responseWrapper<ApiResponseType<any>>(apiClient.getContents),
    enabled: false,
    select: (data) => data.data,
    ...options,
  });

  return {
    contents,
    error,
    isError,
    loading: isFetching,
    getContents,
  };
}
