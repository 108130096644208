import React from 'react';

const FlagNoBranch = () => {
  return (
    <React.Fragment>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.625 18.75C3.45924 18.75 3.30027 18.6842 3.18306 18.5669C3.06585 18.4497 3 18.2908 3 18.125V2.66133C3.00004 2.49757 3.04298 2.33668 3.12454 2.19468C3.20609 2.05268 3.32342 1.93451 3.46484 1.85195C3.9375 1.57734 4.88984 1.25 6.75 1.25C8.20352 1.25 9.8293 1.82461 11.2637 2.33125C12.4187 2.73945 13.5098 3.125 14.25 3.125C15.2029 3.12208 16.1457 2.92953 17.0234 2.55859C17.1303 2.51348 17.2467 2.49551 17.3622 2.50629C17.4777 2.51708 17.5888 2.55628 17.6855 2.6204C17.7822 2.68452 17.8615 2.77158 17.9164 2.8738C17.9713 2.97602 18 3.09023 18 3.20625V11.775C17.9998 11.9269 17.9554 12.0754 17.8722 12.2024C17.7889 12.3295 17.6704 12.4295 17.5312 12.4902C17.191 12.6391 15.9488 13.125 14.25 13.125C13.307 13.125 12.1258 12.8461 10.8754 12.5504C9.46992 12.2184 8.0168 11.875 6.75 11.875C5.30977 11.875 4.57266 12.093 4.25 12.2309V18.125C4.25 18.2908 4.18415 18.4497 4.06694 18.5669C3.94973 18.6842 3.79076 18.75 3.625 18.75Z"
          fill="#FF8A5A"
        />
      </svg>
    </React.Fragment>
  );
};

export default FlagNoBranch;
