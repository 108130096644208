import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { convertText, formatPhoneNumber, getPlanTypeText } from 'src/utils';
import { LEAD_SEARCH_KEY, getLeadStatusText } from './helpers';
import dayjs from 'dayjs';
import { PATHS } from 'src/appConfig/paths';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: LEAD_SEARCH_KEY.NAME,
    label: 'Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <Link
            to={{
              pathname: `${PATHS.sale}${PATHS.leadInbox}/${rowData.id}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? '--'}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.ORGANIZATION,
    label: 'Organization',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ?? '--'}</div>;
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.PLAN_TYPE,
    label: 'Plan Type',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{getPlanTypeText(value)}</div>;
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.PHONE_NUMBER,
    label: 'Phone Number',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{formatPhoneNumber(value) ?? '--'}</div>;
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.EMAIL,
    label: 'Email',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value}</div>;
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.SOURCE,
    label: 'Source',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{convertText(value)}</div>;
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.CREATION_DATE,
    label: 'Creation Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.LAST_MODIFIED_DATE,
    label: 'Last Modified Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: LEAD_SEARCH_KEY.LEAD_STATUS,
    label: 'Lead Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{getLeadStatusText(value)}</div>;
      },
    },
  },
];
