//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const BirthdayIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ backgroundColor: '#E0F1FF', width: '50px', height: '50px', borderRadius: '16px', padding: '17px' }}>
      <svg width="15" height="16" viewBox="0 0 15 16" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M14.333 12C13.4574 12 13.3561 11 12.0049 11C10.6477 11 10.5416 12 9.66895 12C8.80348 12 8.68601 11 7.33301 11C5.9942 11 5.85745 12 5.00488 12C4.12526 12 4.02982 11 2.66895 11C1.3081 11 1.21104 12 0.333008 12V9.5C0.333008 8.67188 1.00488 8 1.83301 8H2.33301V3.5H4.33301V8H6.33301V3.5H8.33301V8H10.333V3.5H12.333V8H12.833C13.6611 8 14.333 8.67188 14.333 9.5V12ZM14.333 16H0.333008V13C1.68788 13 1.79448 12 2.66895 12C3.54241 12 3.6456 13 5.00488 13C6.34373 13 6.48041 12 7.33301 12C8.21263 12 8.30804 13 9.66895 13C11.0239 13 11.1304 12 12.0049 12C12.8639 12 12.9815 13 14.333 13V16ZM3.33301 3C2.77832 3 2.33301 2.55469 2.33301 2C2.33301 1.03125 3.33301 1.28125 3.33301 0C3.70801 0 4.33301 0.921875 4.33301 1.75C4.33301 2.57812 3.8877 3 3.33301 3ZM7.33301 3C6.77832 3 6.33301 2.55469 6.33301 2C6.33301 1.03125 7.33301 1.28125 7.33301 0C7.70801 0 8.33301 0.921875 8.33301 1.75C8.33301 2.57812 7.8877 3 7.33301 3ZM11.333 3C10.7783 3 10.333 2.55469 10.333 2C10.333 1.03125 11.333 1.28125 11.333 0C11.708 0 12.333 0.921875 12.333 1.75C12.333 2.57812 11.8877 3 11.333 3Z"
          fill={color} />
      </svg>
    </Box>

  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default BirthdayIcon;
