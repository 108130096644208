import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { ActivationFormPayload } from './type';

export function useCreateActivationForm(
  options?: UseMutationOptions<any, Error, ActivationFormPayload>
) {
  const { mutate: createActivationForm, isLoading: isLoadingCreateActivationForm } = useMutation<
    any,
    Error,
    ActivationFormPayload
  >({
    mutationFn: (payload: ActivationFormPayload) =>
      authResponseWrapper(apiClient.createActivationForm, [payload]),
    ...options,
  });

  return {
    createActivationForm,
    isLoadingCreateActivationForm,
  };
}
