import { SearchRounded } from '@mui/icons-material';
import { Grid, Switch, Typography } from '@mui/material';
import CustomSearchTable from 'src/components/CustomSearchTable';

import { connect } from 'react-redux';
import { UPDATE_NOTIFICATION_KEY } from './helpers';

const Header: React.FC<Props> = ({ updateNotification, isUnread, isShowMark }) => {
  const handleSwitchReadStatus = () => {
    updateNotification({
      type: !isUnread
        ? UPDATE_NOTIFICATION_KEY.SEE_UNREAD_NOTIFICATIONS
        : UPDATE_NOTIFICATION_KEY.SEE_ALL_NOTIFICATIONS,
    });
  };

  const handlemarkAllNotifications = () => {
    updateNotification({
      type: UPDATE_NOTIFICATION_KEY.MARK_ALL_NOTIFICATIONS,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={6} xl={6}>
        <CustomSearchTable
          placeholder="Search"
          label=""
          icon={<SearchRounded />}
          style={{ width: '400px' }}
          iconPosition="left"
        />
      </Grid>
      <Grid item xl={6} lg={6} xs={12} sm={12} display={'flex'} justifyContent={'end'} gap={2}>
        <div className="toggle-read">
          <Typography fontSize={14} fontWeight={400}>
            See unread only
          </Typography>
          <Switch color="secondary" defaultChecked={isUnread} onChange={handleSwitchReadStatus} />
        </div>
        {isShowMark && (
          <div className="mark" onClick={handlemarkAllNotifications} aria-hidden={true}>
            Mark all as read
          </div>
        )}
      </Grid>
    </Grid>
  );
};
type Props = {
  updateNotification?: any;
  isUnread: boolean;
  isShowMark: boolean;
};

export default connect(undefined, null)(Header);
