import { TableParams } from 'src/redux/types';

export enum ACCOUNT_SEARCH_KEY {
  ACCOUNT_NUMBER = 'accountNumber',
  PLAN_TYPE = 'planType',
  ACCOUNT_NAME = 'accountName',
  ACCOUNT_STATUS = 'accountStatus',
  CURRENT_MEMBERS = 'currentMember',
  PROVINCE = 'province',
  CITY = 'city',
  SUSPENSION_DATE = 'suspensionDate',
  CURRENT_POLICY_DATE = 'policyDate',
  EXPIRY_DATE = 'expiryDate',
  DAY_TO_EXPIRY = 'dayToExpiry',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
