import { TableParams } from 'src/redux/types';

export enum LEAD_SEARCH_KEY {
  NAME = 'name',
  ORGANIZATION = 'organization',
  PLAN_TYPE = 'planType',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  SOURCE = 'source',
  SALE_AGENT = 'saleAgent',
  ASSIGNED_TO = 'assignedTo',
  CREATION_DATE = 'creationDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  LEAD_STATUS = 'leadStatus',
}

export const getLeadStatusText = (type: string) => {
  switch (type) {
    case 'NEW_LEAD':
      return 'New Lead';
    case 'CALLED':
      return 'Called';
    case 'PRESENTED':
      return 'Presented';
    case 'PROPOSAL_SENT':
      return 'Proposal sent';
    case 'FIRST_FOLLOW_UP':
      return 'First follow up';
    case 'SECOND_FOLLOW_UP':
      return 'Second follow up';
    case 'THIRD_FOLLOW_UP':
      return 'Thrid follow up';
    case 'SIGNED_PROPOSAL':
      return 'Signed proposal';
    default:
      return type;
  }
};
