import { TableParams } from 'src/redux/types';

export enum DEAL_SEARCH_KEY {
  NAME = 'name',
  ORGANIZATION = 'organization',
  TOTAL_PREMIUM = 'totalPremium',
  PLAN_TYPE = 'planType',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  SALE_AGENT = 'saleAgent',
  ASSIGNED_TO = 'assignedTo',
  CREATION_DATE = 'creationDate',
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  DEAL_STATUS = 'dealStatus',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
