import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { isEmpty } from 'lodash';
import { ActivationFormPayload } from 'src/queries/ERGuard/type';
import { Yup } from 'src/services';
import { _calculateAge, daysFromToday } from 'src/utils';

export interface Example {}

export enum ER_GUARD_ACTIVATION_FORM_KEY {
  REFERENCE_NUMBER = 'referenceNumber',
  SECURITY_CODE = 'securityCode',
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  BIRTHDAY = 'birthday',
  EMAIL = 'email',
  CONTACT_NUMBER = 'contactNumber',

  BLK = 'bLK',
  STREET = 'street',
  BARANGAY = 'barangay',
  MUNICIPALITY = 'municipality',
  PROVINCE = 'province',
  REGION = 'region',
  ZIP_CODE = 'zipCode',

  CIVIL_STATUS = 'civilStatus',
  NATIONALITY = 'nationality',
  EMPLOYMENT_TYPE = 'employmentType',
  COMPANY_NAME = 'companyName',
  IS_THIS_A_GIFT = 'isThisAGift',
  NAME_OF_GIVER = 'nameOfGiver',
}

export type ERGuardActivationFormValue = {
  id?: string;
  referenceNumber: string;
  securityCode?: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  birthday: string;
  email: string;
  contactNumber: string;

  bLK: string;
  street: string;
  municipality: any;
  barangay: string;
  province: any;
  region: any;
  zipCode: string;

  civilStatus: string;
  nationality: string;
  employmentType: string;
  companyName: string;
  isThisAGift?: string;
  nameOfGiver?: string;
};

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
];
export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    label: 'Employed',
    value: 'EMPLOYED',
  },
  {
    label: 'Self employed',
    value: 'SELF_EMPLOYED',
  },
  {
    label: 'Student',
    value: 'STUDENT',
  },
  {
    label: 'Retired',
    value: 'RETIRED',
  },
  {
    label: 'Others',
    value: 'OTHERS',
  },
];

export const CIVIL_STATUS_OPTIONS = [
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Married',
    value: 'MARRIED',
  },
  {
    label: 'Annulled',
    value: 'ANNULLED',
  },
];
export const initialERGUARDActivationFormValue: ERGuardActivationFormValue = {
  referenceNumber: '',
  securityCode: '',

  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  birthday: '',
  email: '',
  contactNumber: '',

  bLK: '',
  street: '',
  municipality: '',
  barangay: '',
  province: '',
  region: '',
  zipCode: '',

  civilStatus: '',
  nationality: '',
  employmentType: '',
  companyName: '',
  isThisAGift: undefined,
  nameOfGiver: '',
};

export const getErrorMessage = (fieldName: string, { touched, errors }) => {
  // eslint-disable-next-line security/detect-object-injection
  return touched[fieldName] && errors[fieldName] ? errors[fieldName] : '';
};

export type ERGuardActivationFormikProps = {
  values: ERGuardActivationFormValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<ERGuardActivationFormValue>>;
  errors: FormikErrors<ERGuardActivationFormValue>;
  touched: FormikTouched<ERGuardActivationFormValue>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<ERGuardActivationFormValue>>;
  setErrors: (errors?: FormikErrors<ERGuardActivationFormValue>) => void;
};

export const ERGuardActivationFormSchema = Yup.object().shape({
  // assignAgentId: Yup.string().required('This field is required').nullable(),
  referenceNumber: Yup.string().required('This field is required').nullable(),
  securityCode: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required').nullable(),
  lastName: Yup.string().required('This field is required').nullable(),
  birthday: Yup.string()
    .required('This field is required')
    .nullable()
    .test(
      'birthday',
      `This member is either 'younger than 15 days' or '60 years old or older'`,
      (value) => {
        return daysFromToday(new Date(value)) >= 15 && _calculateAge(new Date(value)) <= 60;
      }
    ),
  email: Yup.string().required('This field is required').nullable(),
  gender: Yup.string().required('This field is required').nullable(),
  contactNumber: Yup.string().phone().required('This field is required').nullable(),
  municipality: Yup.object().required('This field is required').nullable(),
  province: Yup.object().when(['municipality', 'region'], {
    is: (city, region) => isEmpty(city) || isEmpty(region),
    then: Yup.object().required('This field is required').nullable(),
    otherwise: Yup.object().nullable(),
  }),
  region: Yup.object().required('This field is required').nullable(),
});

export const formatPayloadSubmit = (values: ERGuardActivationFormValue) => {
  const _values: ActivationFormPayload = {
    onlineMemberInformation: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      gender: values.gender,
      birthday: new Date(values?.birthday).toISOString(),
      civilStatus: values.civilStatus,
      companyName: values.companyName,
      email: values.email,
      employmentType: values.employmentType,
      giverName: values.nameOfGiver,
      isGift: values.isThisAGift,
      nationality: values.nationality,
      phoneNumber: values.contactNumber,
    },
    address: {
      barangay: values.barangay,
      blk: values.bLK,
      building: '',
      cityId: values?.municipality?.id,
      floor: '',
      provinceId: values?.province?.id,
      regionId: values.region?.id,
      street: values.street,
      zipCode: values.zipCode,
    },
    referenceNumber: values.referenceNumber,
    securityCode: values.securityCode,
  };
  return _values;
};

export const objectToAddressOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x,
        key: x?.id,
        label: x.name,
      }))) ||
    []
  );
};
export const objectToProvinceOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: isEmpty(x) ? { name: 'Invalid', id: 'Invalid' } : x,
        key: x?.id,
        label: x?.isDuplicateName === null ? x?.name : `${x?.name} (${x?.region?.name})`,
      }))) ||
    []
  );
};
