import cn from 'classnames';
import React, { useEffect } from 'react';
import './styles.scss';
import { Chip, ChipProps } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const getTypeOfHeader = (type: string) => {
  switch (type) {
    case 'OPEN':
      return {
        data: {
          brColor: '#DCFCE7',
          text: 'Open',
          iconColor: '#22C55E',
          isIcon: true,
          colorText: '#14532D',
        },
      };
    case 'UTILIZED':
      return {
        data: {
          brColor: '#FFEDD5',
          text: 'Utilized',
          iconColor: '#EA580C',
          isIcon: true,
          colorText: '#EA580C',
        },
      };
    case 'VOID_REQUESTED':
      return {
        data: {
          brColor: '#F3E8FFEE',
          text: 'Void Requested',
          iconColor: '#581C87',
          isIcon: true,
          colorText: '#581C87',
        },
      };
    case 'ACTIVATING':
      return {
        data: {
          brColor: '#DAEDFD',
          text: 'Activating',
          iconColor: '#4684E7',
          isIcon: true,
          colorText: '#163485',
        },
      };
    case 'VOIDED':
      return {
        data: {
          brColor: '#DEE1E5',
          text: 'Voided',
          iconColor: '#484C4F',
          isIcon: true,
          colorText: '#484C4F',
        },
      };
    case 'PAYMENT_PENDING':
      return {
        data: {
          brColor: '#FEF3C7',
          text: 'Payment Pending',
          iconColor: '#D97706',
          isIcon: true,
          colorText: '#D97706',
        },
      };
    case 'EXPIRED':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Expired',
          iconColor: '#EF4444',
          isIcon: true,
          colorText: '#7F1D1D',
        },
      };
    case 'ACTIVE':
      return {
        data: {
          brColor: '#DCFCE7',
          text: 'Active',
          iconColor: '#22C55E',
          isIcon: true,
          colorText: '#14532D',
        },
      };
    case 'VALID':
      return {
        data: {
          brColor: '#DCFCE7',
          text: 'Valid',
          iconColor: '#22C55E',
          isIcon: true,
          colorText: '#14532D',
        },
      };
    case 'PENDING':
      return {
        data: {
          brColor: '#FEF9C3',
          text: 'Pending',
          iconColor: '#EAB308',
          isIcon: true,
          colorText: '#713F12',
        },
      };
    case 'DELINQUENT':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Delinquent',
          iconColor: '#EF4444',
          isIcon: true,
          colorText: '#7F1D1D',
        },
      };
    case 'CANCELED':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Hot',
          iconColor: '#EF4444',
          isIcon: true,
          colorText: '#7F1D1D',
        },
      };
    case 'COLD':
      return {
        data: {
          brColor: '#DAEDFD',
          text: 'Cold',
          iconColor: '#4684E7',
          isIcon: true,
          colorText: '#163485',
        },
      };
    case 'WARM':
      return {
        data: {
          brColor: '#FEF9C3',
          text: 'Warm',
          iconColor: '#EAB308',
          isIcon: true,
          colorText: '#713F12',
        },
      };
    case 'HOT':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Hot',
          iconColor: '#EF4444',
          isIcon: true,
          colorText: '#7F1D1D',
        },
      };
    case 'ONGOING':
      return {
        data: {
          brColor: '#DAEDFD',
          text: 'Ongoing',
          isIcon: false,
          colorText: '#163485',
        },
      };
    case 'AWAITING_PAYMENT':
      return {
        data: {
          brColor: '#FEF9C3',
          text: 'Awaiting payment',
          isIcon: false,
          colorText: '#713F12',
        },
      };
    case 'COMPLETED':
      return {
        data: {
          brColor: '#DCFCE7',
          text: 'Completed',
          isIcon: false,
          colorText: '#14532D',
        },
      };
    case 'AWAITING_SAF':
      return {
        data: {
          brColor: '#EDEFF1',
          text: 'Awaiting SAF',
          isIcon: false,
          colorText: '#484C4F',
        },
      };
    case 'NOT_STARTED':
      return {
        data: {
          brColor: '#DEE1E5',
          text: 'Not Started',
          isIcon: true,
          iconColor: '#484C4F',
          colorText: '#484C4F',
        },
      };
    case 'IN_PROGRESS':
      return {
        data: {
          brColor: '#DAEDFD',
          text: 'In Progress',
          isIcon: true,
          iconColor: '#4684E7',
          colorText: '#163485',
        },
      };
    case 'PENDING_APPROVAL':
      return {
        data: {
          brColor: '#FEF9C3',
          text: 'Pending Approval',
          isIcon: true,
          iconColor: '#EAB308',
          colorText: '#713F12',
        },
      };
    case 'REJECTED':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Rejected',
          isIcon: true,
          iconColor: '#EF4444',
          colorText: '#7F1D1D',
        },
      };
    case 'COMPLETE':
      return {
        data: {
          brColor: '#DCFCE7',
          text: 'Complete',
          isIcon: true,
          iconColor: '#22C55E',
          colorText: '#14532D',
        },
      };

    case 'NOT_COMPLETED':
      return {
        data: {
          brColor: '#DEE1E5',
          text: 'Not Completed',
          isIcon: true,
          iconColor: '#484C4F',
          colorText: '#484C4F',
        },
      };

    case 'APPROVED':
      return {
        data: {
          brColor: '#DCFCE7',
          isIcon: true,
          iconColor: '#22C55E',
          colorText: '#14532D',
        },
      };
    case 'REJECT':
      return {
        data: {
          brColor: '#FEE2E2',
          isIcon: true,
          iconColor: '#EF4444',
          colorText: '#7F1D1D',
        },
      };
    case 'REFUNDED':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Refunded',
          isIcon: true,
          iconColor: '#EF4444',
          colorText: '#7F1D1D',
        },
      };
    case 'CANCELLED':
      return {
        data: {
          brColor: '#DEE1E5',
          text: 'Cancelled',
          isIcon: true,
          iconColor: '#484C4F',
          colorText: '#484C4F',
        },
      };
    // case 'UTILIZED':
    //   return {
    //     data: {
    //       brColor: '#FEE2E2',
    //       text: 'Utilized',
    //       isIcon: true,
    //       iconColor: '#EF4444',
    //       colorText: '#7F1D1D',
    //     },
    //   };
    // case 'ACTIVATING':
    //   return {
    //     data: {
    //       brColor: '#FEF9C3',
    //       text: 'Activating',
    //       isIcon: true,
    //       iconColor: '#EAB308',
    //       colorText: '#713F12',
    //     },
    //   };
    // case 'EXPIRED':
    //   return {
    //     data: {
    //       brColor: '#FEF9C3',
    //       text: 'Expired',
    //       isIcon: true,
    //       iconColor: '#EAB308',
    //       colorText: '#713F12',
    //     },
    //   };
    case 'SUSPENDED':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Suspended',
          isIcon: true,
          iconColor: '#EF4444',
          colorText: '#7F1D1D',
        },
      };
    case 'TERMINATED':
      return {
        data: {
          brColor: '#DEE1E5',
          text: 'Terminated',
          isIcon: true,
          iconColor: '#484C4F',
          colorText: '#484C4F',
        },
      };
    case 'INACTIVE':
      return {
        data: {
          brColor: '#FEE2E2',
          text: 'Inactive',
          isIcon: true,
          iconColor: '#EF4444',
          colorText: '#7F1D1D',
        },
      };
    case 'NEED_INFORMATION':
      return {
        data: {
          brColor: '#FEF9C3',
          isIcon: true,
          iconColor: '#EAB308',
          colorText: '#713F12',
        },
      };
    default:
      return {
        data: {
          brColor: '#EDEFF1',
          iconColor: '#ffffff',
          isIcon: false,
          colorText: '#484C4F',
        },
      };
  }
};

const ChipCommon: React.FC<Props> = ({ typeOfChip = 'default', text }) => {
  const { data } = getTypeOfHeader(typeOfChip);

  useEffect(() => {}, [data]);
  return (
    <Chip
      sx={{
        fontFamily: 'Poppins',
        backgroundColor: data.brColor,
        height: '26px',
        fontSize: '12px',
        fontWeight: 600,
        color: data.colorText,
      }}
      label={data.text ? data.text : text}
      icon={
        (data.isIcon && (
          <FiberManualRecordIcon
            fontSize="inherit"
            sx={{
              '&.MuiChip-icon': {
                color: data.iconColor,
              },
            }}
            style={{ color: data.iconColor }}
          />
        )) ||
        null
      }
    />
  );
};

export type Props = {
  typeOfChip?: string;
  text?: string | React.ReactNode;
} & ChipProps;

export default ChipCommon;
