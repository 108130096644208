import { TableParams } from 'src/redux/types';

export enum ERQUARD_BRANCH_KEY {
  BRANCH_NAME = 'branch',
  COMPANY_NAME = 'commpany',
  EMAIL = 'email',
  STATUS = 'status',
  CREATED_DATE = 'createdDate',
  REGION = 'region',
  PROVINCE = 'province',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
