import { Box } from '@mui/material';
import React from 'react';
const StarIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  width = '16',
  height = '15',
  ...props
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 15"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9792 14.0005C11.8739 14.0009 11.7712 13.9681 11.6857 13.9067L7.66666 10.993L3.64759 13.9067C3.56173 13.969 3.45828 14.0024 3.35221 14.002C3.24614 14.0016 3.14294 13.9675 3.05753 13.9046C2.97212 13.8417 2.90893 13.7532 2.87709 13.6521C2.84525 13.5509 2.84641 13.4422 2.8804 13.3417L4.44791 8.69891L0.385405 5.91298C0.297408 5.8527 0.230994 5.76586 0.195865 5.66515C0.160736 5.56444 0.158733 5.45513 0.19015 5.3532C0.221567 5.25127 0.284757 5.16206 0.370486 5.0986C0.456216 5.03514 0.559993 5.00076 0.666655 5.00048H5.67853L7.19103 0.34579C7.22361 0.245296 7.28719 0.157706 7.37264 0.0955837C7.45808 0.0334614 7.56101 0 7.66666 0C7.7723 0 7.87523 0.0334614 7.96067 0.0955837C8.04612 0.157706 8.1097 0.245296 8.14228 0.34579L9.65478 5.00204H14.6667C14.7734 5.00199 14.8775 5.03613 14.9634 5.09947C15.0494 5.16281 15.1129 5.25201 15.1445 5.35402C15.1761 5.45603 15.1742 5.56548 15.1391 5.66634C15.104 5.76721 15.0376 5.85419 14.9495 5.91454L10.8854 8.69891L12.452 13.3405C12.4773 13.4156 12.4845 13.4957 12.4728 13.5742C12.4611 13.6526 12.4309 13.7272 12.3847 13.7917C12.3385 13.8561 12.2777 13.9087 12.2071 13.945C12.1366 13.9813 12.0585 14.0003 11.9792 14.0005Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
  width?: string;
  height?: string;
};
export default StarIcon;
