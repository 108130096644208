/* eslint-disable security/detect-object-injection */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface ISnackbarState {
  snackbarStatus: string;
  isOpenSnackbar: boolean;
}

const initialState: ISnackbarState = {
  snackbarStatus: '',
  isOpenSnackbar: false,
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<{ status: string; timeout?: number }>) => {
      state.isOpenSnackbar = true;
      const { status, timeout } = action.payload;
      state.snackbarStatus = status;
      if (timeout) {
        setTimeout(() => {
          state.isOpenSnackbar = false;
          state.snackbarStatus = '';
        }, timeout);
      }
    },
    closeSnackbar: (state) => {
      state.isOpenSnackbar = false;
      state.snackbarStatus = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSnackbar, closeSnackbar } = snackbarSlice.actions;

export const snackbarState = snackbarSlice.getInitialState();

export default snackbarSlice.reducer;
