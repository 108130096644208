import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Typography } from '@mui/material';
import { ERQUARD_BRANCH_KEY } from './helpers';
import ChipCommon from 'src/components/common/Chip';
import { PATHS } from 'src/appConfig/paths';
import { stringify } from 'src/utils';
import dayjs from 'dayjs';
import { Chip, Link } from 'src/components/common';
import { Navigator } from 'src/services';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: ERQUARD_BRANCH_KEY.BRANCH_NAME,
    label: 'Branch Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        const queryString = stringify({ uuid: rowData.id });
        const handleClick = (e) => {
          e.preventDefault();
          Navigator.navigate(`${PATHS.erGuard}${PATHS.branchManagement}?${queryString}`);
        };
        return (
          <Link
            href={`${PATHS.erGuard}${PATHS.branchManagement}?${queryString}`}
            onClick={handleClick}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? '--'}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: ERQUARD_BRANCH_KEY.EMAIL,
    label: 'Email',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value}</div>;
      },
    },
  },
  {
    name: ERQUARD_BRANCH_KEY.STATUS,
    label: 'Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <Chip typeOfChip={value} />;
      },
    },
  },
  {
    name: ERQUARD_BRANCH_KEY.CREATED_DATE,
    label: 'Created Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
];
