import { FilterContainer } from './FilterContainer';
import { CheckBoxFilter } from './CheckBox';
import { SwitchFilter } from './Switch';
import { SelectFilter } from './SelectFilter';
export default {
  Container: FilterContainer,
  CheckBox: CheckBoxFilter,
  Switch: SwitchFilter,
  Select: SelectFilter,
};
