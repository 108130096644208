import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';

import { useState } from 'react';
import { isEmpty } from 'src/validations';
import { API_QUERIES } from '../keys';
import { ModuleSearchData } from './types';

export function useGetSearchDocument(
  options: UseQueryOptions<ApiResponseType<any>, Error, ModuleSearchData>
) {
  const [params, setParams] = useState<any>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetModuleSearch,
  } = useQuery<ApiResponseType<any>, Error, ModuleSearchData>([API_QUERIES.MODULE_SEARCH, params], {
    queryFn: (query) => {
      const [, ...params] = query.queryKey;

      return responseWrapper<ApiResponseType<any>>(apiClient.getModuleSearch, params);
    },
    select(data) {
      return data.data;
    },
    enabled: !isEmpty(params),
    onSuccess(data) {},
    ...options,
    staleTime: 2000,
  });

  return {
    searchResult: data?.data ?? [],
    totalItems: data?.totalRecords,
    error,
    isError,
    isFetching,
    setParams,
    onGetModuleSearch,
  };
}
