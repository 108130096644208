import { Typography, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { muiResponsive } from 'src/appConfig/constants';
import EmptyTable from 'src/components/EmptyTable';
import { Accordion, Table } from 'src/components/common';
import Footer from 'src/components/common/Footer';
import NoPermission from 'src/containers/StartupContainers/NoPermission';
import { ModuleSearchData } from 'src/queries/SystemSearch/types';
import { useGetSearchDocument } from 'src/queries/SystemSearch/useGetSearchDocument';
import { PermissionsService } from 'src/services';
import NoPermissionSystemSearch from '../NoPermissionSystemSearch';
import {
  FILTER_GLOBAL_SEARCH_INDEX,
  MODULE_SEARCH_KEY,
  QUERY_KEY,
  SYSTEM_SEARCH_LOCALSTORAGE,
} from '../helpers';
import { allColumns } from './allColumns';

const ERGuardBranch: React.FC<Props> = ({ isViewAll }) => {
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);

  const isTabletScreen = useMediaQuery(muiResponsive.TABLET);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchText = query.get(QUERY_KEY.SEARCH) || '';

  const isView = PermissionsService.branchManagement().canView;

  // const isCreate = PermissionsService.branchManagement().canCreate;
  // const isUpdate = PermissionsService.branchManagement().canUpdate;

  const permission = isView;
  const { searchResult, totalItems, setParams, isFetching } = useGetSearchDocument({});

  const convertSearchList = searchResult.map((item) => {
    return {
      id: item._id,
      branch: item._source.name,
      company: '--',
      email: item._source.email,
      status: item._source.status,
      createdDate: item._source.createdAt,
      region: '--',
      province: '--',
    };
  });

  const filter = query.getAll(QUERY_KEY.filter);
  const lastModifiedDate = filter[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE];
  const creationDate = filter[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE];
  const status = filter[FILTER_GLOBAL_SEARCH_INDEX.STATUS];

  const sendDataToParent = (curPage, curRowsPerPage) => {
    setPage(curPage);
    setRowPerPage(curRowsPerPage);
    setParams({
      search: searchText,
      take: curRowsPerPage,
      skip: (curPage - 1) * curRowsPerPage,
      moduleName: MODULE_SEARCH_KEY.ER_GUARD_BRANCH,
      createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
      lastUpdatedDate: lastModifiedDate ? dayjs(lastModifiedDate).format('YYYY-MM-DD') : undefined,
      status: status,
    });
    const systemSearch = localStorage.getItem(SYSTEM_SEARCH_LOCALSTORAGE);
    const parseSystemSearch = systemSearch ? JSON.parse(systemSearch) : {};
    const result = { ...parseSystemSearch };
    result[MODULE_SEARCH_KEY.ER_GUARD_BRANCH] = {
      page: curPage,
      rowPerPage: curRowsPerPage,
    };
    localStorage.setItem(SYSTEM_SEARCH_LOCALSTORAGE, JSON.stringify(result));
  };

  const tableOptions: MUIDataTableOptions = React.useMemo(
    () => ({
      count: totalItems,
      onRowClick: () => {},
      rowHover: true,
      filter: false,
      fixedSelectColumn: false,
      searchAlwaysOpen: false,
      searchOpen: false,
      search: false,
      selectableRowsHeader: true,
      tableBodyMaxHeight: isTabletScreen ? '100%' : 'calc(100vh - 366px)', // content height
      pagination: false,
      customFooter: null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTabletScreen, convertSearchList]
  );

  const columns = React.useMemo(() => allColumns(), []);

  useEffect(() => {
    const systemSearchLocalStorage = localStorage.getItem(SYSTEM_SEARCH_LOCALSTORAGE);
    const parseSystemSearch = JSON.parse(systemSearchLocalStorage);
    if (systemSearchLocalStorage && parseSystemSearch[MODULE_SEARCH_KEY.ER_GUARD_BRANCH]) {
      const newPagination = {
        page: parseSystemSearch[MODULE_SEARCH_KEY.ER_GUARD_BRANCH].page,
        rowPerPage: parseSystemSearch[MODULE_SEARCH_KEY.ER_GUARD_BRANCH].rowPerPage,
      };
      setPage(newPagination.page);
      setRowPerPage(newPagination.rowPerPage);
      setParams({
        search: searchText,
        take: newPagination.rowPerPage,
        skip: (newPagination.page - 1) * newPagination.rowPerPage,
        moduleName: MODULE_SEARCH_KEY.ER_GUARD_BRANCH,
        createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
        lastUpdatedDate: lastModifiedDate
          ? dayjs(lastModifiedDate).format('YYYY-MM-DD')
          : undefined,
        status: status,
      });
    } else {
      setParams({
        search: searchText,
        take: rowPerPage,
        skip: (page - 1) * rowPerPage,
        moduleName: MODULE_SEARCH_KEY.ER_GUARD_BRANCH,
        createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
        lastUpdatedDate: lastModifiedDate
          ? dayjs(lastModifiedDate).format('YYYY-MM-DD')
          : undefined,
        status: status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, creationDate, lastModifiedDate, status]);
  if (!permission && isViewAll) {
    return (
      <NoPermissionSystemSearch
        isView={permission}
        totalItems={totalItems}
        index="ER Guard - Branch"
      />
    );
  }

  return (
    <React.Fragment>
      {permission ? (
        <Accordion
          title={
            <span style={{ fontSize: '18px', fontWeight: '600' }}>{`ER Guard - Branch (${
              totalItems ?? 0
            })`}</span>
          }
          isExpanded={!isViewAll}
          children={
            totalItems ? (
              <>
                <Table
                  noColorStyle={true}
                  title=""
                  onAction={() => {}}
                  isLoading={isFetching}
                  data={convertSearchList}
                  tableOptions={tableOptions}
                  columns={columns}
                  emptyComponent={<EmptyTable />}
                  customRowsPerPageOptions={appConfig.SECOND_ROWS_PER_PAGE_OPTIONS}
                  customRowsPerPage={appConfig.SECOND_ROWS_PER_PAGE}
                  forceUpdate={{}}
                />
                <Footer
                  totalItems={totalItems}
                  currPage={page}
                  currRowPerPage={rowPerPage}
                  sendDataToParent={sendDataToParent}
                />
              </>
            ) : (
              <Typography color={'#6D7176'} textAlign={'center'}>
                No result found
              </Typography>
            )
          }
        />
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  );
};

type Props = {
  items?: ModuleSearchData;
  isViewAll: boolean;
};

export default ERGuardBranch;
