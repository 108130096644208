import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams, NotificationItem } from './types';

export function useGetNotification(
  options?: UseQueryOptions<ApiResponseType<PaginationResponseType<NotificationItem>>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({ take: 10, skip: 0 });
  const {
    data: listNotifications,
    error,
    isFetching,
    refetch: onGetNotifications,
  } = useQuery<ApiResponseType<PaginationResponseType<NotificationItem>>, Error>(
    [API_QUERIES.NOTIFICATION, params],
    {
      queryFn: (query) => {
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PaginationResponseType<NotificationItem>>>(
          apiClient.getNotifications,
          params
        );
      },
      select: (data) => {
        return data;
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      staleTime: 1000,
      ...options,
    }
  );

  const { data } = listNotifications || {};

  const { data: notifications, totalItems } = data || {};
  const queryClient = useQueryClient();

  const handleRecallNotification = () => queryClient.invalidateQueries(API_QUERIES.NOTIFICATION);

  return {
    setParams,
    notifications,
    totalItems,
    error,
    isFetching,
    onGetNotifications,
    handleRecallNotification,
  };
}
