import appConfig from 'src/appConfig';

const LOCAL_STORAGE_LOGIN_KEY = 'x_api_key'; //pragma: allowlist secret

const clearLoginKey = () => {
  localStorage.removeItem(LOCAL_STORAGE_LOGIN_KEY);
};

const setLoginKey = () => {
  localStorage.setItem(LOCAL_STORAGE_LOGIN_KEY, appConfig.LOGIN_KEY);
};

const getLoginKey = () => {
  return localStorage.getItem(LOCAL_STORAGE_LOGIN_KEY);
};

export default {
  clearLoginKey,
  setLoginKey,
  getLoginKey,
};
