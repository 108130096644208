import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams, Team } from './types';

export function useGetAllTeams(
  options?: UseQueryOptions<ApiResponseType<PaginationResponseType<Team>>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data: allTeamsResponse,
    error,
    isError,
    isFetching,
    isLoading,
    refetch: onGetAllTeams,
  } = useQuery<ApiResponseType<PaginationResponseType<Team>>, Error>([API_QUERIES.TEAMS, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<PaginationResponseType<Team>>>(
        apiClient.getAllTeams,
        params
      );
    },
    select: (data) => {
      return data;
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllTeam = () => queryClient.invalidateQueries(API_QUERIES.TEAMS);

  const { data } = allTeamsResponse || {};
  const { data: teams, hasNext, payloadSize, totalItems } = data || {};

  return {
    teams,
    hasNext,
    payloadSize,
    totalItems,
    error,
    isError,
    isFetching,
    onGetAllTeams,
    setParams,
    handleInvalidateAllTeam,
    isLoading,
  };
}
