import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPresignedDownloadResponse } from './types';
export function useQueryUrl(
  options?: UseQueryOptions<ApiResponseType<GetPresignedDownloadResponse>, Error, string> & {
    filePath: string;
  }
) {
  const { data: url, isFetching: isLoading } = useQuery<
    ApiResponseType<GetPresignedDownloadResponse>,
    Error,
    string
  >([API_QUERIES.DOWNLOAD_URL, JSON.stringify({ filePath: options.filePath })], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<GetPresignedDownloadResponse>>(
        apiClient.getDecodeUserServiceUrl,
        params.map((i) => JSON.parse(i as string))
      );
    },
    select(data) {
      return data.data.url;
    },
    enabled: !!options.filePath,
    ...options,
  });

  return {
    url,
    isLoading,
  };
}
