import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import CustomFilter from 'src/components/CustomFilter';
import { isEmpty } from 'src/validations';

import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { DatePicker, Select } from 'src/components/common';
import {
  FILTER_GLOBAL_SEARCH_INDEX,
  FILTER_GLOBAL_SEARCH_KEY,
  PREFIX_FILTER_GLOBAL_SEARCH,
  QUERY_KEY,
  SearchFilterFormValue,
} from './helpers';
import { MergeArrayHook } from './hooks/MergeArrayHook';

const STATUS_LIST = [
  {
    key: 'FIRST_FOLLOW_UP',
    value: 'FIRST_FOLLOW_UP',
    label: '1st Follow Up',
  },
  {
    key: 'SECOND_FOLLOW_UP',
    value: 'SECOND_FOLLOW_UP',
    label: '2st Follow Up',
  },
  {
    key: 'THIRD_FOLLOW_UP',
    value: 'THIRD_FOLLOW_UP',
    label: '3st Follow Up',
  },
  {
    key: 'ACTIVATED',
    value: 'ACTIVATED',
    label: 'Activated',
  },
  {
    key: 'ACTIVE',
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    key: 'CALLED',
    value: 'CALLED',
    label: 'Called',
  },
  {
    key: 'COMPLETE',
    value: 'COMPLETE',
    label: 'Complete',
  },
  {
    key: 'EXPIRED',
    value: 'EXPIRED',
    label: 'Expired',
  },
  {
    key: 'INACTIVATE',
    value: 'INACTIVATE',
    label: 'Inactivate',
  },
  {
    key: 'IN_PROGRESS',
    value: 'IN_PROGRESS',
    label: 'In Progress',
  },
  {
    key: 'LOST',
    value: 'LOST',
    label: 'Lost',
  },
  {
    key: 'NEW_DEAL',
    value: 'NEW_DEAL',
    label: 'New Deal',
  },
  {
    key: 'NEW_LEAD',
    value: 'NEW_LEAD',
    label: 'New Lead',
  },
  {
    key: 'PENDING',
    value: 'PENDING',
    label: 'Pending',
  },
  {
    key: 'PENDING_ACTIVATION',
    value: 'PENDING_ACTIVATION',
    label: 'Pending Activation',
  },
  {
    key: 'PENDING_APPROVALS',
    value: 'PENDING_APPROVALS',
    label: 'Pending Approvals',
  },
  {
    key: 'PRESENTED',
    value: 'PRESENTED',
    label: 'Presented',
  },
  {
    key: 'PROPOSAL_SENT',
    value: 'PROPOSAL_SENT',
    label: 'Proposal Sent',
  },
  {
    key: 'REFUNDED',
    value: 'REFUNDED',
    label: 'Refunded',
  },
  {
    key: 'REJECTED',
    value: 'REJECTED',
    label: 'Rejected',
  },
  {
    key: 'SIGNED_PROPOSAL',
    value: 'SIGNED_PROPOSAL',
    label: 'Signed Proposal',
  },
  {
    key: 'SUSPENDED',
    value: 'SUSPENDED',
    label: 'Suspended',
  },
  {
    key: 'TERMINATED',
    value: 'TERMINATED',
    label: 'Terminated',
  },
  {
    key: 'UTILIZED',
    value: 'UTILIZED',
    label: 'Utilized',
  },
  {
    key: 'VALID',
    value: 'VALID',
    label: 'Valid',
  },
  {
    key: 'VOID_REQUESTED',
    value: 'VOID_REQUESTED',
    label: 'Void Requested',
  },
  {
    key: 'VOIDED',
    value: 'VOIDED',
    label: 'Voided',
  },
];

const Header: React.FC<Props> = ({ totalItems, searchText }) => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const filter = query.getAll(QUERY_KEY.filter) as string[];

  const { finalResult } = MergeArrayHook();

  const getLastModifiedDate = (value: string) => {
    const prefixRemoved = value
      ? new Date(value)
          ?.toISOString()
          ?.replaceAll(PREFIX_FILTER_GLOBAL_SEARCH.LAST_MODIFIED_DATE, '')
      : undefined;
    return prefixRemoved?.toUpperCase();
  };

  const getCreationDate = (value: string) => {
    const prefixRemoved = value
      ? new Date(value)?.toISOString()?.replaceAll(PREFIX_FILTER_GLOBAL_SEARCH.CREATION_DATE, '')
      : undefined;
    return prefixRemoved?.toUpperCase();
  };

  const getStatus = (value: string) => {
    const prefixRemoved = value?.replaceAll(PREFIX_FILTER_GLOBAL_SEARCH.STATUS, '');
    return prefixRemoved?.replaceAll(' ', '_')?.toUpperCase();
  };

  const getAssignedTo = (value: string) => {
    const prefixRemoved = value.replaceAll(PREFIX_FILTER_GLOBAL_SEARCH.ASSIGNED_TO, '');
    return prefixRemoved.replaceAll(' ', '_').split(',');
  };

  const getAssignToDisplay = (assignTo: string[] = []) => {
    return assignTo
      .map((r) => `${PREFIX_FILTER_GLOBAL_SEARCH.ASSIGNED_TO}${r.replace(/_/g, ' ')}`)
      .join(',');
  };

  const initialFormValue: SearchFilterFormValue = {
    lastModifiedDate: !isEmpty(filter?.[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE])
      ? dayjs(getLastModifiedDate(filter?.[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE])).format(
          'MM/DD/YYYY'
        )
      : null,
    creationDate: !isEmpty(filter?.[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE])
      ? dayjs(getCreationDate(filter?.[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE])).format(
          'MM/DD/YYYY'
        )
      : null,
    assignedTo: !isEmpty(filter?.[FILTER_GLOBAL_SEARCH_INDEX.ASSIGNED_TO])
      ? getAssignedTo(filter?.[FILTER_GLOBAL_SEARCH_INDEX.ASSIGNED_TO])
      : [],
    status: !isEmpty(filter?.[FILTER_GLOBAL_SEARCH_INDEX.STATUS])
      ? getStatus(filter?.[FILTER_GLOBAL_SEARCH_INDEX.STATUS])
      : null,
  };
  const initialFilter = Array.from({ length: FILTER_GLOBAL_SEARCH_INDEX.length }, (v) => '');

  const applyFilterQuery = (filters: string[]) => {
    filters.forEach((f) => query.append(QUERY_KEY.filter, f ? f : ''));
  };

  const handleSubmitFilter = (values) => {
    query.delete(QUERY_KEY.filter);
    const updateFilter = isEmpty(filter) ? initialFilter : filter;

    updateFilter[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE] = getLastModifiedDate(
      values?.lastModifiedDate
    );
    updateFilter[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE] = getCreationDate(values?.creationDate);

    updateFilter[FILTER_GLOBAL_SEARCH_INDEX.ASSIGNED_TO] = getAssignToDisplay(values?.assignedTo);
    updateFilter[FILTER_GLOBAL_SEARCH_INDEX.STATUS] = getStatus(values?.status);
    applyFilterQuery(updateFilter);
    query.delete('page');
    history.push({ search: query.toString() });
  };

  const handleClearAll = () => {
    setFieldValue(FILTER_GLOBAL_SEARCH_KEY.LAST_MODIFIED_DATE, '');
    setFieldValue(FILTER_GLOBAL_SEARCH_KEY.CREATION_DATE, '');
    setFieldValue(FILTER_GLOBAL_SEARCH_KEY.ASSIGNED_TO, []);
    setFieldValue(FILTER_GLOBAL_SEARCH_KEY.STATUS, '');
    applyFilterQuery(initialFilter);
    query.delete(QUERY_KEY.filter);
    history.push({ search: query.toString() });
  };

  const { setFieldValue, handleSubmit, getFieldProps, setFieldTouched, values } = useFormik({
    initialValues: initialFormValue,
    onSubmit: handleSubmitFilter,
    enableReinitialize: false,
  });
  const initDataAssignTo = useMemo(() => {
    return filter[FILTER_GLOBAL_SEARCH_INDEX.ASSIGNED_TO]
      ?.replaceAll(PREFIX_FILTER_GLOBAL_SEARCH.ASSIGNED_TO, '')
      .split(',');
  }, [filter]);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      id="header-search"
    >
      <Typography fontSize={16} fontWeight={600} color={'#6D7176'}>
        {`${totalItems} results for "${searchText}"`}
      </Typography>
      <CustomFilter.Container
        clearVariant="link"
        onApply={() => handleSubmit()}
        onClear={handleClearAll}
        large={true}
        filterForm={
          <>
            <Grid container spacing={3} mb={2}>
              <Grid item xl={6} lg={6} md={6}>
                <DatePicker
                  formatISODate
                  label="Last Modified Date"
                  placeholder="MM/DD/YYYY"
                  name={FILTER_GLOBAL_SEARCH_KEY.LAST_MODIFIED_DATE}
                  selected={values.lastModifiedDate ? new Date(values.lastModifiedDate) : undefined}
                  {...getFieldProps(FILTER_GLOBAL_SEARCH_KEY.LAST_MODIFIED_DATE)}
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6}>
                <DatePicker
                  formatISODate
                  label="Creation Date"
                  placeholder="MM/DD/YYYY"
                  name={FILTER_GLOBAL_SEARCH_KEY.CREATION_DATE}
                  selected={values.creationDate ? new Date(values.creationDate) : undefined}
                  {...getFieldProps(FILTER_GLOBAL_SEARCH_KEY.CREATION_DATE)}
                  onChange={setFieldValue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
              {/* <Grid item xl={6} lg={6} md={6}>
                <CustomFilter.Select
                  isHaveUnAssign
                  label={'Assign To'}
                  data={finalResult}
                  value={initDataAssignTo}
                  name={FILTER_GLOBAL_SEARCH_KEY.ASSIGNED_TO}
                  onChange={setFieldValue}
                />
              </Grid> */}
              <Grid item xl={6} lg={6} md={6}>
                <Select
                  label="Status"
                  hideSearchIcon={true}
                  name={FILTER_GLOBAL_SEARCH_KEY.STATUS}
                  value={values.status}
                  options={STATUS_LIST}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Grid>
            </Grid>
          </>
        }
      />
    </div>
  );
};

type Props = {
  totalItems: number;
  searchText: string;
};

export default Header;
