import { Redirect, Route, RouteProps } from 'react-router-dom';
import { IRootState } from 'src/redux/store';
import SplashScreen from '../StartupContainers/SplashScreen';
import { connect } from 'react-redux';
import { PATHS } from 'src/appConfig/paths';
import { listPermission } from '../UAMContainer/constants';
import { SidebarMenu } from 'src/appConfig/sidebar';

const CustomRoute: React.FC<Props> = ({
  isAuthenticated,
  myProfile,
  pageRequiredAuth,
  component,
  ...rest
}) => {
  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      // if (myProfile?.roles[0]?.role?.name === 'ADMIN') {
      //   Navigator.jumpToWebAdmin('');
      //   return;
      // }
      // Before render component, check permission first
      return <Component {...props} />;
    }

    let authRedirect = null;

    for (let i = 0; i < listPermission.length; i++) {
      const isFound = myProfile?.permissions.some((element) => element.includes(listPermission[i]));
      if (isFound) {
        authRedirect = listPermission[i];
        break;
      }
    }

    const redirectPath = isAuthenticated
      ? authRedirect
        ? SidebarMenu.find((item) => item.name === authRedirect)?.href ?? PATHS.noPermission
        : PATHS.noPermission
      : PATHS.signIn;

    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };

    return <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={renderRoute(component)} />;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteProps & { pageRequiredAuth?: boolean };

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  myProfile: state.auth.user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomRoute);
