export const TabSearchList = [
  {
    id: 1,
    label: 'All',
    key: null,
  },
  {
    id: 2,
    label: 'System User',
    key: 'user',
  },
  {
    id: 3,
    label: 'Teams',
    key: 'team',
  },
  {
    id: 4,
    label: 'Leads',
    key: 'lead',
  },
  {
    id: 5,
    label: 'Deals',
    key: 'deal',
  },
  {
    id: 6,
    label: 'Accounts',
    key: 'account',
  },
  {
    id: 7,
    label: 'Members',
    key: 'member',
  },
  {
    id: 8,
    label: 'ER Guard - Orders',
    key: 'er_guard_order',
  },
  {
    id: 9,
    label: 'ER Guard - Branch',
    key: 'er_guard_branch',
  },
  {
    id: 10,
    label: 'ER Guard - Company',
    key: 'er_guard_company',
  },
  {
    id: 11,
    label: 'ER Guard - Users',
    key: 'er_guard_user',
  },
  {
    id: 12,
    label: 'ER Guard - Product',
    key: 'er_guard_product',
  },
];

export const SYSTEM_SEARCH_LOCALSTORAGE = 'system-search';

export enum FILTER_GLOBAL_SEARCH_KEY {
  LAST_MODIFIED_DATE = 'lastModifiedDate',
  CREATION_DATE = 'creationDate',
  ASSIGNED_TO = 'assignedTo',
  STATUS = 'status',
}

export type SearchFilterFormValue = {
  lastModifiedDate: string;
  creationDate: string;
  assignedTo?: string[];
  status?: string;
};

export const FILTER_GLOBAL_SEARCH_INDEX = {
  LAST_MODIFIED_DATE: 0,
  CREATION_DATE: 1,
  ASSIGNED_TO: 2,
  STATUS: 3,
  length: 4,
};

export enum PREFIX_FILTER_GLOBAL_SEARCH {
  LAST_MODIFIED_DATE = 'Last_Modified: ',
  CREATION_DATE = 'Creation_Date: ',
  ASSIGNED_TO = 'Assign: ',
  STATUS = 'Status: ',
}

export enum MODULE_SEARCH_KEY {
  USER = 'user',
  TEAM = 'team',
  LEAD = 'lead',
  DEAL = 'deal',
  ACCOUNT = 'account',
  MEMBER = 'member',
  ER_GUARD_ORDER = 'er_guard_order',
  ER_GUARD_BRANCH = 'er_guard_branch',
  ER_GUARD_COMPANY = 'er_guard_company',
  ER_GUARD_USER = 'er_guard_user',
  ER_GUARD_PRODUCT = 'er_guard_product',
}

export const QUERY_KEY = {
  filter: 'filter',
  SEARCH: 'gSearch',
};
