import dayjs from 'dayjs';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { OrderPayload } from 'src/queries/ERGuard/type';
import { Yup } from 'src/services';
import { OrderDetail } from '../OrderDetail/Details/MemberInfo/helpers';
import { isEmpty } from 'src/validations';
import { _calculateAge, daysFromToday } from 'src/utils';
export const renderStaticItems = (data) => {
  return [
    {
      content: {
        leftText: data?.numberOfLeads,
        rightText: {
          text: data?.leadsGrowthRate
            ? `${data?.leadsGrowthRate > 0 ? '+ ' : '- '} ${Math.abs(data?.leadsGrowthRate)}%`
            : '',
          status: data?.leadsGrowthRate > 0 ? 1 : data?.leadsGrowthRate < 0 ? -1 : 0,
        },
      },
      label: 'Number of Leads',
    },
    {
      content: {
        leftText: data?.numberOfDeals,
        rightText: {
          text: data?.dealsGrowthRate
            ? `${data?.dealsGrowthRate > 0 ? '+ ' : '- '} ${Math.abs(data?.dealsGrowthRate)}%`
            : '',
          status: data?.dealsGrowthRate > 0 ? 1 : data?.dealsGrowthRate < 0 ? -1 : 0,
        },
      },
      label: 'Number of Deals',
    },
    {
      content: {
        leftText: data?.totalCustomers,
        rightText: {
          text: data?.customersGrowthRate
            ? `${data?.customersGrowthRate > 0 ? '+ ' : '- '} ${Math.abs(
                data?.customersGrowthRate
              )}%`
            : '',
          status: data?.customersGrowthRate > 0 ? 1 : data?.customersGrowthRate < 0 ? -1 : 0,
        },
      },
      label: 'Total Customers',
    },
  ];
};

export const formartCurrency = (value) => {
  return parseFloat(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'PHP',
    maximumFractionDigits: 2,
  });
};

export enum CR_ORDER_KEY {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  LAST_NAME = 'lastName',
  GENDER = 'gender',
  BUILDING = 'building',
  BARANGAY = 'barangay',
  TOWN_CITY = 'city',
  PROVINCE = 'province',
  OCCUPATION = 'occupation',
  BIRTHDAY = 'birthday',
  MOBILE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'email',
  TELLER = 'teller',
  BLK = 'blk',
  STREET = 'street',
  BRANCH = 'branchId',
  PRODUCT_ID = 'productId',
  LOCATION = 'location',
  ZIP_CODE = 'zipCode',
  FLOOR = 'floor',
  REGION = 'region',
}
export type CROrderFormValue = {
  location: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  gender: string;
  occupation: string;
  birthday: string;
  phoneNumber: string;
  email: string;
  floor: string;
  building: string;
  blk: string;
  barangay: string;
  street: string;
  cityId: string;
  provinceId: string;
  zipCode: string;
  regionId: string;
  productId: string;
  branchId: number;
  teller: string;
  city?: any;
  region?: any;
  province?: any;
};
export const initialCROrderFormValue: CROrderFormValue = {
  location: '',
  firstName: '',
  middleName: '',
  lastName: '',
  gender: '',
  occupation: '',
  birthday: undefined,
  phoneNumber: '',
  email: '',
  floor: '',
  building: '',
  blk: '',
  barangay: '',
  street: '',
  cityId: '',
  provinceId: '',
  zipCode: '',
  regionId: '',
  productId: '',
  branchId: undefined,
  teller: '',
  city: null,
  province: null,
  region: null,
};

export enum GENDER_LIST {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
}

export const GENDER_OPTIONS = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export const BRANCH_OPTIONS = [
  {
    label: 'Branch 1',
    value: 'b1',
  },
  {
    label: 'Branch 2',
    value: 'b2',
  },
  {
    label: 'Branch 3',
    value: 'b3',
  },
];

export const PROVINCE_OPTIONS = [
  {
    label: 'District 1',
    value: 'male',
  },
  {
    label: 'District 2',
    value: 'female',
  },
  {
    label: 'District 3',
    value: 'other',
  },
];
export const OrderDetailSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required').nullable(),
  lastName: Yup.string().required('This field is required').nullable(),
  city: Yup.object().required('This field is required').nullable(),
  province: Yup.object().when(['city', 'region'], {
    is: (city, region) => isEmpty(city) || isEmpty(region),
    then: Yup.object().required('This field is required').nullable(),
    otherwise: Yup.object().nullable(),
  }),
  region: Yup.object().required('This field is required').nullable(),
  occupation: Yup.string().required('This field is required').nullable(),
  birthday: Yup.string()
    .required('This field is required')
    .nullable()
    .test(
      'birthday',
      `This member is either 'younger than 15 days' or '60 years old or older'`,
      (value) => {
        return daysFromToday(new Date(value)) >= 15 && _calculateAge(new Date(value)) <= 60;
      }
    ),
  phoneNumber: Yup.string()
    .test(
      'is-phone',
      (d) => `This field is required`,
      (value) => !!value && isValidPhoneNumber(value)
    )
    .nullable(),
  email: Yup.string().email().nullable(),
  gender: Yup.string().required('This field is required').nullable(),
});
export const CROrderFormSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required').nullable(),
  branchId: Yup.string().required('This field is required').nullable(),
  productId: Yup.string().required('This field is required').nullable(),
  lastName: Yup.string().required('This field is required').nullable(),
  gender: Yup.string().required('This field is required').nullable(),
  city: Yup.object().required('This field is required').nullable(),
  province: Yup.object().when(['city', 'region'], {
    is: (city, region) => isEmpty(city) || isEmpty(region),
    then: Yup.object().required('This field is required').nullable(),
    otherwise: Yup.object().nullable(),
  }),
  region: Yup.object().required('This field is required').nullable(),
  occupation: Yup.string().required('This field is required').nullable(),
  birthday: Yup.string()
    .required('This field is required')
    .nullable()
    .test(
      'birthday',
      `This member is either 'younger than 15 days' or '60 years old or older'`,
      (value) => {
        return daysFromToday(new Date(value)) >= 15 && _calculateAge(new Date(value)) <= 60;
      }
    ),
  teller: Yup.string().required('This field is required').nullable(),
  phoneNumber: Yup.string()
    .test(
      'is-phone',
      (d) => `This field is required`,
      (value) => !!value && isValidPhoneNumber(value)
    )
    .nullable(),
  email: Yup.string().email().nullable(),
});
export const formatPayloadOrder = (values: CROrderFormValue) => {
  let payload = {
    memberInformation: {
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      gender: values.gender,
      occupation: values.occupation,
      birthday: values.birthday && new Date(values.birthday).toISOString(),
      phoneNumber: values.phoneNumber,
      email: values.email,
    },
    address: {
      street: values.street,
      barangay: values.barangay,
      cityId: !isEmpty(values?.city) && values?.city?.id,
      provinceId: !isEmpty(values?.province) && values?.province?.id,
      regionId: !isEmpty(values?.region) && values?.region?.id,
      floor: values.floor ?? '',
      building: values.building ?? '',
      blk: values.blk ?? '',
      zipCode: values.zipCode,
    },
    productId: values.productId ?? '',
    branchId: values.branchId ? Number(values.branchId) : undefined,
    teller: values.teller ?? '',
  };
  Object.entries(payload.memberInformation).map(([key, value]) => {
    if (typeof value === 'string') {
      return value.trim();
    }
  });
  return payload;
};
export type CROrderFormikProps = {
  values: CROrderFormValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<CROrderFormValue>>;
  errors: FormikErrors<CROrderFormValue>;
  touched: FormikTouched<CROrderFormValue>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<CROrderFormValue>>;
  validateForm: (values?: CROrderFormValue) => Promise<FormikErrors<CROrderFormValue>>;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
};

export const getErrorMessage = (fieldName: string, { touched, errors }) => {
  // eslint-disable-next-line security/detect-object-injection
  return touched[fieldName] && errors[fieldName] ? errors[fieldName] : '';
};
