import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';

import { useState } from 'react';
import { isEmpty } from 'src/validations';
import { API_QUERIES } from '../keys';
import { ResponseSearchDocuments } from './types';

export function useGetSearchAllDocuments(
  options: UseQueryOptions<ApiResponseType<any>, Error, ResponseSearchDocuments>
) {
  const [params, setParams] = useState<any>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetSearchDocuments,
  } = useQuery<ApiResponseType<any>, Error, ResponseSearchDocuments>(
    [API_QUERIES.SYSTEM_SEARCH, params],
    {
      queryFn: (query) => {
        const [, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<any>>(apiClient.getSearchAllDocuments, params);
      },
      select(data) {
        return data.data;
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !isEmpty(params),
      onSuccess(data) {},
      ...options,
      staleTime: 2000,
    }
  );

  return {
    searchDocuments: data?.data ?? [],
    error,
    isError,
    isFetching,
    setParams,
    onGetSearchDocuments,
  };
}
