import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { UpdateTokenPayload } from './types';

export function useDisabledPushNotification(
  options?: UseMutationOptions<any, Error, UpdateTokenPayload>
) {
  const { mutate: disableNotification, isLoading } = useMutation<any, Error, UpdateTokenPayload>({
    mutationFn: (payload: UpdateTokenPayload) => {
      return authResponseWrapper(apiClient.disablePushNotification, [payload]);
    },
    ...options,
  });

  return {
    disableNotification,
    isLoading,
  };
}
