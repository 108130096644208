import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import { topPadding } from 'src/appConfig/constants';
import { Lead } from 'src/queries/Leads/types';
import { Yup } from 'src/services';
import { isEmpty } from 'src/validations';

export enum CRU_INTEREST_KEY {
  FIRST_NAME = 'firstName',
  MIDDLE_NAME = 'middleName',
  ORGANIZATION = 'organization',
  LAST_NAME = 'lastName',
  BIRTHDAY = 'birthday',
  EMAIL = 'email',
  REFERRAL = 'referral',
  PLAN_TYPE = 'planType',
  SOURCE = 'source',
  ZIP_CODE = 'zipCode',
  PHONE_NUMBER = 'phoneNumber',
  BLK = 'bLK',
  STREET = 'street',
  BARANGAY = 'barangay',
  MUNICIPALITY = 'municipality',
  PROVINCE = 'province',
  REGION = 'region',
  SOURCE_OTHER = 'sourceOther',
  MARK_CONFIRM = 'markConfirm',
}

export type CRUInterestFormValue = {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthday: Date;
  email: string;
  referral: string;
  planType: string;

  source: string;
  sourceOther?: string;

  zipCode: string;
  phoneNumber: string;
  bLK: string;
  street: string;
  municipality: any;
  province: any;
  markConfirm: boolean;
  region: any;
  // organization: string;
};

export type CRUInterestPayLoad = {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  birthday: string;
  email: string;
  referral: string;
  planType: string;

  source: string;
  sourceOther?: string;

  zipCode: string;
  phoneNumber: string;
  bLK: string;
  street: string;
  municipality: string;
  province: string;
};

export const initialCRUInterestFormValue: CRUInterestFormValue = {
  firstName: '',
  lastName: '',
  middleName: '',
  birthday: undefined,
  email: '',
  referral: '',
  planType: '',
  source: '',
  zipCode: '',
  phoneNumber: '',
  bLK: '',
  street: '',
  municipality: '',
  province: undefined,
  markConfirm: false,
  region: undefined,
};

export type CRUInterestFormikProps = {
  values: CRUInterestFormValue;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<CRUInterestFormValue>>;
  errors: FormikErrors<CRUInterestFormValue>;
  touched: FormikTouched<CRUInterestFormValue>;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean
  ) => Promise<void> | Promise<FormikErrors<CRUInterestFormValue>>;
};

export type AssignAgentPayload = {
  ids: Lead['id'][];
  saleAgentId: string;
};

export const planTypeOptions = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'FAMILY', label: 'Family' },
  { value: 'SECURITY GUARD', label: 'Security Guard' },
  { value: 'PHILIPPINES NATIONAL POLICE', label: 'Philippines National Police' },
  { value: 'CORPORATE', label: 'Corporate' },
  { value: 'GOVERNMENT', label: 'Government' },
];

export const accountTypeOptions = [
  { value: 'NEW', label: 'New' },
  { value: 'PRINCIPAL', label: 'Principal' },
  { value: 'DEPENDENTS', label: 'Dependents' },
  { value: 'SERVICE', label: 'Service' },
];

export const StatusLead = [
  { value: 'NEW_LEAD', label: 'New lead' },
  { value: 'CALLED', label: 'Called' },
  { value: 'PRESENTED', label: 'Presented' },
  { value: 'PROPOSAL_SENT', label: 'Proposal sent' },
  { value: 'FIRST_FOLLOW_UP', label: '1st follow up' },
  { value: 'SECOND_FOLLOW_UP', label: '2nd follow up' },
  { value: 'THIRD_FOLLOW_UP', label: '3rd follow up' },
  { value: 'SIGNED_PROPOSAL', label: 'Signed Proposal' },
  { value: 'LOST', label: 'Lost' },
];

export const GOVERNMENT_ID_TYPE_LIST = [
  { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate' },
  { value: 'PHILIPPINE_NATIONAL_ID', label: 'Philippine National ID' },
  { value: 'UNIFIED_MULTI_PURPOSE_ID', label: 'Unified Multi-Purpose ID (UMID)' },
  { value: 'PASSPORT', label: 'Passport' },
  { value: 'DRIVER_LICENSE', label: 'Driver’s License' },
  { value: 'PRC_OR_IBP_ID', label: 'PRC/Integrated Bard of the Philippines (IBP) ID' },
  { value: 'SSS_GSIS_TIN_PHILHEALTH_CARD', label: 'SSS/GSIS/TIN/PhilHealth card' },
  { value: 'OFW_OWWA_ID', label: 'OFW/OWWA ID' },
  { value: 'NBI_CLEARENCE ', label: 'NBI clearance ' },
  { value: 'POSTAL_ID', label: 'Postal ID' },
  { value: 'OSCA_OR_SENIOR_CITIZEN', label: 'OSCA ID or Senior Citizen’s card' },
];

export const CRUInterestFormSchema = Yup.object().shape({
  email: Yup.string()
    .notTrimmable()
    .email('Invalid Email format.')
    .required('This field is required')
    .nullable(),
  middleName: Yup.string().letterOnly().max(255).notTrimmable(),
  firstName: Yup.string()
    .letterOnly()
    .max(255)
    .notTrimmable()
    .required('This field is required')
    .nullable(),
  lastName: Yup.string()
    .letterOnly()
    .max(255)
    .notTrimmable()
    .required('This field is required')
    .nullable(),
  birthday: Yup.string().notTrimmable().required('This field is required').nullable(),
  // bLK: Yup.string().required('This field is required').nullable(),
  // zipCode: Yup.string().required('This field is required').nullable(),
  phoneNumber: Yup.string().phone().required('This field is required').nullable(),
  planType: Yup.string()
    .required('This field is required')
    .nullable()
    .typeError('This field is required'),
  markConfirm: Yup.boolean().oneOf(
    [true],
    'Please acknowledge the statements in order to submit the form.'
  ),
  source: Yup.string().required('Please select an option').nullable(),
  // street: Yup.string().required('This field is required').nullable(),
  region: Yup.object().required('This field is required').nullable(),
  municipality: Yup.object().required('This field is required').nullable(),
  province: Yup.object().when(['municipality', 'region'], {
    is: (city, region) => isEmpty(city) || isEmpty(region),
    then: Yup.object().required('This field is required').nullable(),
    otherwise: Yup.object().nullable(),
  }),
});

export const AssignAgentSchema = Yup.object().shape({
  // assignAgentId: Yup.string().required('This field is required').nullable(),
});

export const getErrorMessage = (fieldName: string, { touched, errors }) => {
  // eslint-disable-next-line security/detect-object-injection
  return touched[fieldName] && errors[fieldName] ? errors[fieldName] : '';
};

export const formatPayloadSubmit = (values: CRUInterestFormValue) => {
  const _values = {
    ...values,
    zipCode: values.zipCode,
    source: values.source === 'OTHER' ? values.sourceOther : values.source,
    birthday: values.birthday ? new Date(values.birthday).toISOString() : new Date().toISOString(),
    municipality: values.municipality && values.municipality.name,
    province: values.province && values.province.name,
    region: values.region && values.region.name,
  };

  delete _values['sourceOther'];

  return _values;
};

export const formatAssignAgent = (values: AssignAgentPayload) => {
  const _values = {
    ...values,
  };
  return _values;
};

export const scrollToTop = (nameIdOfElement: string) => {
  const element = document.getElementById(nameIdOfElement);
  element.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToBottom = (nameIdOfElement: string) => {
  const element = document.getElementById(nameIdOfElement);
  // delay to allow below element to mount in before scrolling down
  setTimeout(() => {
    element.scrollTo({ top: topPadding.top, behavior: 'smooth' });
  }, 500);
};
