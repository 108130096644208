/* eslint-disable security/detect-object-injection */
import dayjs from 'dayjs';
import { Location } from 'history';
import _, { get, isEqual } from 'lodash';
import { parse } from 'qs';
import shortid from 'shortid';
import { ErrorService, Toastify } from 'src/services';
import { isEmpty } from 'src/validations';

export const emptyFunction = () => {};

export const getRandomId = (): string => shortid.generate();

export const generateArray = (length: number, initial = '') => Array(length).fill(initial);

export const getLocationState = (location: Location<string>) => {
  const locationState = location.state;
  const state = parse(locationState, { ignoreQueryPrefix: true });

  return state;
};

export const getWeekDay = (value: string) => {
  if (!value) return '';
  return dayjs(value).format('dddd');
};

export const getClassNameByStatus = (status) => {
  switch (status) {
    case 'Pending':
      return 'is-status-pending ';
    case 'Completed':
    case 'Approved':
    case 'Active':
      return 'is-status-active';
    case 'Rejected':
      return 'is-status-rejected';
    default:
      return '';
  }
};

export const getActionByEnum = (action) => {
  switch (action) {
    case 'POST':
      return 'Create';
    case 'PUT':
      return 'Update';
    case 'DELETE':
      return 'Delete';
    case 'UPLOAD':
      return 'Upload';
    default:
      return '';
  }
};

export const getYesNoText = (value: boolean) => (value ? 'Yes' : 'No');

export const getNavigateUrl = (url: string) => (url.includes('http') ? url : `https://${url}`);

export const isURLImage = (url: string) => {
  if (isEmpty(url)) return false;

  const hasExtensionImage = [
    '.png',
    '.jpeg',
    '.jpg',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/svg',
  ].some((ext) => url?.includes(ext));

  if (hasExtensionImage) {
    return true;
  }

  const state = parse(url?.split('?')[1], { ignoreQueryPrefix: false });
  const contentType = state?.['Content-Type'];
  const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(contentType as string);

  return isImage;
};

export const handleGetError = (touched, errors, prefix) =>
  _.get(touched, prefix) ? _.get(errors, prefix) : '';

export const waiter = (time: number = 100) =>
  new Promise<Array<any>>((res) => setTimeout(() => res([]), time));

export const trimUrlHasEndDate = (url: string) => {
  const trimUrl = url.split('?')[0];
  const items = trimUrl.split('_');
  return items.slice(0, items.length - 1).join('');
};

export const trimUrl = (url: string) => {
  if (!url) return null;
  return url.split('?')[0];
};

export const handleClick = (callback) => (event: React.MouseEvent<any>) => {
  event.stopPropagation();
  event.preventDefault();
  if (callback) callback(event);
};

//link https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
export const deepKeys = (t, path = []) => {
  const res =
    Object(t) === t
      ? Object.entries(t) // 1
          .flatMap(([k, v]) => deepKeys(v, [...path, k]))
      : [path.join('.')]; // 2
  return res?.filter((x) => !/\d$/.test(x));
};

export const scrollToTopError = (error: string[]) => {
  if (!isEmpty(error)) {
    const input = document?.querySelector(`[name='${error[0]}']`);
    input?.parentElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};

export const handleShowErrorMsg = (error: Error, prefix: string = '') => {
  let errorMessage = ErrorService.MESSAGES.unknown;
  if (!isEmpty(error)) {
    if (typeof error?.message === 'string') {
      errorMessage = error?.message;
    } else {
      errorMessage = error?.message[0];
    }
    Toastify.error(`${!isEmpty(prefix) ? `${prefix}: ` : ''}${errorMessage}`);
  }
};

export const handleScrollToTopError = <T>(errors: T) => {
  return setTimeout(() => {
    scrollToTopError(deepKeys(errors));
  }, 100);
};

export const getErrorMessage = (fieldName: string, { touched, errors }) => {
  if (!fieldName || !touched || !errors) return '';

  const error = get(errors, fieldName);

  return get(touched, fieldName) && error ? error : '';
};

export const isEqualPrevAndNextObjByPath = <T>({
  prevValues,
  nextValues,
  path,
  checkEqualLengthArray,
}: {
  prevValues: T;
  nextValues: T;
  path: string;
  checkEqualLengthArray?: boolean;
}) => {
  const prev = get(prevValues, path);
  const next = get(nextValues, path);
  return checkEqualLengthArray && Array.isArray(prev) && Array.isArray(next)
    ? prev.length === next.length
    : isEqual(prev, next);
};

export const getOptionsByEnum = (enumObject) => {
  if (isEmpty(enumObject)) return [];

  return Object.keys(enumObject).map((key) => ({
    label: enumObject[key],
    value: enumObject[key],
  }));
};

export const isString = (value: any): value is String => {
  return typeof value === 'string';
};

export function parseCSV(str) {
  const arr = [];
  let quote = false; // 'true' means we're inside a quoted field

  // Iterate over each character, keep track of current row and column (of the returned array)
  for (let row = 0, col = 0, c = 0; c < str.length; c++) {
    let cc = str[c],
      nc = str[c + 1]; // Current character, next character
    arr[row] = arr[row] || []; // Create a new row if necessary
    arr[row][col] = arr[row][col] || ''; // Create a new column (start with empty string) if necessary

    // If the current character is a quotation mark, and we're inside a
    // quoted field, and the next character is also a quotation mark,
    // add a quotation mark to the current column and skip the next character
    if (cc == '"' && quote && nc == '"') {
      arr[row][col] += cc;
      ++c;
      continue;
    }

    // If it's just one quotation mark, begin/end quoted field
    if (cc == '"') {
      quote = !quote;
      continue;
    }

    // If it's a comma and we're not in a quoted field, move on to the next column
    if (cc == ',' && !quote) {
      ++col;
      continue;
    }

    // If it's a newline (CRLF) and we're not in a quoted field, skip the next character
    // and move on to the next row and move to column 0 of that new row
    if (cc == '\r' && nc == '\n' && !quote) {
      ++row;
      col = 0;
      ++c;
      continue;
    }

    // If it's a newline (LF or CR) and we're not in a quoted field,
    // move on to the next row and move to column 0 of that new row
    if (cc == '\n' && !quote) {
      ++row;
      col = 0;
      continue;
    }
    if (cc == '\r' && !quote) {
      ++row;
      col = 0;
      continue;
    }

    // Otherwise, append the current character to the current column
    arr[row][col] += cc;
  }
  return arr;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}
export const diffDays = (date_1, date_2) => {
  let difference = new Date(date_1).getTime() - new Date(date_2).getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  if (TotalDays > 0) return TotalDays;
  return;
};
