import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Button, View } from 'src/components/common';
import { useGetAllLeads } from 'src/queries/Leads/useGetAllLeads';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { IRootState } from 'src/redux/store';
import { deepKeys, scrollToTopError } from 'src/utils';
import InterestFormInfo from './InterestFormInfo';
import {
  CRUInterestFormSchema,
  CRUInterestFormValue,
  CRUInterestFormikProps,
  formatPayloadSubmit,
  initialCRUInterestFormValue,
} from './helpers';

import { Lead } from 'src/queries/Leads/types';

import { handleShowErrorMsg } from 'src/utils/handleUtils';
import { useCreateInterest } from 'src/queries/Interest/useCreateInterest';
import { muiResponsive } from 'src/appConfig/constants';
import { Navigator } from 'src/services';

const LeadInboxModal: React.FC<Props> = ({ onHideAllDialog, isAssignAgent = false }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formRef = React.useRef<FormikProps<CRUInterestFormValue>>(null);
  const isMobileScreen = useMediaQuery(muiResponsive.MOBILE);

  const initialFormValue = React.useMemo(() => {
    return {
      ...initialCRUInterestFormValue,
    };
  }, []);

  const handleScrollToTopError = () => {
    return setTimeout(() => {
      scrollToTopError(deepKeys(errors));
    }, 100);
  };

  const { createInterest, isLoading: isLoadingCreateInterest } = useCreateInterest({
    onSuccess(data, variables, context) {
      Navigator.jumpToWebLMP('/confirmation-inquiry');
    },
    onError(error, variables, context) {
      handleShowErrorMsg(error);
    },
  });

  useGetAllLeads({
    onError: (error) => handleShowErrorMsg(error),
  });

  const handleFormSubmit = (values: CRUInterestFormValue) => {
    const payload = formatPayloadSubmit(values);
    createInterest(payload);
  };

  const { values, setFieldValue, errors, touched, getFieldProps, setFieldTouched, handleSubmit } =
    useFormik<CRUInterestFormValue>({
      initialValues: initialFormValue,
      onSubmit: handleFormSubmit,
      validationSchema: CRUInterestFormSchema,
      innerRef: formRef,
      enableReinitialize: true,
    });

  const formikProps: CRUInterestFormikProps = {
    values,
    setFieldValue,
    errors,
    touched,
    getFieldProps,
    setFieldTouched,
  };

  return (
    <Container maxWidth="lg">
      <>
        {isMobileScreen && (
          <View isRow justify="center" align="center">
            <Typography variant="h6" className="heading-xs" marginBottom={1}>
              GET INSURED
            </Typography>
          </View>
        )}
        <InterestFormInfo formikProps={formikProps} />
        <Stack mt={4} flexDirection={'row'} justifyContent="center">
          <Button
            style={{ backgroundColor: '#F89837' }}
            variant="secondary"
            isLoading={isLoadingCreateInterest}
            disabled={isLoadingCreateInterest}
            onClick={() => {
              handleScrollToTopError();
              handleSubmit();
            }}
          >
            {'Submit'}
          </Button>
        </Stack>
      </>
    </Container>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    dataLead?: any;
    isAssignAgent?: boolean;
    listIds?: Lead['id'][];
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadInboxModal);
