import { useMutation, UseMutationOptions } from 'react-query';
import { CRUInterestPayLoad } from 'src/containers/SaleContainers/InterestForm/helpers';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useCreateInterest(options?: UseMutationOptions<any, Error, CRUInterestPayLoad>) {
  const { mutate: createInterest, isLoading } = useMutation<any, Error, CRUInterestPayLoad>({
    mutationFn: (payload: CRUInterestPayLoad) =>
      authResponseWrapper(apiClient.createInterest, [payload]),
    ...options,
  });

  return {
    createInterest,
    isLoading,
  };
}
