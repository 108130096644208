const configs = {
  WEB_URL: process.env.REACT_APP_WEB_URL,
  API_URL: process.env.REACT_APP_API_URL,
  ADMIN_WEB_URL: process.env.REACT_APP_ADMIN_WEB_URL,
  IDENTITY_WEB_URL: process.env.REACT_APP_IDENTITY_WEB_URL,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  TIME_ZONE: process.env.REACT_APP_TIME_ZONE,
  APP_ENV: process.env.REACT_APP_BUILD_MODE,
  AWS_S3_WEB_LOGIN_BUCKET_URL: process.env.REACT_APP_AWS_S3_WEB_LOGIN_BUCKET_URL,
  APP_VERSION: process.env.REACT_APP_VERSION || '0.1.0',
  CCP_URL: process.env.REACT_APP_AWS_CCP_URL,
  CCP_REGION: process.env.REACT_APP_AWS_CCP_REGION,
  SALE_QUEUE_ARN: process.env.REACT_APP_AWS_SALES_QUEUE_ARN,
  LOGIN_KEY: process.env.REACT_APP_LOGIN_KEY,
  LMP_WEB_URL: process.env.REACT_APP_LMP_WEB_URL,
  SITE_KEY: process.env.REACT_APP_GOOGLE_SITE_KEY,
};

const common = {
  CONNECTION_TIMEOUT: 30000,
  MAXIMUM_FILE_SIZE: 1024 * 1024 * 7, //7 MB
  WAITING_TIME: 5000, // 5 secs
  ANIMATION_TIME: 300,
  MAXIMUM_AVATAR_SIZE: 16 * 1024 * 1024, // 16MB
};

const table = {
  ROWS_PER_PAGE_OPTIONS: [20, 50, 100],
  ROWS_PER_PAGE: 20,
  SECOND_ROWS_PER_PAGE_OPTIONS: [10, 20, 50, 100],
  SECOND_ROWS_PER_PAGE: 10,
};

const textLength = {
  CODE_LENGTH: 16,
  TEXT_SHORT_LENGTH: 50,
  TEXT_MEDIUM_LENGTH: 100,
  TEXT_MAX_LENGTH: 255,
  VERIFICATION_CODE_LENGTH: 6,
};

const AWS_CONFIG = {
  region: process.env.REACT_APP_AWS_IDENTITY_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth: {
    domain: process.env.REACT_APP_AWS_USER_POOL_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.REACT_APP_WEB_URL + '/signin',
    redirectSignOut: process.env.REACT_APP_WEB_URL + '/signin',
    responseType: 'code',
  },
  cookieStorage: {
    domain: configs.REACT_APP_COOKIE_DOMAIN,
    path: '/',
    secure: configs.NODE_ENV === 'test',
    expires: 365,
  },
};

const CUSTOM_AWS_CONFIG = {
  ...AWS_CONFIG,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
};

const PERMISSIONS = {
  SALES_DASHBOARD: [
    'SALES_DASHBOARD:C',
    'SALES_DASHBOARD:R',
    'SALES_DASHBOARD:U',
    'SALES_DASHBOARD:D',
  ],
  UPD_DASHBOARD: ['UPD_DASHBOARD:C', 'UPD_DASHBOARD:R', 'UPD_DASHBOARD:U', 'UPD_DASHBOARD:D'],
  MEDICAL_DASHBOARD: [
    'MEDICAL_DASHBOARD:C',
    'MEDICAL_DASHBOARD:R',
    'MEDICAL_DASHBOARD:U',
    'MEDICAL_DASHBOARD:D',
  ],
  FINANCE_DASHBOARD: [
    'FINANCE_DASHBOARD:C',
    'FINANCE_DASHBOARD:R',
    'FINANCE_DASHBOARD:U',
    'FINANCE_DASHBOARD:D',
  ],
  USER_MANAGEMENT: [
    'USER_MANAGEMENT:C',
    'USER_MANAGEMENT:R',
    'USER_MANAGEMENT:U',
    'USER_MANAGEMENT:D',
  ],
  ROLE_MANAGEMENT: [
    'ROLE_AND_PERMISSION:C',
    'ROLE_AND_PERMISSION:R',
    'ROLE_AND_PERMISSION:U',
    'ROLE_AND_PERMISSION:D',
  ],
  TEAM_MANAGEMENT: ['TEAMS:C', 'TEAMS:R', 'TEAMS:U', 'TEAMS:D'],
  LEAD_MANAGEMENT: ['LEADS:C', 'LEADS:R', 'LEADS:U', 'LEADS:D'],
  DEAL_MANAGEMENT: ['DEALS:C', 'DEALS:R', 'DEALS:U', 'DEALS:D'],
  // ACCOUNT_MANAGEMENT: ['ACCOUNT:C', 'ACCOUNT:R', 'ACCOUNT:U', 'ACCOUNT:D'],
  ACCOUNT_RECORD_MANAGEMENT: [
    'ACCOUNT_RECORDS:C',
    'ACCOUNT_RECORDS:R',
    'ACCOUNT_RECORDS:U',
    'ACCOUNT_RECORDS:D',
  ],
  MEMBER_RECORD_MANAGEMENT: [
    'MEMBER_RECORDS:C',
    'MEMBER_RECORDS:R',
    'MEMBER_RECORDS:U',
    'MEMBER_RECORDS:D',
  ],
  ORDER_MANAGEMENT: [
    'ORDER_MANAGEMENT_ERGUARD:C',
    'ORDER_MANAGEMENT_ERGUARD:R',
    'ORDER_MANAGEMENT_ERGUARD:U',
  ],
  COMPANY_MANAGEMENT: [
    'BRANCH_MANAGEMENT_ERGUARD:C',
    'BRANCH_MANAGEMENT_ERGUARD:R',
    'BRANCH_MANAGEMENT_ERGUARD:U',
    'COMPANY_MANAGEMENT_ERGUARD:C',
    'COMPANY_MANAGEMENT_ERGUARD:R',
    'COMPANY_MANAGEMENT_ERGUARD:U',
  ],
  PRODUCT_MANAGEMENT: [
    'PRODUCT_MANAGEMENT_ERGUARD:C',
    'PRODUCT_MANAGEMENT_ERGUARD:R',
    'PRODUCT_MANAGEMENT_ERGUARD:U',
  ],
  PAYMENT_MANAGEMENT: [
    'PAYMENT_MANAGEMENT_ERGUARD:C',
    'PAYMENT_MANAGEMENT_ERGUARD:R',
    'PAYMENT_MANAGEMENT_ERGUARD:U',
  ],
  ER_USERMANAGEMENT: [
    'USER_MANAGEMENT_ERGUARD:C',
    'USER_MANAGEMENT_ERGUARD:R',
    'USER_MANAGEMENT_ERGUARD:U',
  ],
  DASHBOARD_ERGUARD: ['DASHBOARD_ERGUARD:R'],
  BILLING: ['BILLING:C', 'BILLING:R', 'BILLING:U', 'BILLING:D'],
  UNVOID_ORDER: [
    'UNVOID_PERMISSION:C',
    'UNVOID_PERMISSION:R',
    'UNVOID_PERMISSION:U',
    'UNVOID_PERMISSION:D',
  ],
  CONFIGURATION_PRODUCT: ['CONFIGURATION_PRODUCTS:R', 'CONFIGURATION_PRODUCTS:U'],
  CONFIGURATION_BENEFITS: [
    'CONFIGURATION_BENEFITS:C',
    'CONFIGURATION_BENEFITS:R',
    'CONFIGURATION_BENEFITS:U',
    'CONFIGURATION_BENEFITS:D',
  ],
  CONFIGURATION_MATRIX: ['CONFIGURATION_MATRIX:R', 'CONFIGURATION_MATRIX:U'],
};

export default {
  ...configs,
  ...common,
  ...textLength,
  ...table,
  ...PERMISSIONS,
  AWS_CONFIG,
  CUSTOM_AWS_CONFIG,
};
