/* eslint-disable security/detect-object-injection */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Checkbox, Grid, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useComponentDidMount, useDebounce } from 'src/hooks';
import { Input, Button as LinkButton } from '../common';
import './styles.scss';

/**
 *
 * 1. Please send correctly data and value
 * 2. Pay attention about the "intersections" value went open this component did mount
 */

export interface SelectedValue {
  key?: string;
  value: string;
  label: string;
}

export interface DataSelectedProps {
  [key: string]: any[];
}

export interface DataRender {
  key: string;
  children: SelectedValue[];
}

export const SelectFilter: React.FC<SelectProps> = ({
  label,
  options,
  data = {},
  onChange,
  value = [],
  name,
  errorMessage,
  columns = 1,
  enableSearch = false,
  isHaveUnAssign = false,
  showHiddenItem = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const [listSelected, setListSelected] = useState<SelectedValue[]>([]);
  const [dataRender, setDataRender] = useState<DataRender[]>([]);

  // const [isSelectedAll, setIsSelectedAll] = useState([]);

  const toggleClick = (e) => {
    setAnchorEl(e.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /*----- Open this if have search bar -----*/
  const [searchValue, setSearchValue] = useState('');
  const debounceValue = useDebounce(searchValue, 200);

  useEffect(() => {
    onChange?.(
      name,
      listSelected?.map((item) => {
        return item.value;
      })
    );
  }, [name, onChange, listSelected]);

  const intersections = Object.keys(data)
    .map((key) => {
      return data?.[key];
    })
    .flat(Infinity)
    .filter((e) => {
      return value.indexOf(e.key) !== -1;
    });

  useComponentDidMount(() => {
    setListSelected(intersections);

    if (value?.includes('unassigned' as any)) {
      setListSelected((prev) => [
        ...prev,
        { value: 'unassigned', label: 'unassigned', key: 'unassigned' },
      ]);
    }

    const dataTransForm = Object.entries(data).map(([key, value], idx) => ({
      key: key,
      children: value,
    }));

    // const selected = Object.entries(data).map(([key, value], idx) => ({
    //   key: key,
    //   isSelectedAll: false,
    // }));

    // setIsSelectedAll(selected);
    setDataRender(dataTransForm);
  });

  const handleSelectUser = (selectedItem: SelectedValue) => {
    const checkDuplicateValue = listSelected.some((item) => item.key === selectedItem.key);

    if (checkDuplicateValue) {
      setListSelected((prev) => prev.filter((item, idx) => item.key !== selectedItem.key));
    } else {
      setListSelected((prev) => [
        ...prev,
        { value: selectedItem.value, key: selectedItem.key, label: selectedItem.label },
      ]);
    }
  };

  const handleSelectAll = (item) => {
    const isCurrentInArray = listSelected.filter((value, idx) => {
      return item.children.some((itemChild) => value.key === itemChild.key);
    });

    const isCurrentHaveInArrayButNotFull =
      item.children.filter((valueFilter) => listSelected.includes(valueFilter)).length <
      item.children.length;

    const checkSelectedAll =
      item.children.filter((valueFilter) => listSelected.includes(valueFilter)).length ===
      item.children.length;

    if (isCurrentInArray.length === 0 && isCurrentInArray.length < item.children.length) {
      setListSelected((prev) => [...prev, ...item.children]);
    } else if (isCurrentHaveInArrayButNotFull) {
      setListSelected((prev) =>
        prev.filter(
          (dataItem) => !item.children.find(({ key }) => dataItem.key === key) && dataItem.value
        )
      );
      setListSelected((prev) => [...prev, ...item.children]);
    } else if (checkSelectedAll) {
      setListSelected((prev) =>
        prev.filter(
          (dataItem) => !item.children.find(({ key }) => dataItem.key === key) && dataItem.value
        )
      );
    }
  };
  const widthLabelRef = useRef<any>(data?.categories?.map(() => React.createRef()));
  const [widthLabel, setWidthLabel] = useState([]);

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        const measuredWidths = widthLabelRef.current.map((ref) =>
          ref?.current ? ref?.current?.offsetWidth : 0
        );
        setWidthLabel(measuredWidths);
      }, 0);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isOpen]);
  return (
    <div>
      <Typography>{label}</Typography>
      <Button
        id="customized-button"
        aria-controls={isOpen ? 'customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        style={{
          width: '100%',
          minWidth: 180,
          padding: '8px',
          background: '#FFFFFF',
          color: '#6D7176',
          border: '1px solid #CFD4D9',
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          borderRadius: '8px',
        }}
        endIcon={<KeyboardArrowDownIcon />}
        onClick={toggleClick}
      >
        {listSelected?.length > 1 && `${listSelected?.length} selections`}

        {listSelected?.length === 1 && `${listSelected?.length} selection`}

        {listSelected?.length === 0 && 'Selection'}
      </Button>
      <Menu
        id="customized-menu"
        open={isOpen}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        sx={{
          '&& .Mui-selected, && .Mui-selected:hover': {
            backgroundColor: '#EEFAFE',
          },
        }}
        onClose={handleClose}
      >
        <Grid
          container
          sx={{
            pr: '5px',
            m: 1,
            minWidth: 350,
            maxWidth: 500,
            maxHeight: 388,
            overflowY: 'scroll',
          }}
        >
          <Grid item xs={12} xl={12}>
            {enableSearch && (
              <Input
                value={searchValue}
                placeholder="Search"
                onChange={(e) => setSearchValue(e.currentTarget.value)}
              />
            )}
            {dataRender?.map((data, idx) => {
              return (
                <React.Fragment key={idx}>
                  {idx === 0 && isHaveUnAssign && (
                    <>
                      <MenuItem
                        key={`${data.key}${idx}`}
                        sx={{ paddingLeft: '5px' }}
                        onClick={() =>
                          handleSelectUser({
                            value: 'unassigned',
                            label: 'unassigned',
                            key: 'unassigned',
                          })
                        }
                      >
                        <Checkbox
                          style={{
                            color:
                              listSelected?.findIndex((item) => item.label === 'unassigned') >= 0
                                ? '#4684E7'
                                : '#91979E',
                          }}
                          className="filter-cb"
                          key={idx}
                          checked={
                            listSelected?.findIndex((item) => item.label === 'unassigned') >= 0
                          }
                        />
                        Unassigned
                      </MenuItem>
                    </>
                  )}
                  {/* <Divider key={`1`} /> */}

                  {dataRender.length < 2 ? (
                    <Stack
                      paddingLeft={'10px'}
                      direction={'row'}
                      display={'flex'}
                      justifyContent={'space-between'}
                    >
                      <LinkButton variant="link" onClick={() => handleSelectAll(data)}>
                        {data.children.filter((valueFilter) => listSelected.includes(valueFilter))
                          .length === data.children.length
                          ? 'Clear all'
                          : 'Select all'}
                      </LinkButton>
                    </Stack>
                  ) : (
                    <Stack
                      paddingLeft={'10px'}
                      direction={'row'}
                      display={'flex'}
                      justifyContent={'space-between'}
                    >
                      <Typography style={{ fontWeight: '600', paddingTop: '10px' }}>
                        {data.key}
                      </Typography>
                      <LinkButton variant="link" onClick={() => handleSelectAll(data)}>
                        {data.children.filter((valueFilter) => listSelected.includes(valueFilter))
                          .length === data.children.length
                          ? 'Clear all'
                          : 'Select all'}
                      </LinkButton>
                    </Stack>
                  )}
                  {data.children.map((childrenData, idx) => {
                    if (
                      childrenData?.label?.toUpperCase().indexOf(debounceValue?.toUpperCase()) > -1
                    ) {
                      return (
                        <MenuItem
                          sx={{ paddingLeft: '5px' }}
                          key={idx}
                          onClick={() => handleSelectUser(childrenData)}
                        >
                          <Checkbox
                            key={`${childrenData.value}${idx}`}
                            style={{
                              color:
                                listSelected?.findIndex(
                                  (item) => item.value === childrenData.value
                                ) >= 0
                                  ? '#4684E7'
                                  : '#91979E',
                            }}
                            className="filter-cb"
                            checked={
                              listSelected?.findIndex(
                                (item) => item.value === childrenData.value
                              ) >= 0
                            }
                          />
                          <Tooltip
                            arrow
                            title={widthLabel[idx] > 400 ? childrenData.label : null}
                            PopperProps={{
                              sx: {
                                '& .MuiTooltip-tooltip': {
                                  maxWidth: 450,
                                },
                              },
                            }}
                          >
                            <span
                              ref={widthLabelRef?.current?.[idx]}
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {childrenData.label}
                            </span>
                          </Tooltip>
                        </MenuItem>
                      );
                    }
                  })}
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Menu>
    </div>
  );
};

type SelectProps = {
  label?: string;
  options?: SelectedValue[];
  value?: string[];
  name?: string;
  data?: DataSelectedProps;
  onChange?: (...arg: any[]) => void;
  errorMessage?: string;
  columns?: number;
  enableSearch?: boolean;
  isHaveUnAssign: boolean;
  showHiddenItem?: boolean;
};
