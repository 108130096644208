export interface MyProfile {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  middleName: any;
  fullName: any;
  username: string;
  email: string;
  gender: string;
  defaultUserType: string;
  status: string;
  phoneNumber: string;
  birthday: any;
  dependentByUserId: any;
  lastLoginDate: string;
  profile: {
    address: string;
    avatarUrl: string;
    department: string;
    jobTitle: string;
    staffId: string;
  };
  permissions: string[];
  roles: {
    userId: string;
    roleId: string;
    createdAt: string;
    updatedAt: string;
    role: {
      id: string;
      name: string;
      displayName: string;
      description: any;
    };
  }[];
}

export interface ProfilePayload {
  phoneNumber: string;
  mailingAddress: string;
  country: string;
  zipCode: string;
  city: string;
  state: string;
  careOf: string;
}

export enum LeadManagementPermission {
  Create = 'LEADS:C',
  Read = 'LEADS:R',
  Update = 'LEADS:U',
  Delete = 'LEADS:D',
}

export enum DealManagementPermission {
  Create = 'DEALS:C',
  Read = 'DEALS:R',
  Update = 'DEALS:U',
  Delete = 'DEALS:D',
}

export enum AccountManagementPermission {
  Create = 'USER_MANAGEMENT:C',
  Read = 'USER_MANAGEMENT:R',
  Update = 'USER_MANAGEMENT:U',
  Delete = 'USER_MANAGEMENT:D',
}

export enum AccountRecordPermission {
  Create = 'ACCOUNT_RECORDS:C',
  Read = 'ACCOUNT_RECORDS:R',
  Update = 'ACCOUNT_RECORDS:U',
  Delete = 'ACCOUNT_RECORDS:D',
}

export enum MemberRecordPermission {
  Create = 'MEMBER_RECORDS:C',
  Read = 'MEMBER_RECORDS:R',
  Update = 'MEMBER_RECORDS:U',
  Delete = 'MEMBER_RECORDS:D',
}

export enum SystemRoleAndPermissionPermission {
  Create = 'ROLE_AND_PERMISSION:C',
  Read = 'ROLE_AND_PERMISSION:R',
  Update = 'ROLE_AND_PERMISSION:U',
  Delete = 'ROLE_AND_PERMISSION:D',
}

export enum TeamManagementPermission {
  Create = 'TEAMS:C',
  Read = 'TEAMS:R',
  Update = 'TEAMS:U',
  Delete = 'TEAMS:D',
}

export enum ApprovalPermission {
  UpdateSaleManager = 'SALES_MANAGER:U',
  UpdateUPDSupervisor = 'UPD_SUPERVISOR:U',
  UpdateUPDManager = 'UPD_MANAGER:U',
  UpdateHeadOfSales = 'HEAD_OF_SALES:U',
  UpdateUPDOfficer = 'UPD_OFFICER:U',
  UpdateFinance = 'FINANCE_APPROVAL:U',
  UpdateMedical = 'MEDICAL_APPROVAL:U',
  UpdateSeniorSalesManager = 'SENIOR_SALES_MANAGER:U',
}
export enum SalesDashboard {
  Create = 'SALES_DASHBOARD:C',
  Read = 'SALES_DASHBOARD:R',
  Update = 'SALES_DASHBOARD:U',
  Delete = 'SALES_DASHBOARD:D',
}

export enum UPDDashboard {
  Read = 'UPD_DASHBOARD:R',
}

export enum MedicalDashboard {
  Read = 'MEDICAL_DASHBOARD:R',
}

export enum FinanceDashboard {
  Read = 'FINANCE_DASHBOARD:R',
}

export enum UnassignedRecords {
  Read = 'UNASSIGNED_RECORDS:R',
  Update = 'UNASSIGNED_RECORDS:U',
  Delete = 'UNASSIGNED_RECORDS:D',
}

export enum UserManagementERGuard {
  Create = 'USER_MANAGEMENT_ERGUARD:C',
  View = 'USER_MANAGEMENT_ERGUARD:R',
  Update = 'USER_MANAGEMENT_ERGUARD:U',
}
export enum OrderManagement {
  Read = 'ORDER_MANAGEMENT_ERGUARD:R',
  Create = 'ORDER_MANAGEMENT_ERGUARD:C',
  Update = 'ORDER_MANAGEMENT_ERGUARD:U',
  Delete = 'ORDER_MANAGEMENT_ERGUARD:D',
}

export enum BranchManagement {
  Read = 'BRANCH_MANAGEMENT_ERGUARD:R',
  Create = 'BRANCH_MANAGEMENT_ERGUARD:C',
  Update = 'BRANCH_MANAGEMENT_ERGUARD:U',
}

export enum CompanyManagement {
  Read = 'COMPANY_MANAGEMENT_ERGUARD:R',
  Create = 'COMPANY_MANAGEMENT_ERGUARD:C',
  Update = 'COMPANY_MANAGEMENT_ERGUARD:U',
}

export enum ProductManagement {
  Read = 'PRODUCT_MANAGEMENT_ERGUARD:R',
  Create = 'PRODUCT_MANAGEMENT_ERGUARD:C',
  Update = 'PRODUCT_MANAGEMENT_ERGUARD:U',
  Delete = 'PRODUCT_MANAGEMENT_ERGUARD:D',
}

export enum PaymentManagement {
  Read = 'PAYMENT_MANAGEMENT_ERGUARD:R',
  Create = 'PAYMENT_MANAGEMENT_ERGUARD:C',
  Update = 'PAYMENT_MANAGEMENT_ERGUARD:U',
}

export enum OverridePremiumPermission {
  Read = 'OVERRIDE_PREMIUM:R',
  Create = 'OVERRIDE_PREMIUM:C',
  Update = 'OVERRIDE_PREMIUM:U',
  Delete = 'OVERRIDE_PREMIUM:D',
}

export enum BillingPermission {
  Read = 'BILLING:R',
  Create = 'BILLING:C',
  Update = 'BILLING:U',
  Delete = 'BILLING:D',
}
export enum UnvoidPermission {
  Read = 'UNVOID_PERMISSION:R',
  Create = 'UNVOID_PERMISSION:C',
  Update = 'UNVOID_PERMISSION:U',
  Delete = 'UNVOID_PERMISSION:D',
}

export enum ProductsPermission {
  Read = 'CONFIGURATION_PRODUCTS:R',
  Create = 'CONFIGURATION_PRODUCTS:C',
  Update = 'CONFIGURATION_PRODUCTS:U',
  Delete = 'CONFIGURATION_PRODUCTS:D',
}

export enum BenefitsPermission {
  Read = 'CONFIGURATION_BENEFITS:R',
  Create = 'CONFIGURATION_BENEFITS:C',
  Update = 'CONFIGURATION_BENEFITS:U',
  Delete = 'CONFIGURATION_BENEFITS:D',
}

export enum MatrixPermission {
  Read = 'CONFIGURATION_MATRIX:R',
  Create = 'CONFIGURATION_MATRIX:C',
  Update = 'CONFIGURATION_MATRIX:U',
  Delete = 'CONFIGURATION_MATRIX:D',
}

export enum DashboardERGuardPermission {
  Read = 'DASHBOARD_ERGUARD:R',
}
