import avatarExam from 'src/assets/images/avatar-example.png';
import background from 'src/assets/images/background.svg';
import backgroundMedicare from 'src/assets/images/backgroundMedicare.png';
import backgroundDiamondFooter from 'src/assets/images/background_diamond_footer.png';
import backgroundDiamondSmall from 'src/assets/images/background_diamond_small.png';
import backgroundLogin from 'src/assets/images/background_login.webp';
import defaultAvatar from 'src/assets/images/default-avatar.svg';
import defaultUser from 'src/assets/images/default-user.png';
import documentView from 'src/assets/images/document-view.png';
import Illustration from 'src/assets/images/icons/Illustration.png';
import iconP from 'src/assets/images/icons/Text.svg';
import iconCalendar from 'src/assets/images/icons/calendar-clear.png';
import iconClose from 'src/assets/images/icons/close.svg';
import iconDisabled from 'src/assets/images/icons/disabled-checkbox.svg';
import iconVoidRequest from 'src/assets/images/icons/git-pull-request.svg';
import iconAlertProblem from 'src/assets/images/icons/ic_alertProblem.png';
import iconAlertProblemFill from 'src/assets/images/icons/ic_alertProblem_fill.svg';
import iconCloudUpload from 'src/assets/images/icons/ic_cloud_upload.png';
import iconComputer from 'src/assets/images/icons/ic_computer.png';
import iconCSVUpload from 'src/assets/images/icons/ic_csv_upload.png';
import iconCustomPlan from 'src/assets/images/icons/ic_custom_plan.png';
import iconDelete from 'src/assets/images/icons/ic_delete.png';
import iconDownload from 'src/assets/images/icons/ic_download.svg';
import iconImage from 'src/assets/images/icons/ic_images.png';
import iconInformationCircle from 'src/assets/images/icons/ic_information_circle.png';
import iconOpen from 'src/assets/images/icons/ic_open.png';
import iconPDF from 'src/assets/images/icons/ic_pdf.png';
import iconReceipt from 'src/assets/images/icons/ic_receipt.png';
import iconRedFlag from 'src/assets/images/icons/ic_red_flag.png';
import iconWarning from 'src/assets/images/icons/ic_warning.png';
import iconYellowFlag from 'src/assets/images/icons/ic_yellow_flag.svg';
import iconSettings from 'src/assets/images/icons/settings.svg';

import iconTrash from 'src/assets/images/icons/icon_trash.svg';
import iconDeleteGrey from 'src/assets/images/icons/icondelete.svg';
import iconUtilized from 'src/assets/images/icons/utilize.svg';
import warning from 'src/assets/images/icons/warning.svg';
import linearGradientBackground from 'src/assets/images/linear-bg-activation-form.png';
import logoCRM from 'src/assets/images/logos/LogoCRM.svg';
import banner from 'src/assets/images/logos/banner.svg';
import logo from 'src/assets/images/logos/logo-beta.svg';
import logoFull from 'src/assets/images/logos/logo-full.svg';
import logoCRMLarge from 'src/assets/images/logos/logoCRMLarge.svg';
import noNotification from 'src/assets/images/no-notification.svg';
import payment from 'src/assets/images/payment.svg';
import permission from 'src/assets/images/permission.png';

export const IMAGES = {
  banner,
  logoCRMLarge,
  logoCRM,
  logo,
  logoFull,
  background,
  backgroundMedicare,
  backgroundDiamondSmall,
  backgroundDiamondFooter,
  backgroundLogin,
  defaultUser,
  defaultAvatar,
  iconComputer,
  documentView,
  iconOpen,
  iconPDF,
  iconReceipt,
  Illustration,
  iconImage,
  payment,
  permission,
  iconCalendar,
  iconCSVUpload,
  iconCustomPlan,
  iconDelete,
  iconDownload,
  iconTrash,
  avatarExam,
  iconRedFlag,
  iconYellowFlag,
  iconAlertProblem,
  iconInformationCircle,
  iconCloudUpload,
  iconWarning,
  linearGradientBackground,
  iconUtilized,
  iconVoidRequest,
  iconClose,
  iconDisabled,
  warning,
  iconP,
  iconAlertProblemFill,
  iconDeleteGrey,
  iconSettings,
  noNotification,
};
