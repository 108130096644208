import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { createRoot } from 'react-dom/client';

import * as workbox from './workbox';
import appConfig from './appConfig';
import './scss/styles.scss';
import 'dayjs/locale/vi';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import createStore from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ONE_HOUR } from './appConfig/constants';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './appConfig/muiTheme';
import dayjs from 'dayjs';
import 'amazon-connect-streams';
import { ProSidebarProvider } from 'react-pro-sidebar';
import AppNavigator from './containers';
import CustomErrorBoundary from './components/ErrorBoundary/CustomErrorBoundary';

dayjs.extend(utc);

const { store, history } = createStore();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: ONE_HOUR,
    },
  },
});

Amplify.configure(appConfig.AWS_CONFIG);

const container = document.getElementById('root');
const root = createRoot(container!);

// const AppNavigator = React.lazy(() => import('./containers'));

root.render(
  <ProSidebarProvider>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CustomErrorBoundary showErrorMessage={true}>
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route component={AppNavigator} />
              </Switch>
            </ConnectedRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </CustomErrorBoundary>
      </Provider>
    </ThemeProvider>
  </ProSidebarProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

workbox.register();
// updateVnLocale();
