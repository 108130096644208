import React, { useRef, useState } from 'react';
import cn from 'classnames';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import './styles.scss';
import Element from '../Element';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import { Callback } from 'src/redux/types';
import dayjs from 'dayjs';
import { DateFormat } from 'src/utils/momentUtils';
import moment from 'moment';

const DateSelector: React.FC<Props> = ({
  label,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'MM/DD/YYYY',
  dateFormat = 'MM/dd/yyyy',
  required,
  name,
  onBlur,
  positionFixed = true,
  formatISODate = false,
  popperPlacement,
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  // For more information:
  // https://reactdatepicker.com/
  const handleChange = (value: Date) => {
    onChange(name, formatISODate ? value : dayjs(value).format(DateFormat));
  };
  // for manually typing in the date into datepicker
  const handleChangeRaw = (value) => {
    if (
      (value.currentTarget.value?.length === 2 || value.currentTarget.value?.length === 5) &&
      value.nativeEvent?.inputType !== 'deleteContentBackward'
    ) {
      value.currentTarget.value = value.currentTarget.value + '/';
    }
    if (
      dayjs(value.currentTarget.value).format(DateFormat) === 'Invalid Date' ||
      dayjs(value.currentTarget.value).toDate() > new Date()
    ) {
      onChange(name, '');
    }
  };
  const handleBlur = () => {
    onBlur(name, true);
  };

  const hasError = !isEmpty(errorMessage);
  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn('cmp-datepicker', containerClassName)}
      required={required}
    >
      <DatePicker
        onChangeRaw={handleChangeRaw}
        id={id.current}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholderText={placeholder}
        className={cn(
          'cmp-datepicker__input',
          { 'cmp-datepicker__input--error': hasError },
          classNames
        )}
        showPopperArrow={false}
        dateFormat={dateFormat}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperProps={{
          strategy: 'fixed',
          positionFixed: positionFixed,
        }}
        portalId="root"
        popperPlacement={popperPlacement}
        {...props}
      />
    </Element>
  );
};

type BaseDatePickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange' | 'onBlur'>
>;

type Props = BaseDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  mask?: string;
  label?: string | React.ReactNode;
  onChange: Callback;
  onBlur?: Callback;
  hasDifferentValue?: boolean;
  formatISODate?: boolean;
  positionFixed?: boolean;
  popperPlacement?: string;
};

export default DateSelector;
