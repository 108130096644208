import { Typography } from '@mui/material';
import React from 'react';
import ReceiptIcon from 'src/components/IconsCommon/ReceiptIcon';
import { MODULE_CONTENT_KEY, SUB_TITLE_NAME } from '../helpers';
import dayjs from 'dayjs';
import { PATHS } from 'src/appConfig/paths';
import { Callback } from 'src/redux/types';
import { Link as LinkCommon } from 'src/components/common';
import { Navigator } from 'src/services';
import { Link } from 'react-router-dom';

const SearchPreviewItem: React.FC<Props> = ({ data, setOpen }) => {
  const { _index, _source, _id } = data || {};

  const getData = (module) => {
    switch (module) {
      case MODULE_CONTENT_KEY.TEAM:
        return {
          id: _id,
          mainTitle: _source?.name,
          subTitle: {
            from: SUB_TITLE_NAME.TEAMS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.LEAD:
        return {
          id: _id,
          mainTitle: _source?.fullName,
          subTitle: {
            from: SUB_TITLE_NAME.LEADS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.DEAL:
        return {
          id: _id,
          mainTitle: _source?.fullName,
          subTitle: {
            from: SUB_TITLE_NAME.DEALS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.ACCOUNT:
        return {
          id: _id,
          mainTitle: _source?.accountNo,
          subTitle: {
            from: SUB_TITLE_NAME.ACCOUNTS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.MEMBER:
        return {
          id: _id,
          mainTitle: _source?.fullName,
          subTitle: {
            from: SUB_TITLE_NAME.MEMBERS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.ORDER:
        return {
          id: _id,
          mainTitle: _source.type === 'RETAIL' ? _source?.aRNumber : _source?.referenceNumber,
          subTitle: {
            from: SUB_TITLE_NAME.ER_GUARD_ORDERS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.BRANCH:
        return {
          id: _id,
          mainTitle: _source?.name,
          subTitle: {
            from: SUB_TITLE_NAME.ER_GUARD_BRANCH,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.COMPANY:
        return {
          id: _id,
          mainTitle: _source?.name,
          subTitle: {
            from: SUB_TITLE_NAME.ER_GUARD_BRANCH,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.ER_USER:
        return {
          id: _id,
          mainTitle: _source?.fullName,
          subTitle: {
            from: SUB_TITLE_NAME.ER_GUARD_USERS,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      case MODULE_CONTENT_KEY.ER_PRODUCT:
        return {
          id: _id,
          mainTitle: _source?.name,
          subTitle: {
            from: SUB_TITLE_NAME.ER_GUARD_PRODUCT,
            updatedAt: _source?.updatedAt,
            createdAt: _source?.createdAt,
          },
        };
      default:
        return null;
    }
  };
  const item = getData(_index);
  const getPathName = (module, id, type?: string, code?: string) => {
    switch (module) {
      case MODULE_CONTENT_KEY.TEAM:
        return {
          pathname: `${PATHS.admin}${PATHS.teamManagement}/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.LEAD:
        return {
          pathname: `${PATHS.sale}${PATHS.leadInbox}/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.DEAL:
        return {
          pathname: `${PATHS.sale}${PATHS.dealsManagement}/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.ACCOUNT:
        return {
          pathname: `${PATHS.accountAndMembers}${PATHS.accountRecords}/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.MEMBER:
        return {
          pathname: `${PATHS.accountAndMembers}${PATHS.memberRecords}/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.ORDER:
        return {
          pathname: `${PATHS.erGuard}${
            type === 'RETAIL' ? PATHS.retailOrder : PATHS.onlineOrder
          }/${id}`,
          state: id,
        };
      case MODULE_CONTENT_KEY.BRANCH:
        return {
          pathname: `${PATHS.erGuard}${PATHS.branchManagement}?uuid=${id}`,
          state: null,
        };
      case MODULE_CONTENT_KEY.COMPANY:
        return {
          pathname: `${PATHS.erGuard}${PATHS.companyManagement}?uuid=${id}`,
          state: null,
        };
      case MODULE_CONTENT_KEY.ER_USER:
        return {
          pathname: `${PATHS.erGuard}${PATHS.eRGuardUserManagement}?uuid=${id}`,
          state: null,
        };
      case MODULE_CONTENT_KEY.ER_PRODUCT:
        return {
          pathname: `${PATHS.erGuard}${PATHS.productManagement}?uuid=${code}`,
          state: null,
        };
      default:
        return null;
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    setOpen(false);
    Navigator.navigate(getPathName(_index, item.id, _source.type, _source.code).pathname);
  };
  return (
    <>
      {getPathName(_index, item.id, _source.type, _source.code).state ? (
        <Link
          onClick={() => {
            setOpen(false);
          }}
          to={getPathName(_index, item.id, _source.type, _source.code)}
        >
          <div role="button" tabIndex={0} className="card-search">
            <ReceiptIcon />
            <div className="content-des">
              <Typography fontSize={14} fontWeight={400}>
                {item?.mainTitle}
              </Typography>
              <div className="des">{`${item?.subTitle?.from} - ${
                item?.subTitle?.updatedAt
                  ? `Updated ${dayjs(item?.subTitle?.updatedAt).fromNow()}`
                  : `Created ${dayjs(item?.subTitle?.createdAt).fromNow()}`
              }`}</div>
            </div>
          </div>
        </Link>
      ) : (
        <LinkCommon
          href={getPathName(_index, item.id, _source.type, _source.code).pathname}
          onClick={handleClick}
          style={{ textDecoration: 'none' }}
        >
          <div role="button" tabIndex={0} className="card-search">
            <ReceiptIcon />
            <div className="content-des">
              <Typography fontSize={14} fontWeight={400}>
                {item?.mainTitle}
              </Typography>
              <div className="des">{`${item?.subTitle?.from} - ${
                item?.subTitle?.updatedAt
                  ? `Updated ${dayjs(item?.subTitle?.updatedAt).fromNow()}`
                  : `Created ${dayjs(item?.subTitle?.createdAt).fromNow()}`
              }`}</div>
            </div>
          </div>
        </LinkCommon>
      )}
    </>
  );
};
type Props = {
  data: any;
  setOpen: Callback;
};
export default SearchPreviewItem;
