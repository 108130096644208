import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Content, ContentStore } from './types';

export interface ICommonState {
  loading: boolean;
  screenWidth: number;
  collapseSidebar: boolean;
  showMiniSidebar: boolean;
  showSidebar: boolean;
  showNavbar: boolean;
  contents: ContentStore;
  assignAgentList: Content;
  salesManagerList: Content;
  selectedLink: string;
  selectedLinkName: string;
}

const initialState: ICommonState = {
  loading: false,
  screenWidth: 0,
  collapseSidebar: false,
  showMiniSidebar: false,
  showSidebar: false,
  showNavbar: true,
  contents: {
    cities: undefined,
    provinces: undefined,
    regions: undefined,
    roleSaleAgentId: '',
    roleSaleManagerId: '',
  },
  assignAgentList: {
    data: [],
    total: 0,
  },
  salesManagerList: {
    data: [],
    total: 0,
  },
  selectedLink: '',
  selectedLinkName: '',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setScreenWidth: (state, action: PayloadAction<number>) => {
      state.screenWidth = action.payload;
    },
    setContents: (state, action: PayloadAction<ContentStore>) => {
      state.contents = action.payload;
    },
    setAssignAgentList: (state, action: PayloadAction<Content>) => {
      state.assignAgentList = action.payload;
    },
    setSalesManagerList: (state, action: PayloadAction<Content>) => {
      state.salesManagerList = action.payload;
    },
    setCollapseSidebar: (state, action: PayloadAction<boolean>) => {
      state.collapseSidebar = action.payload;
    },
    setShowMiniSidebar: (state, action: PayloadAction<boolean>) => {
      state.showMiniSidebar = action.payload;
    },
    setShowNavbar: (state, action: PayloadAction<boolean>) => {
      state.showNavbar = action.payload;
    },
    setShowSidebar: (state, action: PayloadAction<boolean>) => {
      state.showSidebar = action.payload;
    },
    setSelectedLink: (state, action: PayloadAction<string>) => {
      state.selectedLink = action.payload;
    },
    setSelectedLinkName: (state, action: PayloadAction<string>) => {
      state.selectedLinkName = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setScreenWidth,
  setCollapseSidebar,
  setShowMiniSidebar,
  setShowNavbar,
  setShowSidebar,
  setContents,
  setSalesManagerList,
  setAssignAgentList,
  setSelectedLink,
  setSelectedLinkName,
} = commonSlice.actions;

export const commonState = commonSlice.getInitialState();

export default commonSlice.reducer;
