export const isFamilyIndividual = (account) => {
  return (
    account?.planType === 'FAMILY' ||
    account?.planType === 'INDIVIDUAL' ||
    (account?.planType === 'CORPORATE' && !account?.deal?.originalDeal)
  );
};

export const isPNPSecurityGuard = (account) => {
  return (
    account?.planType === 'SECURITY_GUARD' || account?.planType === 'PHILIPPINES_NATIONAL_POLICE'
  );
};

export const convertAccountNo = (value) => {
  let result = '';
  if (value < 100000) {
    const length = String(value).length;
    for (let i = 0; i < 5 - length; i++) {
      result = '0' + result;
    }
    return result + value;
  }

  return String(value);
};

export const getNextYear = (value: Date) => {
  return new Date(value).setFullYear(new Date(value).getFullYear() + 1);
};

export const getFileType = (fileType: string) => {
  switch (fileType) {
    case '.xls' || '.xlsx':
      return 'application/vnd.ms-excel';
    case '.doc' || '.docx':
      return 'application/msword';
    case '.png' || '.jpg' || 'jpeg' || '.gif':
      return 'image/png';
    case '.pdf':
      return 'application/pdf';
    case '.ppt' || '.pptx':
      return 'application/vnd.ms-powerpoint';
  }
};
