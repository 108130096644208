import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatPhoneNumber } from 'src/utils';
import { TEAM_SEARCH_KEY } from './helpers';
import dayjs from 'dayjs';
import { PATHS } from 'src/appConfig/paths';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: TEAM_SEARCH_KEY.TEAM_NAME,
    label: 'Team Name',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <Link
            to={{
              pathname: `${PATHS.admin}${PATHS.teamManagement}/${rowData.id}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? ''}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: TEAM_SEARCH_KEY.DESCRIPTION,
    label: 'Description',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ?? ''}</div>;
      },
    },
  },
  {
    name: TEAM_SEARCH_KEY.CREATED_DATE,
    label: 'Created Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
];
