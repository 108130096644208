import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { COLOR_CODE } from 'src/appConfig/constants';
import { PATHS } from 'src/appConfig/paths';
import Notification from 'src/containers/Notification';
import UserProfile from 'src/containers/UserProfile';
import { useProfile } from 'src/queries';
import { IRootState } from 'src/redux/rootReducer';
import GlobalSearch from './GlobalSearch';

export const CustomAppBar: React.FC<Props> = ({ children }) => {
  const { selectedLinkName } = useSelector((state: IRootState) => state.common);
  const location = useLocation();
  const { profile } = useProfile();
  const { fullName } = profile || {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        id="navbar"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          // onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box p={2}>
          <Typography variant="h2" fontWeight={'bold'} color={COLOR_CODE.PRIMARY}>
            {location.pathname.includes(PATHS.notifications)
              ? 'Notifications'
              : location.pathname.includes(PATHS.systemSearch)
              ? 'System Search'
              : location.pathname === PATHS.myProfile
              ? 'My Profile'
              : selectedLinkName}
          </Typography>
        </Box>

        <Stack flexDirection={'row'} sx={{ alignItems: 'center' }}>
          <GlobalSearch />
          <Notification />
          <ListItem>
            <ListItemAvatar>
              <UserProfile />
            </ListItemAvatar>
            <ListItemText primary={fullName || 'Anonymous'} />
          </ListItem>
        </Stack>
      </div>
      {children}
    </Box>
  );
};
type Props = { children?: any };

export default CustomAppBar;
