import React, { ReactElement, useCallback } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Callback } from 'src/redux/types';
import { emptyFunction, MoneyInputDetect } from 'src/utils';
import { Input } from '..';
import { InputIcon, InputProps } from '../Input';
import { isEmpty } from 'src/validations';
import { Typography } from '@mui/material';

const DollarInputIcon = (
  <InputIcon
    iconName={'ic_dollar'}
    isIconPositionLeft={true}
    iconComponent={null}
    onIconClick={emptyFunction}
  />
);

const InputCurrency: React.FC<Props> = ({
  unFixedDecimalScale = false,
  name,
  value,
  onChange,
  icon = DollarInputIcon,
  label = '',
  required = false,
  disabled = false,
  ...props
}) => {
  const handleChange = (values) => {
    
    const { floatValue } = values;
    const returnValue = floatValue ? floatValue : floatValue === 0 ? 0 : null;
    onChange(name, returnValue);
  };
  const customInput = useCallback(
    ({ ...props }) => <Input customIcon={icon} iconPosition="left" {...props} />,
    []
  );

  return (
    <>
      {!isEmpty(label) && (
        <Typography style={{ marginBottom: '4px' }}>
          {label} {required && <span style={{ display: 'inline-block', color: '#EE2D2D' }}>*</span>}
        </Typography>
      )}

      <CurrencyFormat
        customInput={customInput}
        thousandSeparator
        fixedDecimalScale={!unFixedDecimalScale}
        decimalScale={2}
        displayType="input"
        onValueChange={handleChange}
        {...props}
        name={name}
        value={typeof value === 'string' ? !Number(value) ? '':  value: MoneyInputDetect(value)}
        disabled={disabled}
      />
    </>
  );
};
type Props = Omit<CurrencyFormat.Props, 'InputProps'> & { InputProps: InputProps } & {
  unFixedDecimalScale?: boolean;
  value: string | number;
  name: string;
  onChange: Callback;
  icon?: ReactElement;
  label?: string;
  required?: boolean;
  disabled?: boolean;
};

export default InputCurrency;
