import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { Image, Text, View } from 'src/components/common';
import { IRootState } from 'src/redux/store';

const Empty: React.FC<Props> = ({ title = '', src = `${IMAGES.Illustration}` }) => {
  return (
    <View
      flexGrow={1}
      justify="center"
      align="center"
      style={{
        padding: '1rem 0',
      }}
    >
      <Image
        style={{
          maxWidth: '23rem',
        }}
        src={src}
      />
      <Text color="text-color-primary-700 text-align-center " className="text-empty ">
        {title}
      </Text>
    </View>
  );
};
type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    title?: string;
    src?: string;
  };

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Empty);
