import { BenefitCategory, BenefitItem } from 'src/queries/Benefits/types';

export enum BENEFITS_LABEL {
  BENEFIT_CATEGORY_CODE = 'Benefit Category Code',
  CATEGORY = 'Category',
  BENEFIT_ITEM_CODE = 'Benefit Item Code',
  BENEFIT_ITEM_DESCRIPTION = 'Benefit Item Description',
}
export enum BENEFITS_KEY {
  BENEFIT_CATEGORY_CODE = 'categoryCode',
  CATEGORY = 'category',
  BENEFIT_ITEM_CODE = 'code',
  BENEFIT_ITEM_DESCRIPTION = 'description',
}
export const categoryOptions = [
  { index: 0, value: 'MEMBER_BENEFIT_CLASSIFICATION', label: 'Member Benefit Classification' },
  { index: 1, value: 'IN_PATIENT_BENEFITS', label: 'In-Patient (IP) Benefits' },
  { index: 2, value: 'OUT_PATIENT_BENEFITS', label: 'Out-Patient (OP) Benefits' },
];
export enum CUSTOM_FILTER_CATEGORY {
  CATEGORIES = 'categories',
}
export type CustomFilterBenefits = {
  categories: string[];
};
export const FILTER_BENEFITS_INDEX = {
  CATEGORIES: 0,
  length: 1,
};
export enum PREFIX_FILTER_BENEFITS {
  CATEGORIES = 'Category: ',
}
export const QUERY_KEY = {
  filter: 'filter',
};
export const objectToOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: String(x.code),
        key: x.code,
        label: x.name,
      }))) ||
    []
  );
};

export const convertToCategoryList = (items: BenefitCategory[]) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: String(x.code),
        key: x.code,
        label: `${x.code}. ${x.name}`,
      }))) ||
    []
  );
};

export const convertToBenefitItem = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: String(x.code),
        key: x.code,
        label: `${x.code} ${x.description}`,
      }))) ||
    []
  );
};

export const headerForCSVBenefit = [
  { label: 'Benefit Category Code', key: 'categoryCode' },
  { label: 'Category', key: 'category' },
  { label: 'Benefit Item Code', key: 'itemCode' },
  { label: 'Benefit Item Description', key: 'description' },
];

export const formatToStringCSV = (data) => {
  if (typeof data === 'string') return data;
  return `'${data}`;
};

export enum UPDATE_NOTIFICATION_KEY {
  SEE_UNREAD_NOTIFICATIONS = 'SEE_UNREAD_NOTIFICATIONS',
  SEE_ALL_NOTIFICATIONS = 'SEE_ALL_NOTIFICATIONS',
  MARK_ALL_NOTIFICATIONS = 'MARK_ALL_NOTIFICATIONS',
}
