import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ERGuardActivationForm from './ERGuardActivationForm';
import appConfig from 'src/appConfig';

const ERGuardActivationFormContainer = () => {
  return (
    <GoogleReCaptchaProvider
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'body',
        nonce: undefined,
      }}
      reCaptchaKey={appConfig.SITE_KEY}
    >
      <ERGuardActivationForm />
    </GoogleReCaptchaProvider>
  );
};

export default ERGuardActivationFormContainer;
