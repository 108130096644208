//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const ErrorIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg width="22" height="22" viewBox="0 0 22 22" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path d="M10.083 13.7502H11.9163V15.5835H10.083V13.7502ZM10.083 6.41683H11.9163V11.9168H10.083V6.41683ZM10.9905 1.8335C5.93051 1.8335 1.83301 5.94016 1.83301 11.0002C1.83301 16.0602 5.93051 20.1668 10.9905 20.1668C16.0597 20.1668 20.1663 16.0602 20.1663 11.0002C20.1663 5.94016 16.0597 1.8335 10.9905 1.8335ZM10.9997 18.3335C6.94801 18.3335 3.66634 15.0518 3.66634 11.0002C3.66634 6.9485 6.94801 3.66683 10.9997 3.66683C15.0513 3.66683 18.333 6.9485 18.333 11.0002C18.333 15.0518 15.0513 18.3335 10.9997 18.3335Z"
          fill={color} />
      </svg>

    </Box>

  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default ErrorIcon;
