import { isEmpty } from 'lodash';

export enum GOVERNMENT_ID_TYPE_KEY {
  GOVERNMENT_ID_TYPE = 'governmentIdType',
  GOVERNMENT_ID_NUMBER = 'idNumber',
}

export enum GOVERNMENT_ID_TYPE_LABEL {
  GOVERNMENT_ID_TYPE = 'Government ID Type',
  GOVERNMENT_ID_NUMBER = 'ID Number',
}
export interface Government {
  id: string;
  governmentIdType: string;
  idNumber: string;
}
export const GOVERNMENT_LIST = [
  { value: 'BIRTH_CERTIFICATE', label: 'Birth Certificate', key: 'BIRTH_CERTIFICATE' },
  {
    value: 'PHILIPPINE_NATIONAL_ID',
    label: 'Philippine National ID',
    key: 'PHILIPPINE_NATIONAL_ID',
  },
  {
    value: 'UNIFIED_MULTI_PURPOSE_ID',
    label: 'Unified Multi-Purpose ID (UMID)',
    key: 'UNIFIED_MULTI_PURPOSE_ID',
  },
  { value: 'PASSPORT', label: 'Passport', key: 'PASSPORT' },
  { value: 'DRIVER_LICENSE', label: 'Driver’s License', key: 'DRIVER_LICENSE' },
  {
    value: 'PRC_OR_IBP_ID',
    label: 'PRC/Integrated Bard of the Philippines (IBP) ID',
    key: 'PRC_OR_IBP_ID',
  },
  {
    value: 'SSS_GSIS_TIN_PHILHEALTH_CARD',
    label: 'SSS/GSIS/TIN/PhilHealth card',
    key: 'SSS_GSIS_TIN_PHILHEALTH_CARD',
  },
  { value: 'OFW_OWWA_ID', label: 'OFW/OWWA ID', key: 'OFW_OWWA_ID' },
  { value: 'NBI_CLEARENCE', label: 'NBI clearance ', key: 'NBI_CLEARENCE' },
  { value: 'POSTAL_ID', label: 'Postal ID', key: 'POSTAL_ID' },
  {
    value: 'OSCA_OR_SENIOR_CITIZEN',
    label: 'OSCA ID or Senior Citizen’s card',
    key: 'OSCA_OR_SENIOR_CITIZEN',
  },
];

export const objectToAddressOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: x,
        key: x?.id,
        label: x.name,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
export const objectToProvinceOptions = (items) => {
  return (
    (items &&
      items.map &&
      items.map((x) => ({
        value: isEmpty(x) ? { name: 'Invalid', id: 'Invalid' } : x,
        key: x?.id,
        label: x?.isDuplicateName === null ? x?.name : `${x?.name} (${x?.region?.name})`,
        // code: x.code || '',
        // searchField: x.searchField || '',
      }))) ||
    []
  );
};
