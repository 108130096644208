import { Box } from '@mui/material';
import React from 'react';

const SignOutIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H12.5V5.3125C12.5 4.0625 11.1801 3.125 10 3.125H4.0625C3.48253 3.12562 2.92649 3.35629 2.51639 3.76639C2.10629 4.17649 1.87562 4.73253 1.875 5.3125V14.6875C1.87562 15.2675 2.10629 15.8235 2.51639 16.2336C2.92649 16.6437 3.48253 16.8744 4.0625 16.875H10.3125C10.8925 16.8744 11.4485 16.6437 11.8586 16.2336C12.2687 15.8235 12.4994 15.2675 12.5 14.6875V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10Z" fill="#6D7176" />
        <path d="M17.9418 9.5586L14.8168 6.43361C14.6986 6.32134 14.5413 6.25968 14.3783 6.26177C14.2153 6.26386 14.0596 6.32953 13.9444 6.44478C13.8291 6.56003 13.7635 6.71574 13.7614 6.87872C13.7593 7.04169 13.8209 7.19904 13.9332 7.3172L15.991 9.3754H12.5V10.6254H15.991L13.9332 12.6836C13.8727 12.7411 13.8244 12.81 13.791 12.8865C13.7576 12.9629 13.7398 13.0453 13.7387 13.1287C13.7377 13.2121 13.7533 13.2949 13.7847 13.3722C13.8162 13.4495 13.8628 13.5197 13.9217 13.5786C13.9807 13.6376 14.0509 13.6842 14.1282 13.7157C14.2055 13.7471 14.2883 13.7627 14.3717 13.7617C14.4551 13.7606 14.5375 13.7428 14.6139 13.7094C14.6904 13.676 14.7593 13.6277 14.8168 13.5672L17.9418 10.4422C18.0589 10.325 18.1247 10.1661 18.1247 10.0004C18.1247 9.83471 18.0589 9.6758 17.9418 9.5586Z" fill="#6D7176" />
      </svg>

    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default SignOutIcon;
