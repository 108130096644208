import { createTheme } from '@mui/material/styles';
import { COLOR_CODE } from './constants';
const defaultTheme = createTheme();

const { breakpoints } = defaultTheme;

const configTheme = createTheme({
  palette: {
    primary: {
      main: COLOR_CODE.BACKGROUND,
      dark: COLOR_CODE.PRIMARY_DARK,
      light: COLOR_CODE.PRIMARY_LIGHT,
    },
    secondary: {
      main: COLOR_CODE.SECONDARY,
    },
    grey: {
      '50': COLOR_CODE.PRIMARY_50,
      '100': COLOR_CODE.PRIMARY_100,
      '200': COLOR_CODE.PRIMARY_200,
      '300': COLOR_CODE.PRIMARY_300,
      '400': COLOR_CODE.PRIMARY_400,
      '500': COLOR_CODE.PRIMARY_500,
      '600': COLOR_CODE.PRIMARY_600,
      '700': COLOR_CODE.PRIMARY_700,
      '800': COLOR_CODE.PRIMARY_800,
      '900': COLOR_CODE.PRIMARY_900,
    },
    success: {
      main: COLOR_CODE.SUCCESS,
    },
    warning: {
      main: COLOR_CODE.WARNING,
    },
    error: {
      main: COLOR_CODE.DANGER,
    },
    info: {
      main: COLOR_CODE.INFO,
    },
    text: {
      primary: COLOR_CODE.PRIMARY,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'md',
      },
      styleOverrides: {
        maxWidthMd: {
          maxWidth: '960px !important',
          '@media (min-width): 900px': {
            maxWidth: '960px !important',
          },
        },
        maxWidthXl: {
          maxWidth: '100% !important',
          '@media (min-width): 1536px': {
            maxWidth: '100% !important',
          },
        },
      },
      // styleOverrides: {
      //   maxWidthMd: {
      //     maxWidth: '960px !important',
      //     '@media (min-width): 900px': {
      //       maxWidth: '960px !important',
      //     },
      //   },
      // },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#484C4F',
          fontSize: 14,
          padding: '8px 12px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: 32,
          fontWeight: 'bold',
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 32,
          },
        },
        h2: {
          fontSize: 26,
          fontWeight: 'bold',
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 26,
          },
        },
        h3: {
          fontSize: 20,
          fontWeight: 'bold',
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 20,
          },
        },
        h4: {
          fontSize: 18,
          fontWeight: 'bold',
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 18,
          },
        },
        h5: {
          fontSize: 16,
          fontWeight: 'bold',
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 16,
          },
        },
        body1: {
          '&.Mui-selected': {
            color: 'white',
          },
          fontSize: 14,
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 16,
          },
        },
        body2: {
          fontSize: 14,
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 14,
          },
        },
        subtitle1: {
          // type: small in Figma
          fontSize: 12,
          color: COLOR_CODE.PRIMARY,
          [breakpoints.down('md')]: {
            fontSize: 12,
          },
        },
      },
    },

    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            color: COLOR_CODE.INFO,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          '&.MuiTableCell-root': {
            backgroundColor: '#F8F8F9',
          },
        },
      },
    },
    // MuiButtonBase: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selected': {
    //         color: '#4684E7'
    //       }
    //     }
    //   }
    // },
    // MuiTab: {
    //   styleOverrides: {
    //     root: {
    //       '&.Mui-selectd': {
    //         color: '#4684E7',
    //       }
    //     }
    //   }
    // },
    // .css-1f8u0b8-MuiButtonBase-root-MuiTab-root
    // MuiButtonBase-root-MuiButton-root.Mui-disabled
    // MuiInputBase-root-MuiOutlinedInput-root
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            // color: '#4684E7',
            // border: '1px solid #4684E7',
            // fontWeight: '500',
            // fontSize: '16px',
            '&.Mui-disabled': {
              opacity: '0.8',
              color: '#72A8F0',
              border: '1px solid #72A8F0',
            },
          },
          '&.MuiTab-root': {
            fontSize: '16px',
            '&.Mui-selected': {
              color: '#4684E7',
            },
          },
          '&.MuiListItemButton-root': {
            '&:hover': {
              borderRadius: '8px',
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: 56,
          '&.Mui-selected': {
            backgroundColor: COLOR_CODE.SECONDARY,
            color: 'white',
            borderRadius: '8px',
            '& .MuiListItemIcon-root': {
              color: 'white',
            },
            '& .MuiTypography-root': {
              color: 'white',
            },
            '&:hover': {
              backgroundColor: COLOR_CODE.SECONDARY,
              color: COLOR_CODE.INFO,
            },
            // '& .MuiListItemText-root': {
            //   color: 'white',
            // },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '2rem',
          background: '#FFFFFF',
          boxShadow: '0px 4px 20px rgba(146, 146, 146, 0.1)',
          borderRadius: '10px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '24px 24px 0 24px',
          // overflowY: 'unset',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiDialog-paper': {
            boxShadow: '0px 0px 2px rgb(0 0 0 / 10%), 2px 2px 14px rgb(67 71 72 / 35%)',
            borderRadius: '1rem',
          },
        },
      },
    },
  },
});

// export const theme = responsiveFontSizes(configTheme);
export const theme = configTheme;
