import { TableParams } from 'src/redux/types';

export enum TEAM_SEARCH_KEY {
  TEAM_NAME = 'name',
  DESCRIPTION = 'description',
  NUMBER_OF_MEMBERS = 'numberMembers',
  CREATED_DATE = 'createdDate',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
