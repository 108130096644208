//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const NotificationIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color = '#6D7176',
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6286 15.9989C20.5508 15.9052 20.4744 15.8114 20.3994 15.7209C19.3682 14.4736 18.7443 13.7208 18.7443 10.1897C18.7443 8.36156 18.3069 6.86156 17.4449 5.73656C16.8093 4.90547 15.9501 4.275 14.8176 3.80906C14.803 3.80096 14.79 3.79032 14.7791 3.77766C14.3718 2.41359 13.2571 1.5 11.9999 1.5C10.7427 1.5 9.62849 2.41359 9.22115 3.77625C9.21027 3.78845 9.19744 3.79875 9.18318 3.80672C6.54036 4.89469 5.25599 6.98203 5.25599 10.1883C5.25599 13.7208 4.63302 14.4736 3.60083 15.7195C3.52583 15.81 3.44943 15.9019 3.37161 15.9975C3.17061 16.2399 3.04326 16.5348 3.00464 16.8473C2.96601 17.1598 3.01772 17.4769 3.15365 17.7609C3.44286 18.3703 4.05927 18.7486 4.76286 18.7486H19.2421C19.9424 18.7486 20.5546 18.3708 20.8447 17.7642C20.9813 17.4801 21.0335 17.1628 20.9952 16.8499C20.9569 16.537 20.8297 16.2417 20.6286 15.9989Z"
          fill={color}
        />
        <path
          d="M12.0028 22.5C12.6802 22.4994 13.3448 22.3156 13.9261 21.9679C14.5074 21.6202 14.9838 21.1217 15.3047 20.5252C15.3198 20.4966 15.3273 20.4646 15.3264 20.4322C15.3255 20.3999 15.3162 20.3684 15.2995 20.3407C15.2828 20.313 15.2593 20.2901 15.2311 20.2742C15.2029 20.2583 15.1711 20.25 15.1388 20.25H8.86785C8.83547 20.2499 8.80361 20.2582 8.77538 20.274C8.74715 20.2899 8.7235 20.3128 8.70675 20.3405C8.68999 20.3682 8.68069 20.3997 8.67976 20.4321C8.67883 20.4645 8.6863 20.4965 8.70144 20.5252C9.02231 21.1216 9.49862 21.6201 10.0798 21.9678C10.6611 22.3155 11.3256 22.4994 12.0028 22.5Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default NotificationIcon;
