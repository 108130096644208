import * as React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel, Stack } from '@mui/material';

export const SwitchFilter: React.FC<SwitchProps> = ({
  label,
  checked,
  onChange,
  name,
  direction = 'column',
  color = 'secondary',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked) => {
    onChange?.(name, checked);
  };

  return (
    <Stack
      direction={direction}
      spacing={1}
      sx={{
        display: 'flex',
        justifyContent: direction === 'column' ? 'flex-start' : 'flex-start',
        alignItems: direction === 'column' ? 'flex-start' : 'center',
      }}
    >
      <div className={'heading__h5'} style={{ marginRight: '12px' }}>
        {label}
      </div>
      <FormControlLabel
        value="start"
        name={name}
        control={
          <div>
            <Switch
              color={color}
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: '#4684E7',
                },
                '& .MuiSwitch-track': {
                  backgroundColor: '#90C0F7',
                },

                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#90C0F7',
                  },
                },
              }}
            />
          </div>
        }
        labelPlacement="start"
        label={
          checked ? (
            <div className={'heading__h6 black'}>ON</div>
          ) : (
            <div className={'heading__h6 black'}>OFF</div>
          )
        }
      />
    </Stack>
  );
};

type SwitchProps = {
  label?: string;
  checked?: any;
  name?: string;
  onChange?: (...arg: any[]) => void;
  columns?: number;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
};
