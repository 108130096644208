export const PATHS = {
  root: '/',
  sale: '/sale',
  accountAndMembers: '/account-and-member',
  admin: '/admin',
  erGuard: '/er-guard',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  signUp: '/sign-up',
  newPasswordChange: '/new-password-change',
  forgotPassword: '/forgot-password',
  myAccount: '/my-accounts',
  dashboard: '/dashboard',
  uPDDashboard: '/upd-dashboard',
  medDashboard: '/medical-dashboard',
  financeDashboard: '/finance-dashboard',
  saleDashboard: '/sale-dashboard',
  resetPassword: '/reset-password',
  verificationCode: '/verification-code',
  congratulation: '/congratulation',
  passwordUpdated: '/password-updated',
  noPermission: '/no-permission',
  logout: '/logout',
  configuration: '/configuration',
  userListing: '/users-listing',
  // User Permissions
  userPermissions: '/user-permissions',

  // Team Management

  userRole: '/user-permissions/user-role',

  // Team Permissions
  teamManagement: '/team-management',

  // ======== Profile ========
  myProfile: '/me',
  changePassword: '/me/change-password',
  editMyProfile: '/me/edit',
  configureNotification: '/me/notification',

  // Users Managements
  userManagements: '/user-managements',
  addUser: '/user-managements/add',
  userDetail: '/user-managements/user-detail',

  // Lead Inbox
  leadInbox: '/lead-inbox',
  // Lead Inbox Details

  // Deal Management
  dealsManagement: '/deals-management',

  interestForm: '/interest-form',
  activationFormOnlineShop: '/activation-form',

  //Deals Inbox

  //Sale agent
  saleAgent: '/saleAgent',

  // Deals inbox
  // dealsInbox: '/deals-inbox',

  // Pipeline Stage
  pipelineStage: '/pipeline-stage',

  // SAF Review
  safReview: '/saf-review',

  // Sale Routing Slip
  saleRoutingSlip: '/sale-routing-slip',

  // Call Activity
  callActivity: '/call-activity',

  // Call Center
  callManagement: '/call-management',

  // Reports
  reports: '/reports',

  // Settings
  settings: '/settings',

  // ER Guard Router
  erGuardDashBoard: '/er-guard-dashboard',
  orderManagement: '/order-management',
  retailManagement: '/retail-management',
  companyManagement: '/company-management',
  branchManagement: '/branch-management',
  productManagement: '/product-management',
  eRGuardUserManagement: '/user-management',
  paymentManagement: '/payment-management',
  retailOrderManagement: '/payment-management/1234',
  onlineOrder: '/online-order',
  retailOrder: '/retail-order',
  voidRequest: '/void-request',
  createOrder: '/create-order',
  paymentRequest: '/payment-request',
  //Account and member
  accountRecords: '/account-records',
  memberRecords: '/member-records',

  //payment
  onlineOrderPayment: '/online-order-payment',
  retailOrderPayment: '/retail-order-payment',
  retailOrderPaymentRequest: '/retail-order-payment-request',

  //Configuration
  products: '/products',
  benefits: '/benefits',
  matrix: '/matrix',

  //Notifications
  notifications: '/notifications',

  //System Search
  systemSearch: '/system-search',
};

export const PATH_HEADERS = {
  [PATHS.myProfile]: 'My Profile',
  [PATHS.editMyProfile]: 'My Profile',
  [PATHS.changePassword]: 'Change Password', // pragma: allowlist secret
};

export const HIDE_NAV_PATHS = [];
