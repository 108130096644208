import './styles.scss';
import { useEffect, useState } from 'react';
import { Pagination, TablePagination } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

export enum KanbanQueryParams {
  SEARCH = 'search',
  ROWS_PER_PAGE = 'rowsPerPage',
  PAGE = 'page',
  FILTER = 'filter',
}

const Footer: React.FC<Props> = ({ currPage, currRowPerPage, totalItems, sendDataToParent }) => {
  const [page, setPage] = useState(currPage);
  const [rowsPerPage, setRowsPerPage] = useState(currRowPerPage);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const handlePageChange = (event, newPage) => {};

  const handleClearPagination = () => {
    query.delete(KanbanQueryParams.PAGE);
    query.delete(KanbanQueryParams.ROWS_PER_PAGE);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleClearPagination();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    sendDataToParent(1, event.target.value);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    curPage
  ) => {
    handleClearPagination();
    setPage(curPage);
    sendDataToParent(curPage, rowsPerPage);
  };

  useEffect(() => {
    if (currPage && currRowPerPage) {
      setPage(currPage);
      setRowsPerPage(currRowPerPage);
    }
  }, [currPage, currRowPerPage]);

  return (
    <div className="kanban-footer">
      <div className="left">
        <span className="showing">{`Showing ${(page - 1) * rowsPerPage + 1} to ${
          page * rowsPerPage > totalItems ? totalItems : page * rowsPerPage
        } of ${totalItems} items`}</span>
      </div>
      <div className="right">
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelDisplayedRows={() => ''}
          backIconButtonProps={{ style: { display: 'none' } }}
          nextIconButtonProps={{ style: { display: 'none' } }}
        />
        <Pagination
          count={Math.ceil(totalItems / rowsPerPage)}
          shape="rounded"
          onChange={handlePaginationChange}
          page={page}
          sx={{
            ul: {
              '& li .Mui-selected': {
                color: 'white',
                backgroundColor: '#4684E7',
              },
            },
          }}
        />
      </div>
    </div>
  );
};

type Props = {
  totalItems: number;
  sendDataToParent?: any;
  currPage: number;
  currRowPerPage: number;
};

export default Footer;
