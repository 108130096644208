import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useGetSalesAgentList(
  options?: UseQueryOptions<ApiResponseType<any>, Error, any> & {
    roles?: string;
  }
) {
  const {
    data: listSalesAgent,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetSelectedList,
  } = useQuery<ApiResponseType<any>, Error, any>(
    [
      API_QUERIES.SALES_AGENT_LIST,
      {
        roles: options.roles,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<any>>(apiClient.getSalesAgentList, params);
      },
      select(data) {
        return data.data;
      },
      staleTime: 1000,
      enabled: !isEmpty(options.roles),
      ...options,
    }
  );
  const queryClient = useQueryClient();

  const handleInvalidateSaleAssignList = () =>
    queryClient.invalidateQueries(API_QUERIES.ASSIGN_AGENT);
  return {
    listSalesAgent,
    error,
    isError,
    isLoading,
    onGetSelectedList,
    handleInvalidateSaleAssignList,
  };
}
