import { useEffect, useRef, useState } from 'react';
import { onMessageListener } from 'src/firebase';

import './styles.scss';
import { IconButton, Switch, Tooltip, Typography } from '@mui/material';
import NotificationIcon from 'src/components/IconsCommon/NotificationIcon';
import OpenIcon from 'src/components/IconsCommon/OpenIcon';
import { Image, LoadingCommon } from 'src/components/common';
import { IMAGES } from 'src/appConfig/images';
import { useGetNotification } from 'src/queries/Notification/useGetNotification';
import NotificationDetail from './NotificationDetail';
import { useMarkReadAllNotifications } from 'src/queries/Notification/useMarkReadAllNotifications';
import { Navigator } from 'src/services';
import { PATHS } from 'src/appConfig/paths';

const Notification = () => {
  const [isUnread, setIsUnread] = useState(false);
  const [isShowNotificationIcon, setIsShowNotificationIcon] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const wrapperRef = useRef(null);
  const notiRef = useRef<() => Promise<void>>();

  const { setParams, onGetNotifications, totalItems } = useGetNotification({
    onSuccess(data) {
      setNotifications(data.data.data);
      setIsLoading(false);
    },
  });

  const { markAllNotifications } = useMarkReadAllNotifications({
    onSuccess() {
      onGetNotifications();
    },
  });

  useEffect(() => {
    onMessageListener().then((data) => {
      if (data.notification.title) {
        setIsShowNotificationIcon(true);
        onGetNotifications();
      }
    });
  });

  const handleToggleNotification = () => {
    setIsShowNotificationIcon(false);
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleSwitchReadStatus = () => {
    setParams({
      take: 10,
      skip: 0,
      unReadOnly: !isUnread ? String(!isUnread) : null,
    });
    setPage(1);
    setIsUnread(!isUnread);
  };

  const handlemarkAllNotifications = () => {
    markAllNotifications();
  };

  const onChangeNotificationList = () => {
    onGetNotifications();
  };

  const handleOpenNotificationList = () => {
    setIsOpen(false);
    Navigator.navigate(PATHS.notifications);
  };

  const handleGetMoreNotification = async () => {
    setParams({
      take: (page + 1) * 10,
      skip: 0,
      unReadOnly: isUnread ? true : null,
    });
    setPage(page + 1);
  };

  const handleInfiniteScroll = async () => {
    if (page * 10 > totalItems) return;
    const wrapperContent = document.getElementById('wrapper-notification');
    try {
      if (
        !isLoading &&
        Math.ceil(wrapperContent.scrollTop + wrapperContent.offsetHeight) + 1 >=
          wrapperContent.scrollHeight
      ) {
        setIsLoading(true);
        handleGetMoreNotification();
      }
    } catch (error) {}
  };

  useEffect(() => {
    notiRef.current = () => handleInfiniteScroll();
  });

  useEffect(() => {
    onGetNotifications();
    document.addEventListener('mousedown', handleClickOutside);
    document
      .getElementById('wrapper-notification')
      ?.addEventListener('scroll', () => notiRef.current());
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document
        .getElementById('wrapper-notification')
        ?.removeEventListener('scroll', () => notiRef.current());
    };
  }, []);

  return (
    <div className="notification-wrapper">
      <div className="notification-button" ref={wrapperRef}>
        <IconButton
          onClick={handleToggleNotification}
          sx={{ backgroundColor: isOpen ? '#80808021' : 'none' }}
        >
          <span className="dot" style={{ display: isShowNotificationIcon ? 'block' : 'none' }} />
          <NotificationIcon />
        </IconButton>
        <div className="notification-list" style={{ display: isOpen ? 'block' : 'none' }}>
          <div className="title">
            <Typography fontSize={18} fontWeight={600}>
              Notifications
            </Typography>
            <Tooltip
              arrow={true}
              title="View all"
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    maxWidth: 170,
                    fontSize: 12,
                    fontWeight: 100,
                  },
                },
              }}
            >
              <IconButton style={{ padding: 0 }} onClick={handleOpenNotificationList}>
                <OpenIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="read">
            <div className="toggle-read">
              <Typography fontSize={14} fontWeight={400}>
                See unread only
              </Typography>
              <Switch
                color="secondary"
                defaultChecked={isUnread}
                onChange={handleSwitchReadStatus}
              />
            </div>
            <div className="mark" onClick={handlemarkAllNotifications} aria-hidden={true}>
              Mark all as read
            </div>
          </div>
          <div className="list" id="wrapper-notification">
            <div className="showed">
              {notifications.length ? (
                notifications.map((item, index) => (
                  <NotificationDetail
                    key={index}
                    item={item}
                    onCloseDialog={handleCloseDialog}
                    onChangeNotificationList={onChangeNotificationList}
                  />
                ))
              ) : (
                <div style={{ padding: 75, textAlign: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Image src={IMAGES.noNotification} />
                  </div>
                  <Typography mt={1}>
                    You've <span style={{ fontWeight: 600 }}>read</span> all your notifications from
                    the last 30 days.
                  </Typography>
                </div>
              )}
              {isLoading && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '10px 0',
                  }}
                >
                  <LoadingCommon loadingStyle={6} size="medium" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
