//import cn from 'classnames';
import { Box } from '@mui/material';
import React from 'react';
//import './styles.scss';

const UploadIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.38836 6.46853C7.70086 6.78103 7.85711 7.1605 7.85711 7.60693C7.85711 8.05336 7.70086 8.43282 7.38836 8.74532C7.07586 9.05782 6.6964 9.21407 6.24997 9.21407C5.80354 9.21407 5.42407 9.05782 5.11157 8.74532C4.79907 8.43282 4.64282 8.05336 4.64282 7.60693C4.64282 7.1605 4.79907 6.78103 5.11157 6.46853C5.42407 6.15603 5.80354 5.99978 6.24997 5.99978C6.6964 5.99978 7.07586 6.15603 7.38836 6.46853ZM16.4286 10.8212V14.5712H4.64286V12.9641L7.32143 10.2855L8.66072 11.6248L12.9464 7.33907L16.4286 10.8212ZM17.2321 4.92836H3.83928C3.76674 4.92836 3.70396 4.95487 3.65095 5.00788C3.59793 5.06089 3.57143 5.12367 3.57143 5.19622V15.3748C3.57143 15.4473 3.59793 15.5101 3.65095 15.5631C3.70396 15.6161 3.76674 15.6426 3.83928 15.6426H17.2321C17.3047 15.6426 17.3675 15.6161 17.4205 15.5631C17.4735 15.5101 17.5 15.4473 17.5 15.3748V5.19622C17.5 5.12367 17.4735 5.06089 17.4205 5.00788C17.3675 4.95487 17.3047 4.92836 17.2321 4.92836ZM18.5714 5.19622V15.3748C18.5714 15.7431 18.4403 16.0584 18.178 16.3206C17.9157 16.5829 17.6004 16.7141 17.2321 16.7141H3.83929C3.47098 16.7141 3.15569 16.5829 2.89342 16.3206C2.63114 16.0584 2.5 15.7431 2.5 15.3748V5.19622C2.5 4.82792 2.63114 4.51263 2.89342 4.25035C3.15569 3.98807 3.47098 3.85693 3.83929 3.85693H17.2321C17.6004 3.85693 17.9157 3.98807 18.178 4.25035C18.4403 4.51263 18.5714 4.82792 18.5714 5.19622Z"
          fill="#4684E7"
        />
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default UploadIcon;
