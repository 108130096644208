import { Snackbar } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { LoadingCommon } from 'src/components/common';
import { hideDialog } from 'src/redux/dialog/dialogSlice';
import { IRootState } from 'src/redux/rootReducer';

const SnackbarContainer: React.FC<Props> = ({ snackbarStatus, isOpenSnackbar }) => {
  return (
    <Snackbar
      open={isOpenSnackbar}
      message={`${snackbarStatus}...`}
      action={
        <>
          <LoadingCommon />
        </>
      }
    />
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const mapStateToProps = (state: IRootState) => {
  const { snackbar } = state;
  return {
    snackbarStatus: snackbar.snackbarStatus,
    isOpenSnackbar: snackbar.isOpenSnackbar,
  };
};

const mapDispatchToProps = {
  hideDialog: hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);
