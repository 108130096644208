import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams, Lead } from './types';

export function useGetAllLeads(
  options?: UseQueryOptions<ApiResponseType<PaginationResponseType<Lead>>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data: allLeadsResponse,
    error,
    isError,
    isFetching,
    refetch: onGetAllLeads,
  } = useQuery<ApiResponseType<PaginationResponseType<Lead>>, Error>([API_QUERIES.LEADS, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<PaginationResponseType<Lead>>>(
        apiClient.getAllLeads,
        params
      );
    },
    select: (data) => {
      return data;
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    staleTime: 5000,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllLead = () => queryClient.invalidateQueries(API_QUERIES.LEADS);

  const getAllLeads = () => {
    const { take, skip, ...newParams } = params;
    return queryClient.fetchQuery({
      queryKey: [API_QUERIES.LEADS, newParams],
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PaginationResponseType<Lead>>>(
          apiClient.getAllLeads,
          params
        );
      },
    });
  };

  const { data } = allLeadsResponse || {};
  const { data: leads, hasNext, payloadSize, totalItems } = data || {};

  return {
    leads,
    hasNext,
    payloadSize,
    totalItems,
    error,
    isError,
    isFetching,
    onGetAllLeads,
    setParams,
    handleInvalidateAllLead,
    getAllLeads,
  };
}
