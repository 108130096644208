import { Backdrop, Box, CircularProgress, MenuListTypeMap } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
  menuClasses,
  useProSidebar,
} from 'react-pro-sidebar';
import { SidebarHeader } from './components/SidebarHeader';

import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import WindowIcon from '@mui/icons-material/Window';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { COLOR_CODE } from 'src/appConfig/constants';
import { PATHS } from 'src/appConfig/paths';
import {
  AccountAndMemberMenu,
  ConfigurationMenu,
  CustomerMenu,
  DashboardMenu,
  ERGuardMenu,
  MenuType,
  SidebarMenu,
  UserMenu,
} from 'src/appConfig/sidebar';
import { useLogout } from 'src/queries';
import { setSelectedLink, setSelectedLinkName } from 'src/redux/common/commonSlice';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, PermissionsService, TokenService } from 'src/services';
import { isEmpty } from 'src/validations';
import { RoundUserIcons, SignOutIcon } from '../IconsCommon';
import TagIcon from '../IconsCommon/tagIcon';
import './styles.scss';
import { Settings } from '@mui/icons-material';
import { Image } from '../common';
import { IMAGES } from 'src/appConfig/images';
import { LOCAL_STORAGE_FIREBASE_TOKEN } from 'src/services/content';
import { useDisabledPushNotification } from 'src/queries/Notification/useDisabledPushNotification';

type Theme = 'light' | 'dark';

const themes = {
  light: {
    sidebar: {
      backgroundColor: '#ffffff',
      color: '#607489',
      width: '300px',
    },
    menu: {
      menuContent: '#fbfcfd',
      icon: '#6D7176',
      hover: {
        backgroundColor: '#F8F8F9',
        color: '#44596e',
      },
      active: {
        backgroundColor: '#DAEDFD',
        color: COLOR_CODE.SECONDARY,
      },
      disabled: {
        color: '#9fb6cf',
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: '#0b2948',
      color: '#8ba1b7',
      width: '300px',
    },
    menu: {
      menuContent: '#082440',
      icon: '#59d0ff',
      hover: {
        backgroundColor: '#F8F8F9',
        color: '#b6c8d9',
      },
      active: {
        backgroundColor: COLOR_CODE.SECONDARY,
        color: '#ffffff',
      },
      disabled: {
        color: '#3e5e7e',
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const CustomSidebar: React.FC<Props> = ({ children }) => {
  const { collapsed, broken } = useProSidebar();
  const location = useLocation();
  const [toggle, setToggle] = useState({
    openAdmin: location.pathname.startsWith(PATHS.admin),
    openSale: location.pathname.startsWith(PATHS.sale),
    openDashboard: location.pathname.startsWith(PATHS.dashboard),
    openErGuard: location.pathname.startsWith(PATHS.erGuard),
    openAccountAndMembers: location.pathname.startsWith(PATHS.accountAndMembers),
    openConfiguration: location.pathname.startsWith(PATHS.configuration),
  });
  const { logout } = useLogout();
  const dispatch = useDispatch();

  const { disableNotification } = useDisabledPushNotification();

  const { selectedLink, selectedLinkName } = useSelector((state: IRootState) => state.common);

  useEffect(() => {
    SidebarMenu.forEach((value) => {
      if (location.pathname.includes(value.href)) {
        dispatch(setSelectedLink(value.href));
        dispatch(setSelectedLinkName(value.title));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [isRTL, setIsRTL] = React.useState<boolean>(false);
  const [hasImage, setHasImage] = React.useState<boolean>(false);
  const [theme, setTheme] = React.useState<Theme>('light');
  const [isClickedLogout, setIsClickedLogout] = React.useState(false);

  const userMenu = UserMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );

  const customerMenu = CustomerMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );

  const accountAndMemberMenu = AccountAndMemberMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );

  const erGuardMenu = ERGuardMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );

  const dashboardMenu = DashboardMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );
  const configurationMenu = ConfigurationMenu.filter((item) =>
    item.permissions.some((permission) => PermissionsService.getPermissions().includes(permission))
  );
  // handle on RTL change event
  const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsRTL(e.target.checked);
  };

  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? 'dark' : 'light');
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  const handleListItemClick = (link: string, title: string) => {
    dispatch(setSelectedLink(link));
    dispatch(setSelectedLinkName(title));
    setTimeout(() => {
      Navigator.navigate(link);
    }, 0);
  };

  const handleLogout = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        const firebaseToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FIREBASE_TOKEN));
        if (firebaseToken) {
          const tokenPayload = {
            token: firebaseToken,
          };
          disableNotification(tokenPayload);
        }
      }
    });

    setIsClickedLogout(true);
    logout();
    TokenService.clearToken();
    localStorage.removeItem(LOCAL_STORAGE_FIREBASE_TOKEN);
  };

  const menuItemStyles: MenuItemStyles = {
    //#region
    root: {
      fontSize: '16px',
      fontWeight: 600,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      // '& span': {
      //   width: '6px',
      //   height: '6px'
      // },
      //color: '#b6b7b9',
      color: '#6D7176',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: ({ level, active, disabled }) => ({
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: active
          ? themes[theme].menu.active.backgroundColor
          : hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: active ? undefined : themes[theme].menu.hover.color,
      },
      backgroundColor: active ? themes[theme].menu.active.backgroundColor : undefined,
      color: active ? themes[theme].menu.active.color : undefined,
      borderRadius: '8px !important',
    }),
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };
  const getActiveSubMenu = (arrayMenu: MenuType[]) => {
    if (collapsed) {
      return arrayMenu.find((x) => x.href === location.pathname)
        ? true
        : arrayMenu
            .filter((x) => x.groupHref)
            .map((x) => x.groupHref)
            .flat(1)
            .includes(location.pathname);
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Box sx={{ display: 'flex', height: '100%' }}>
        {isClickedLogout && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            onClick={() => {}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <Sidebar
          className="cmp-sidebar custom-dot-menu"
          style={{}}
          // image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
          rtl={isRTL}
          width={themes[theme].sidebar.width}
          breakPoint="md"
          backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
          rootStyles={{
            color: themes[theme].sidebar.color,
          }}
          // defaultCollapsed
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <SidebarHeader style={{ marginBottom: '24px', marginTop: '16px' }} />
            <div
              style={{
                flex: 1,
                marginBottom: '32px',
                padding: '0 10px',
                height: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {/* <div style={{ padding: '0 24px', marginBottom: '8px' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                Sale
              </Typography>
            </div> */}
              <Menu menuItemStyles={menuItemStyles} className="custom-v-menu">
                {userMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="User Management"
                    icon={<PersonIcon />}
                    defaultOpen={location.pathname.startsWith(PATHS.admin)}
                    open={toggle.openAdmin}
                    active={getActiveSubMenu(userMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: !toggle.openAdmin,
                        openErGuard: false,
                        openSale: false,
                        openAccountAndMembers: false,
                        openDashboard: false,
                        openConfiguration: false,
                      });
                    }}
                  >
                    {userMenu.map((item, index) => (
                      <MenuItem
                        className="custome-v-submenu"
                        key={item.title}
                        active={selectedLink === item.href}
                        onClick={() => {
                          handleListItemClick(item.href, item.title);
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
                {dashboardMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="Dashboard"
                    icon={<WindowIcon />}
                    open={toggle.openDashboard}
                    active={getActiveSubMenu(dashboardMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: false,
                        openErGuard: false,
                        openSale: false,
                        openAccountAndMembers: false,
                        openConfiguration: false,
                        openDashboard: !toggle.openDashboard,
                      });
                    }}
                  >
                    {dashboardMenu.map((item, index) => (
                      <MenuItem
                        className="custome-v-submenu"
                        key={item.title}
                        active={selectedLink === item.href}
                        onClick={() => {
                          handleListItemClick(item.href, item.title);
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
                {erGuardMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="ER Guard"
                    icon={<TagIcon />}
                    defaultOpen={location.pathname.startsWith(PATHS.erGuard)}
                    open={toggle.openErGuard}
                    active={getActiveSubMenu(erGuardMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: false,
                        openErGuard: !toggle.openErGuard,
                        openSale: false,
                        openAccountAndMembers: false,
                        openConfiguration: false,
                        openDashboard: false,
                      });
                    }}
                  >
                    {erGuardMenu.map((item, index) => {
                      return (
                        <MenuItem
                          className="custome-v-submenu"
                          key={item.title}
                          active={
                            isEmpty(item.groupHref)
                              ? selectedLink === item.href
                              : item.groupHref.includes(location.pathname)
                              ? selectedLink === item.groupHref.find((x) => x === location.pathname)
                              : selectedLink ===
                                item.groupHref.find(
                                  (x) =>
                                    x ===
                                    location.pathname.slice(0, location.pathname.lastIndexOf('/'))
                                )
                          }
                          onClick={() => {
                            handleListItemClick(item.href, item.title);
                          }}
                        >
                          {item.title}
                        </MenuItem>
                      );
                    })}
                  </SubMenu>
                )}
                {customerMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="Customer"
                    icon={<PeopleIcon />}
                    defaultOpen={location.pathname.startsWith(PATHS.sale)}
                    open={toggle.openSale}
                    active={getActiveSubMenu(customerMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: false,
                        openErGuard: false,
                        openSale: !toggle.openSale,
                        openAccountAndMembers: false,
                        openConfiguration: false,
                        openDashboard: false,
                      });
                    }}
                  >
                    {customerMenu.map((item, index) => (
                      <MenuItem
                        className="custome-v-submenu"
                        active={selectedLink === item.href}
                        key={item.title}
                        onClick={() => {
                          handleListItemClick(item.href, item.title);
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
                {accountAndMemberMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="Accounts & Members"
                    icon={<RoundUserIcons />}
                    defaultOpen={location.pathname.startsWith(PATHS.accountAndMembers)}
                    open={toggle.openAccountAndMembers}
                    active={getActiveSubMenu(accountAndMemberMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: false,
                        openErGuard: false,
                        openSale: false,
                        openAccountAndMembers: !toggle.openAccountAndMembers,
                        openConfiguration: false,
                        openDashboard: false,
                      });
                    }}
                  >
                    {accountAndMemberMenu.map((item, index) => (
                      <MenuItem
                        className="custome-v-submenu"
                        active={selectedLink === item.href}
                        key={item.title}
                        onClick={() => {
                          handleListItemClick(item.href, item.title);
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
                {configurationMenu.length > 0 && (
                  <SubMenu
                    style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                    label="Configuration"
                    icon={<Image src={IMAGES.iconSettings} />}
                    open={toggle.openConfiguration}
                    active={getActiveSubMenu(configurationMenu)}
                    onOpenChange={(open) => {
                      setToggle({
                        openAdmin: false,
                        openErGuard: false,
                        openSale: false,
                        openAccountAndMembers: false,
                        openConfiguration: !toggle.openConfiguration,
                        openDashboard: false,
                      });
                    }}
                  >
                    {configurationMenu.map((item, index) => (
                      <MenuItem
                        className="custome-v-submenu"
                        key={item.title}
                        active={selectedLink === item.href}
                        onClick={() => {
                          handleListItemClick(item.href, item.title);
                        }}
                      >
                        {item.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                )}
                <MenuItem
                  className="cus-sOut-link"
                  style={{ fontSize: '16px', color: '#484C4F', paddingLeft: '0px' }}
                  icon={<SignOutIcon />} //<ExitToAppIcon />}
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  Log out
                </MenuItem>
              </Menu>

              {/* <div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px' }}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
              >
                Extra
              </Typography>
            </div> */}
            </div>
            {/* <SidebarFooter collapsed={collapsed} /> */}
          </Box>
        </Sidebar>
        {/* <Navbar /> */}
      </Box>
    </Box>
  );
};
type Props = { children?: any };

export default CustomSidebar;
