import { Box } from '@mui/material';
import React from 'react';

const OpenIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color = '#6D7176',
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 14.25C10.3517 14.2499 10.2068 14.2059 10.0835 14.1235C9.96023 14.0411 9.86416 13.924 9.80742 13.787C9.75068 13.65 9.73583 13.4992 9.76474 13.3538C9.79365 13.2083 9.86502 13.0747 9.96984 12.9698L17.3733 5.56641C16.9901 5.35837 16.561 5.24959 16.125 5.25H4.875C4.17904 5.25075 3.51179 5.52755 3.01967 6.01967C2.52755 6.51179 2.25074 7.17904 2.25 7.875V19.125C2.25074 19.821 2.52755 20.4882 3.01967 20.9803C3.51179 21.4725 4.17904 21.7493 4.875 21.75H16.125C16.821 21.7493 17.4882 21.4725 17.9803 20.9803C18.4725 20.4882 18.7493 19.821 18.75 19.125V7.875C18.7504 7.43902 18.6416 7.00987 18.4336 6.62672L11.0302 14.0302C10.9607 14.1 10.878 14.1554 10.7871 14.1931C10.6961 14.2308 10.5985 14.2502 10.5 14.25Z"
          fill={color}
        />
        <path
          d="M21 2.25H15.75C15.5511 2.25 15.3603 2.32902 15.2197 2.46967C15.079 2.61032 15 2.80109 15 3C15 3.19891 15.079 3.38968 15.2197 3.53033C15.3603 3.67098 15.5511 3.75 15.75 3.75H19.1892L17.3733 5.56641C17.8217 5.80999 18.19 6.17827 18.4336 6.62672L20.25 4.81078V8.25C20.25 8.44891 20.329 8.63968 20.4697 8.78033C20.6103 8.92098 20.8011 9 21 9C21.1989 9 21.3897 8.92098 21.5303 8.78033C21.671 8.63968 21.75 8.44891 21.75 8.25V3C21.75 2.80109 21.671 2.61032 21.5303 2.46967C21.3897 2.32902 21.1989 2.25 21 2.25Z"
          fill={color}
        />
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default OpenIcon;
