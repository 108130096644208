import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useMarkReadAllNotifications(options?: UseMutationOptions<any, Error>) {
  const { mutate: markAllNotifications, isLoading } = useMutation<any, Error>({
    mutationFn: () => {
      return authResponseWrapper(apiClient.markAllNotifications);
    },
    ...options,
  });

  return {
    markAllNotifications,
    isLoading,
  };
}
