import { TableParams } from 'src/redux/types';

export enum MEMBER_SEARCH_KEY {
  MEMBER_ID = 'memberId',
  HMO = 'hMO',
  NAME = 'name',
  PLAN_TYPE = 'planType',
  MEMBER_STATUS = 'memberStatus',
  MEMBER_TYPE = 'memberType',
  MEMBER_PARTICIPATION = 'memberParticipation',
  ACCOUNT_NAME = 'accountName',
  SUSPENSION_DATE = 'suspensionDate',
  CURRENT_POLICY_DATE = 'policyDate',
  EXPIRY_DATE = 'expiryDate',
  DAY_TO_EXPIRY = 'dayToExpiry',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
