import { UseQueryOptions, useQuery } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Provinces } from './type';

export function useGetListProvinces(
  options: UseQueryOptions<ApiResponseType<PaginationResponseType<Provinces>>, Error>
) {
  const { data: provinces, refetch: onGetListProvinces } = useQuery<
    ApiResponseType<PaginationResponseType<Provinces>>,
    Error
  >([API_QUERIES.LIST_PROVINCE], {
    queryFn: (query) => {
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<PaginationResponseType<Provinces>>>(
        apiClient.getListProvinces,
        params
      );
    },
    select: (data) => {
      return data;
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    ...options,
  });
  return {
    provinces,
    onGetListProvinces,
  };
}
