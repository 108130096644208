import { TableParams } from 'src/redux/types';

export enum ERQUARD_ORDER_KEY {
  ORDER_ID = 'orderId',
  PRODUCT = 'product',
  TYPE = 'type',
  MEMBER_NAME = 'memberName',
  MEMBER_EMAIL = 'memberEmail',
  PURCHASED_DATE = 'purchasedDate',
  CARD_ACTIVATION_DATE = 'cardActivationDate',
  EFFECTIVE_DATE = 'effectiveDate',
  COMPANY_NAME = 'companyName',
  BRANCH_NAME = 'branchName',
  REGION = 'region',
  TELLER = 'teller',
  STATUS = 'status',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};
