import { Location } from 'history';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { PATHS } from 'src/appConfig/paths';
import { Navigator, TenantService } from 'src/services';

import { Box, CircularProgress } from '@mui/material';
import Sidebar from 'src/components/Sidebar';
import CustomRoute from 'src/containers/CustomRouting';
import { useComponentDidMount } from 'src/hooks';
import AuthContainer from '../containers/StartupContainers/AuthContainer';
import ContentContainer from '../containers/StartupContainers/ContentContainer';
import DialogContainer from '../containers/StartupContainers/DialogContainer';
import ScrollToTop from '../containers/StartupContainers/ScrollToTop';
import ToastContainer from '../containers/StartupContainers/ToastContainer';
import NotPermission from './NotPermission';
import ERGuardActivationForm from './SaleContainers/ERGuardActivationForm';
import InterestForm from './SaleContainers/InterestForm';
import SnackbarContainer from './StartupContainers/SnackbarContainer';
import NotificationList from './Notification/NotificationList';
import SystemSearch from './SystemSearch';
import AppBar from 'src/components/AppBar';
import useBackPreviousPage from 'src/hooks/useBackPreviousPage';

/* Admin */
const UAMNavigator = React.lazy(() => import('../containers/UAMContainer'));
const SaleNavigator = React.lazy(async () => import('../containers/SaleContainers'));
const AdminNavigator = React.lazy(() => import('../containers/AdminContainers'));
const MyProfile = React.lazy(() => import('../containers/UserProfile/MyProfile'));
const ERGuardNavigator = React.lazy(() => import('../containers/ERGuardContainers'));
const DashboardNavigator = React.lazy(() => import('../containers/DashboardContainers'));
const AccountAndMembersNavigator = React.lazy(
  () => import('../containers/AccountAndMembersContainer')
);
const ConfigurationNavigator = React.lazy(() => import('../containers/ConfigurationContainer'));

/* Admin */

const AppRouting: React.FC<{ location: Location }> = (props) => {
  Navigator.setTopHistory(useHistory());

  useComponentDidMount(() => {
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });
  useBackPreviousPage();
  return (
    <Box pt={0} sx={{ overflow: 'hidden', height: '100vh' }}>
      <Box sx={{ display: 'flex' }}>
        {(props.location.pathname.includes(PATHS.admin) ||
          props.location.pathname.includes(PATHS.sale) ||
          props.location.pathname.includes(PATHS.dashboard) ||
          props.location.pathname.includes(PATHS.erGuard) ||
          props.location.pathname.includes(PATHS.accountAndMembers) ||
          props.location.pathname.includes(PATHS.configuration) ||
          props.location.pathname.includes(PATHS.noPermission) ||
          props.location.pathname.includes(PATHS.notifications) ||
          props.location.pathname.includes(PATHS.systemSearch) ||
          props.location.pathname.includes(PATHS.myProfile)) && <Sidebar />}
        <div style={{ overflow: 'hidden', height: '100vh', width: '100%' }}>
          <Suspense
            fallback={
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress color="inherit" />
              </div>
            }
          >
            {props.location.pathname.includes(PATHS.admin) ||
            props.location.pathname.includes(PATHS.sale) ||
            props.location.pathname.includes(PATHS.dashboard) ||
            props.location.pathname.includes(PATHS.erGuard) ||
            props.location.pathname.includes(PATHS.accountAndMembers) ||
            props.location.pathname.includes(PATHS.configuration) ||
            props.location.pathname.includes(PATHS.noPermission) ||
            props.location.pathname.includes(PATHS.notifications) ||
            props.location.pathname.includes(PATHS.systemSearch) ||
            props.location.pathname.includes(PATHS.myProfile) ? (
              <AppBar>
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 70px)' }}
                >
                  <Switch location={props.location}>
                    <CustomRoute pageRequiredAuth path={'/me'} component={MyProfile} />
                    <Route path={'/admin'} component={AdminNavigator} />
                    <Route path={'/sale'} component={SaleNavigator} />
                    <Route path={'/dashboard'} component={DashboardNavigator} />
                    <Route path={'/er-guard'} component={ERGuardNavigator} />
                    <Route path={'/account-and-member'} component={AccountAndMembersNavigator} />
                    <Route path={'/configuration'} component={ConfigurationNavigator} />
                    <CustomRoute
                      pageRequiredAuth
                      path={'/notifications'}
                      component={NotificationList}
                    />
                    <CustomRoute
                      pageRequiredAuth
                      path={'/system-search'}
                      component={SystemSearch}
                    />
                    <CustomRoute pageRequiredAuth path={'/me'} component={MyProfile} />
                  </Switch>
                  <CustomRoute pageRequiredAuth path={'/no-permission'} component={NotPermission} />
                </div>
              </AppBar>
            ) : (
              <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh)' }}>
                <Switch location={props.location}>
                  <CustomRoute path={`${PATHS.interestForm}`} component={InterestForm} />
                  <CustomRoute
                    path={`${PATHS.activationFormOnlineShop}`}
                    component={ERGuardActivationForm}
                  />
                  <Route path={PATHS.root} component={UAMNavigator} />
                </Switch>
              </div>
            )}
          </Suspense>
        </div>
      </Box>
      <AuthContainer />
      <ContentContainer />
      <DialogContainer />
      <SnackbarContainer />
      <ToastContainer />
      <ScrollToTop />
    </Box>
  );
};

export default AppRouting;
