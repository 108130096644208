import { UseQueryOptions, useQuery } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Cities } from './type';

export function useGetListCities(
  options: UseQueryOptions<ApiResponseType<PaginationResponseType<Cities>>, Error>
) {
  const { data: cities, refetch: onGetListCities } = useQuery<
    ApiResponseType<PaginationResponseType<Cities>>,
    Error
  >([API_QUERIES.LIST_CITY], {
    queryFn: (query) => {
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<PaginationResponseType<Cities>>>(
        apiClient.getListCities,
        params
      );
    },
    select: (data) => {
      return data;
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    ...options,
  });
  return {
    cities,
    onGetListCities,
  };
}
