import React from 'react';
import SystemUser from '../SystemUser';
import TeamsSearch from '../TeamsSearch';
import LeadSearch from '../LeadSearch';
import DealSearch from '../DealSearch';
import AccountSearch from '../AccountSearch';
import MemberSearch from '../MemberSearch';
import ERGuardOrder from '../ERGuardOrder/';
import ERGuardBranch from '../ERGuardBranch';
import ERGuardCompany from '../ERGuardCompany';
import ERGuardUser from '../ERGuardUser';
import ProductSearch from '../ProductSearch';

const AllSystemSearch: React.FC<Props> = ({ searchText }) => {
  return (
    <div style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'scroll' }}>
      <SystemUser isViewAll searchText={searchText} /> <br />
      <TeamsSearch isViewAll searchText={searchText} /> <br />
      <LeadSearch isViewAll searchText={searchText} /> <br />
      <DealSearch isViewAll searchText={searchText} /> <br />
      <AccountSearch isViewAll searchText={searchText} /> <br />
      <MemberSearch isViewAll searchText={searchText} /> <br />
      <ERGuardOrder isViewAll /> <br />
      <ERGuardBranch isViewAll /> <br />
      <ERGuardCompany isViewAll /> <br />
      <ERGuardUser isViewAll /> <br />
      <ProductSearch isViewAll searchText={searchText} /> <br />
    </div>
  );
};

type Props = {
  searchText: string;
};

export default AllSystemSearch;
