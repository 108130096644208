import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import appConfig from 'src/appConfig';
import { CRUInterestPayLoad } from 'src/containers/SaleContainers/InterestForm/helpers';
import { LeadFromCSV } from 'src/containers/SaleContainers/LeadInbox/TableList/helpers';
import {
  AssignAgentPayload,
  CRULeadInboxFormValue,
} from 'src/containers/SaleContainers/LeadInbox/components/CreateLeadInboxModal/helpers';
import { ProfilePayload } from 'src/queries';
import {
  BillingInformationForCorporate,
  ConfirmPaymentPayload,
  DeleteAccountRecordPayload,
  UpdateAccountRecordPayload,
  UpdateStatusAccountPayload,
} from 'src/queries/Account/types';
import {
  AdditionalDocument,
  AdditionalDocumentERGuard,
} from 'src/queries/AdditionalDocuments/types';
import {
  CreateBenefitItemPayload,
  CreateProviderPayload,
  DeleteProviderPayload,
  UpdateBenefitPayload,
} from 'src/queries/Benefits/types';
import { UpdateProductPayload } from 'src/queries/Configuration/types';
import {
  AddAccountRecordPayload,
  AddDealPayload,
  AddNoteApproval,
  AdditionalApproval,
  AssignAgentsPayload,
  ConfirmActivePlan,
  Deal,
  DeleteAdditionalApproval,
  LinkToAccount,
  SendApplicationForm,
  SendPolicyDocuments,
  UnlinkToAccount,
  UpdateBillingDetailPayload,
  UpdateDealPayload,
  UpdateMarkACompletePayload,
  UpdatePolicyEffectiveDate,
  UpdateReadyForReviewPayload,
  UpdateSAFForm,
} from 'src/queries/Deals/types';
import {
  ActivationFormPayload,
  BranchDetail,
  Companies,
  CreateBranchPayload,
  CreateERGuardUserPayload,
  OrderDetailResponse,
  OrderPayload,
  Product,
  RequestVoidTransactionPayload,
  ResetPasswordByIdPayload,
  UnvoidPayload,
  UpdateBranchPayload,
  UpdateMemberRecordPayload,
  UpdateOrderDetailPayload,
  UpdateVoidTransactionPayload,
  UtilizedOrderPayload,
  VoidTransactionPayload,
} from 'src/queries/ERGuard/type';
import {
  DeleteAdditionalDocPayload,
  EnrollmentDoc,
  GetPresignedPayload,
  UpdateAdditionalDocPayload,
  UpdateEnrollmentDocPayload,
  UploadAdditionalDocPayload,
  UploadEnrollmentDocPayload,
  UploadFilePayload,
} from 'src/queries/File/types';
import { Finance } from 'src/queries/FinanceDashboard/types';
import { Lead, UpdateDuplicatePayLoad } from 'src/queries/Leads/types';
import { CreateMatrix } from 'src/queries/Matrix/types';
import {
  BulkAssignHMOCardNumberPayload,
  ConfirmPaymentMemberPayload,
  CreateMemberRecordsPayload,
  UpdateMemberRecordStatusPayload,
} from 'src/queries/MemberRecords/types';
import { GeneralNote, Note, Notes } from 'src/queries/Notes/types';
import { MarkReadNotificationPayload, UpdateTokenPayload } from 'src/queries/Notification/types';
import {
  ActivateOnlineOrderPayload,
  OnlineOrderDetail,
  RefundOnlineOrderPayload,
  UpdateOnlineOrderDetailPayload,
} from 'src/queries/OnlineOrder/type';
import {
  ConfirmationPayment,
  ImportARNumberPayload,
  MassConfirmPaymentPayload,
  OrderPaymentDetail,
  OrderPaymentRequestDetail,
} from 'src/queries/Payment/type';
import {
  CreateMemberMasterListPayload,
  CreateSAFFormPayload,
  ExportPolicyContract,
  ExportSAFForm,
} from 'src/queries/SAFQueries/types';
import { Sale } from 'src/queries/SalesDashboard/types';
import {
  AddMemberPayload,
  AddTeamPayload,
  RemoveMemberPayload,
  Team,
} from 'src/queries/Teams/types';
import {
  ChangeFirstNewPasswordParams,
  ChangePasswordPayload,
  CompleteNewPasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  ConfirmSignUpPayload,
  ForgotPasswordPayload,
  ResendSignUpPayload,
  SignInPayload,
  SignUpPayload,
  SubmitForgotPasswordPayload,
} from 'src/queries/UAM/types';
import { UPD } from 'src/queries/UPDDashboard/types';
import {
  CreateNewRolePayload,
  Role,
  UpdateUserRolePayload,
} from 'src/queries/UserPermission/types';
import {
  AddUserPayload,
  GetPropertiesParams,
  UpdateUserPayload,
  User,
} from 'src/queries/Users/types';
import { newCancelToken, stringify } from 'src/utils';
import { TokenService, XLoginKeyService } from '.';

axios.defaults.withCredentials = true;

const responseStatusWrapper = (promise: Promise<any>) => {
  return promise
    .then((data) => ({ ok: true, fromAuth: true, data }))
    .catch((err) => ({ data: err }));
};

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    // if (config.url.includes('sales-svc')) {
    //   config.baseURL = 'http://[::1]:3000/';
    // }
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) =>
    responseStatusWrapper(Auth.signIn(body.username, body.password));

  const signOut = () => responseStatusWrapper(Auth.signOut());

  const signUp = (body: SignUpPayload) => {
    const params = {
      username: body.username,
      password: body.password,
    };

    const attributes = {
      email: body.email,
      phone_number: body.phoneNumber,
      given_name: body.firstName,
      family_name: body.lastName,
      gender: body.gender,
      'custom:user_type': body.userType,
      // middle_name: body.middleName,
      birthdate: body.dateOfBirth,
      // 'custom:ssn': body.socialSecurityNumber,
      // 'custom:user_type': 'CLAIMANT',
    };

    return responseStatusWrapper(Auth.signUp({ ...params, attributes }));
  };

  const resendSignUp = (body: ResendSignUpPayload) =>
    responseStatusWrapper(Auth.resendSignUp(body.email));

  const confirmSignUp = (body: ConfirmSignUpPayload) => {
    return responseStatusWrapper(Auth.confirmSignUp(body.username, body.code));
  };

  const forgotPassword = (body: ForgotPasswordPayload) =>
    responseStatusWrapper(Auth.forgotPassword(body.email));

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    responseStatusWrapper(Auth.forgotPasswordSubmit(body.username, body.code, body.password));

  const changePassword = (body: ChangePasswordPayload) =>
    responseStatusWrapper(Auth.changePassword(body.user, body.currentPassword, body.newPassword));

  const confirmSignIn = (body: ConfirmSignInPayload) =>
    responseStatusWrapper(Auth.sendCustomChallengeAnswer(body.user, body.code));

  const confirmPassword = (password: ConfirmPasswordPayload) => {
    return responseStatusWrapper(
      Auth.currentAuthenticatedUser().then((user) =>
        Auth.signIn({
          username: user.username,
          password: password.password,
        })
      )
    );
  };

  const completeNewPassword = (body: CompleteNewPasswordPayload) =>
    responseStatusWrapper(
      Auth.completeNewPassword(body.user, body.password, body.requiredAttributes)
    );

  // ====================== Profile ======================
  const getUserId = (params: { username: string }) => {
    const username = { username: params.username };
    const queryString = stringify(username);
    return api.get(`/account-svc/v1/users/user-id?${queryString}`, {}, newCancelToken());
  };

  // ====================== Profile ======================
  const getMyProfile = () => api.get('/account-svc/v1/me', {}, newCancelToken());

  const updateUserAvatar = (body: { avatarUrl: string }) =>
    api.patch(`/me/avatar`, body, newCancelToken());

  const updateMyProfile = (body: ProfilePayload) =>
    api.put(`/account-svc/v1/me`, body, newCancelToken());

  // ====================== Content ======================
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());

  // ====================== File ======================
  const getPresignedUserServiceUrl = (params: GetPresignedPayload) => {
    return api.get('/sales-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  const uploadFile = (body: UploadFilePayload) => axios.put(body.url, body.fileData);

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/sales-svc/v1/files/presigned-download-url', params, newCancelToken());

  const uploadFileWithProgress = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      ...(body.contentType && { headers: { 'Content-Type': body.contentType } }),
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        body.setProgress(percentageProgress, loaded, total);
      },
    });

  const uploadAdditionalDoc = (params: UploadAdditionalDocPayload) => {
    const { id } = params;
    delete params.id;
    return api.put(
      `/sales-svc/v1/share/upload-additional-doc/${id}`,
      { ...params },
      newCancelToken()
    );
  };
  const updateAdditionalDoc = (params: UpdateAdditionalDocPayload) => {
    const { id } = params;
    delete params.id;
    return api.put(
      `/sales-svc/v1/share/update-additional-doc/${id}`,
      { ...params },
      newCancelToken()
    );
  };

  const deleteAdditonalDoc = (params: DeleteAdditionalDocPayload) => {
    return api.post(`/sales-svc/v1/notes/delete-additional-doc`, { ...params }, newCancelToken());
  };

  const uploadEnrollmentDoc = (params: UploadEnrollmentDocPayload) => {
    const { id } = params;
    delete params.id;
    return api.put(
      `/sales-svc/v1/deals/upload-enroll-documents/${id}`,
      { ...params },
      newCancelToken()
    );
  };
  const updateEnrollmentDoc = (params: UpdateEnrollmentDocPayload) => {
    const { id } = params;
    delete params.id;
    return api.put(
      `/sales-svc/v1/deals/update-enroll-documents/${id}`,
      { ...params },
      newCancelToken()
    );
  };
  const deleteEnrollmentDoc = (id: EnrollmentDoc['id']) => {
    return api.delete(`/sales-svc/v1/deals/delete-enroll-documents/${id}`, {}, newCancelToken());
  };
  const getUrlExportMemberMasterList = () => {
    return api.get('/sales-svc/v1/share/export-member-master', {}, newCancelToken());
  };
  const getUrlExportLeadExampleCSV = () => {
    return api.get('/sales-svc/v1/share/export-lead-example-csv', {}, newCancelToken());
  };
  // ======================Customer Profile Notes ======================
  const uploadNote = (params: Note) => {
    const { id } = params;
    return api.put(`/sales-svc/v1/share/upload-notes/${id}`, { ...params }, newCancelToken());
  };
  const updateNote = (params: Note) => {
    const { id } = params;
    return api.put(`/sales-svc/v1/share/update-notes/${id}`, { ...params }, newCancelToken());
  };
  const deteleNote = (params: Notes['id'][]) => {
    return api.post(`/sales-svc/v1/notes/delete`, { ...params }, newCancelToken());
  };

  // ====================== General Notes ======================
  const createNote = (payload: GeneralNote) => {
    return api.post(
      `/sales-svc/v1/notes`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };
  const updateNoteById = (params: GeneralNote) => {
    const { id, description } = params;
    return api.put(`/sales-svc/v1/notes/${id}`, { description }, newCancelToken());
  };
  const deleteNoteById = (params: Note) => {
    const { id } = params;
    return api.delete(`/sales-svc/v1/notes/${id}`, {}, newCancelToken());
  };

  // ====================== General Upload Documents ======================
  const uploadMultipleDocuments = (documents: AdditionalDocument[]) => {
    return api.post(
      `/sales-svc/v1/additionalDocuments`,
      {
        additionalDocuments: [...documents],
      },
      newCancelToken()
    );
  };
  const uploadMultipleDocumentsERGuard = (documents: AdditionalDocumentERGuard[]) => {
    return api.post(
      `/erguard-svc/v1/additional-documents`,
      {
        additionalDocuments: [...documents],
      },
      newCancelToken()
    );
  };
  const updateDocumentById = (params: AdditionalDocument) => {
    const { id, name } = params;
    return api.put(`/sales-svc/v1/additionalDocuments/${id}`, { name }, newCancelToken());
  };
  const deleteDocumentById = (params: { id: string }) => {
    const { id } = params;
    return api.delete(`/sales-svc/v1/additionalDocuments/${id}`, {}, newCancelToken());
  };

  const deleteMultipleDocuments = (payload: { ids: string[] }) => {
    const { ids } = payload;
    return api.post(`/sales-svc/v1/additionalDocuments/deleteMany`, { ids }, newCancelToken());
  };
  // ====================== System Accounts ======================

  const searchUserAccounts = (params: { search: string }) => {
    const queryString = stringify(params);
    return api.get(`/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsAxios = (params: { search: string; skip: number; take: number }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsByOrderAxios = (params: {
    search: string;
    skip: number;
    take: number;
    order: string;
  }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };

  // ====================== System Accounts ======================
  const getMyPermissions = () => api.get('/account-svc/v1/permissions/me', {}, newCancelToken());

  // ====================== Users Management ======================
  const getAllUsers = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users?${queryString}`, {}, newCancelToken());
  };

  const getUser = (params: { id: User['id'] }) => {
    return api.get(`/account-svc/v1/users/${params.id}`, {}, newCancelToken());
  };

  const getListSelectSales = (params: { saleTitle: string }) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users?${queryString}`, {}, newCancelToken());
  };

  const createUser = (payload: AddUserPayload) => {
    return api.post(
      `/account-svc/v1/users`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteUser = (userId: User['id']) => {
    return api.delete(`/account-svc/v1/users/deactive/${userId}`, {}, newCancelToken());
  };

  const updateUser = (payload: UpdateUserPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/account-svc/v1/users/${id}`, { ...payload }, newCancelToken());
  };

  const searchUsers = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users/search/delegates?${queryString}`, {}, newCancelToken());
  };

  const searchProjects = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/projects?${queryString}`, {}, newCancelToken());
  };

  const changeFirstNewPassword = (payload: ChangeFirstNewPasswordParams) => {
    const url = `/account-svc/v1/users/new-password-change`;
    const localXLoginKey = XLoginKeyService.getLoginKey();

    const options = {
      headers: {
        ...(localXLoginKey && {
          'X-LOGIN-KEY': CryptoJS.AES.encrypt(payload.username, localXLoginKey).toString(),
        }),
      },
    };

    return api.post(url, { ...payload }, options);
  };

  const getStaffUser = (params: { id: User['id'] }) => {
    return api.get(`/account-svc/v1/users/staff/${params.id}`, {}, newCancelToken());
  };

  // ====================== Users Permission ======================

  const getPermissionList = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/roles?${queryString}`, {}, newCancelToken());
  };

  const getPermissionDefault = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/permission-default?${queryString}`, {}, newCancelToken());
  };

  const createNewRole = (payload: CreateNewRolePayload) => {
    return api.post(`/account-svc/v1/roles`, { ...payload }, newCancelToken());
  };

  const updateRole = (payload: CreateNewRolePayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/account-svc/v1/roles/${id}`, { ...payload }, newCancelToken());
  };

  const deleteRole = (roleID: Role['id']) => {
    return api.delete(`/account-svc/v1/roles/delete/${roleID}`, {}, newCancelToken());
  };
  const getDetailRole = (params: { id: Role['id'] }) => {
    return api.get(`/account-svc/v1/roles/${params.id}`, {}, newCancelToken());
  };

  const updateUserRole = (payload: UpdateUserRolePayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/account-svc/v1/roles/update-member/${id}`, { ...payload }, newCancelToken());
  };

  // ====================== Teams Management ======================
  const getAllTeams = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/teams?${queryString}`, {}, newCancelToken());
  };

  const getTeam = (params: { id: Team['id'] }) => {
    return api.get(`/account-svc/v1/teams/${params.id}`, {}, newCancelToken());
  };

  const createTeam = (payload: AddTeamPayload) => {
    return api.post(
      `/account-svc/v1/teams`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateTeam = (payload: AddTeamPayload) => {
    const { id } = payload;
    delete payload.id;
    delete payload.leadUserId;
    return api.put(`/account-svc/v1/teams/${id}`, { ...payload }, newCancelToken());
  };

  const addMember = (payload: AddMemberPayload) => {
    return api.post(
      `/account-svc/v1/teams/add-member`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const removeMember = (payload: RemoveMemberPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/account-svc/v1/teams/remove-member/${id}`, { ...payload }, newCancelToken());
  };

  const deleteTeam = (teamId: Team['id']) => {
    return api.delete(`/account-svc/v1/teams/${teamId}`, {}, newCancelToken());
  };

  // ====================== LIST SELECT ======================

  const getListRoles = () => {
    return api.get(`/account-svc/v1/roles`, {}, newCancelToken());
  };

  const getSalesManagerList = (params: { roles: any }) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users?${queryString}`, {}, newCancelToken());
  };

  const getSalesAgentList = (params: { roles: any }) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users?${queryString}`, {}, newCancelToken());
  };

  // ====================== DEAL API ======================
  const getListDeal = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/deals?${queryString}`, {}, newCancelToken());
  };

  const createDeal = (payload: AddDealPayload) => {
    if (payload.assignUserId === '') {
      delete payload.assignUserId;
    }

    return api.post(
      `/sales-svc/v1/deals`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const convertToLead = (leadId: Deal['id'][]) => {
    return api.post(`/sales-svc/v1/deals/deal-to-lead`, { ids: leadId }, newCancelToken());
  };

  const getDealDetail = (params: { id: Deal['id'] }) => {
    return api.get(`/sales-svc/v1/deals/${params.id}`, {}, newCancelToken());
  };

  const getUserActivities = (id: string) => {
    // const queryString = stringify(params);
    return api.get(`/sales-svc/v1/get-user-activity?id=${id}`, {}, newCancelToken());
  };

  const updateDeal = (payload: UpdateDealPayload) => {
    const { id } = payload;
    delete payload.id;
    delete payload.assignUserId;
    return api.put(`/sales-svc/v1/deals/${id}`, { ...payload }, newCancelToken());
  };

  const updateAssignAgent = (payload: AssignAgentsPayload) => {
    return api.post(
      `/sales-svc/v1/deals/assign-agents`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const archiveDeal = (leadId: Deal['id'][]) => {
    // return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
    return api.post(`/sales-svc/v1/deals/archive`, { ids: leadId }, newCancelToken());
  };

  const updateBillingDetail = (payload: UpdateBillingDetailPayload) => {
    // const { id } = payload;
    //delete payload.id;
    return api.put(`/sales-svc/v1/billing-detail`, { ...payload }, newCancelToken());
  };

  const sendEmail = (payload: SendApplicationForm) => {
    return api.post(
      `/sales-svc/v1/deals/send-application-file-email`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updatePolicyEffectiveDate = (payload: UpdatePolicyEffectiveDate) => {
    return api.post(
      `/sales-svc/v1/active-plan/policy-effective-date`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getListAccountRecord = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/active-plan/get-list-account-record?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const linkToAccount = (payload: LinkToAccount) => {
    return api.post(
      `/sales-svc/v1/active-plan/link-to-account`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const unlinkToAccount = (payload: UnlinkToAccount) => {
    return api.post(
      `/sales-svc/v1/active-plan/unlink-to-account`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const createAccountRecord = (payload: AddAccountRecordPayload) => {
    return api.post(
      `/sales-svc/v1/active-plan/create-account-record`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const createMemberRecords = (payload: CreateMemberRecordsPayload) => {
    return api.post(
      `/sales-svc/v1/member-record/create-member-record`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const confirmActivePlan = (payload: ConfirmActivePlan) => {
    const { id } = payload;
    return api.put(
      `/sales-svc/v1/active-plan/confirm-active-plan/${id}`,
      { ...payload },
      newCancelToken()
    );
  };

  const exportPolicyContract = (payload: ExportPolicyContract) => {
    const { id } = payload;
    return api.put(
      `/sales-svc/v1/share/export-policy-contract/${id}?utcOffset=${dayjs().utcOffset()}`,
      { ...payload },
      newCancelToken()
    );
  };

  const getAllDealsKanban = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/deals-kanban?${queryString}`, {}, newCancelToken());
  };

  const updateSAFForm = (payload: UpdateSAFForm) => {
    const { id } = payload;
    return api.put(`/sales-svc/v1/deals/saf-form/${id}`, { ...payload }, newCancelToken());
  };

  // ====================== Leads Management ======================
  const getAllLeads = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/leads?${queryString}`, {}, newCancelToken());
  };

  const getAllLeadsKanban = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/leads-kanban?${queryString}`, {}, newCancelToken());
  };

  const checkDuplicate = (params: {
    email: Lead['email'];
    firstName: Lead['firstName'];
    lastName: Lead['lastName'];
    birthday: Lead['birthday'];
    organization: Lead['organization'];
  }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/share/check-mail?${queryString}`, {}, newCancelToken());
  };

  const createLead = (payload: CRULeadInboxFormValue) => {
    return api.post(
      `/sales-svc/v1/leads`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const createMultipleLeads = (payload: { leads: LeadFromCSV[] }) => {
    return api.post(
      `/sales-svc/v1/leads/create-many`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateLead = (payload: CRULeadInboxFormValue) => {
    const { id } = payload;
    delete payload.id;
    return api.put(
      `/sales-svc/v1/leads/${id}`,
      {
        ...payload,
        isArchive: false,
      },
      newCancelToken()
    );
  };

  const getLead = (params: { id: Lead['id'] }) => {
    return api.get(`/sales-svc/v1/leads/${params.id}`, {}, newCancelToken());
  };

  const createInterest = (payload: CRUInterestPayLoad) => {
    return api.post(
      `/sales-svc/v1/generated`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const convertToDeal = (leadId: Lead['id'][]) => {
    // return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
    return api.post(`/sales-svc/v1/leads/lead-to-deal/`, { ids: leadId }, newCancelToken());
  };

  const archiveLead = (leadId: Lead['id'][]) => {
    // return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
    return api.post(`/sales-svc/v1/leads/archive/`, { ids: leadId }, newCancelToken());
  };

  const assignAgent = (payload: AssignAgentPayload) => {
    // return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
    return api.post(`/sales-svc/v1/leads/assign-agents/`, { ...payload }, newCancelToken());
  };

  const updateDealDuplicateStatus = (payload: UpdateDuplicatePayLoad) => {
    const { id } = payload;
    delete payload.id;

    return api.put(
      `/sales-svc/v1/deals/${id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateLeadDuplicateStatus = (payload: UpdateDuplicatePayLoad) => {
    const { id } = payload;
    delete payload.id;

    return api.put(
      `/sales-svc/v1/leads/${id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  //PLAN DATA API
  const getPlanData = (params: {
    planDataType: string;
    noDependents: string;
    age: any;
    isStudent: any;
  }) => {
    if (params.planDataType !== 'FAMILY_PLAN') {
      delete params['noDependents'];
    }
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/planData?${queryString}`, {}, newCancelToken());
  };

  const getPlanDataDefault = (params: { plansType: string; noDependents: string }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/planData-default?${queryString}`, {}, newCancelToken());
  };

  const getCoverageDefault = (params: { plansType: string }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/coverage-default?${queryString}`, {}, newCancelToken());
  };

  const getCoverage = (params: { planDataType: string }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/coverage?${queryString}`, {}, newCancelToken());
  };

  const getHealthQuestionaries = () => {
    return api.get(`/sales-svc/v1/health-questionnaire`, {}, newCancelToken());
  };

  const getContractNo = () => {
    // return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
    return api.post(`/sales-svc/v1/contract-number`, {}, newCancelToken());
  };

  //SAF FORM

  const createSAFForm = (payload: CreateSAFFormPayload) => {
    return api.post(
      `/sales-svc/v1/deals/create-saf-form`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };
  const getSAFForm = (payload: CreateSAFFormPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.get(`/sales-svc/v1/saf-form/${id}`);
  };

  const createMemberMasterList = (payload: CreateMemberMasterListPayload) => {
    return api.post(
      `/sales-svc/v1/member-master-list`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const exportSAFForm = (payload: ExportSAFForm) => {
    const { id } = payload;
    return api.put(
      `/sales-svc/v1/share/export-saf-form/${id}?utcOffset=${dayjs().utcOffset()}`,
      { ...payload },
      newCancelToken()
    );
  };
  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //

  //-----------------Approval------------------------------
  const additionalApproval = (payload: AdditionalApproval) => {
    return api.post(`/sales-svc/v1/deals/additional-approval`, { ...payload }, newCancelToken());
  };

  const addNoteApproval = (payload: AddNoteApproval) => {
    const { id } = payload;
    return api.put(`/sales-svc/v1/deals/approval/${id}`, { ...payload }, newCancelToken());
  };

  const deleteAdditionalApproval = (payload: DeleteAdditionalApproval) => {
    const { id } = payload;
    return api.delete(
      `/sales-svc/v1/deals/delete-additional-approval/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const updateSAFReadyForReview = (payload: UpdateReadyForReviewPayload) => {
    const { id } = payload;
    return api.put(`/sales-svc/v1/deals/ready-for-review/${id}`, { ...payload }, newCancelToken());
  };
  const updateMarkAComplete = (payload: UpdateMarkACompletePayload) => {
    const { id } = payload;
    return api.put(`/sales-svc/v1/deals/mark-complete/${id}`, { ...payload }, newCancelToken());
  };
  //-----------------Sale Dashboard------------------------------
  const getLeadsInformation = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/leads-information?${queryString}`, {}, newCancelToken());
  };

  const getSalesPerformance = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    if (queryString) {
      return api.get(`/sales-svc/v1/sales-performance?${queryString}`, {}, newCancelToken());
    }
  };

  const getDealsInformation = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/deal-information?${queryString}`, {}, newCancelToken());
  };

  const getPaymentsInformation = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/payments-information?${queryString}`, {}, newCancelToken());
  };

  const getBirthdaysInformation = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/birthday-information?${queryString}`, {}, newCancelToken());
  };

  const getSaleDashboard = (params: {
    from: Sale['from'];
    to: Sale['to'];
    previousFrom: Sale['previousFrom'];
    previousTo: Sale['previousTo'];
    userId: Sale['userId'];
    isAll: Sale['isAll'];
    isAllDate: Sale['isAllDate'];
  }) => {
    const queryString = stringify(params);
    if (queryString) {
      return api.get(`/sales-svc/v1/sales-dashboard?${queryString}`, {}, newCancelToken());
    }
  };

  //-----------------UPD Dashboard------------------------------

  const getUPDDashboard = (params: {
    from: UPD['from'];
    to: UPD['to'];
    isAllDate: UPD['isAllDate'];
  }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/upd-dashboard?${queryString}`, {}, newCancelToken());
  };
  const getUPDDeals = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/upd-deals?${queryString}`, {}, newCancelToken());
  };

  //-----------------ER Guard------------------------------
  //-----------------Companies------------------------------

  const getAllCompanies = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/companies-medicare?${queryString}`, {}, newCancelToken());
  };

  const getCompaniesById = (payload: Companies) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/companies-medicare/${id}`, {}, newCancelToken());
  };

  const postCompanies = (payload: Companies) => {
    return api.post(`/erguard-svc/v1/companies`, { ...payload }, newCancelToken());
  };

  const updateCompanies = (payload: Companies) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/erguard-svc/v1/companies/${id}`, { ...payload }, newCancelToken());
  };
  //-----------------Branches------------------------------

  const createBranch = (payload: CreateBranchPayload) => {
    return api.post(
      `/erguard-svc/v1/branches`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateBranch = (payload: UpdateBranchPayload) => {
    const { id } = payload;
    return api.put(`/erguard-svc/v1/branches/${id}`, { ...payload }, newCancelToken());
  };

  const getAllBranch = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/branches-medicare?${queryString}`, {}, newCancelToken());
  };

  const getListAgentForFilter = (params: { isArchive: string; isDeal: string }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/share/agent-for-filter?${queryString}`, {}, newCancelToken());
  };

  //-----------------Products------------------------------
  const createProduct = (payload: Product) => {
    return api.post(`/erguard-svc/v1/products-medicare`, { ...payload }, newCancelToken());
  };

  const updateProduct = (payload: Product) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/products-medicare/${id}`, { ...payload }, newCancelToken());
  };
  const getProducts = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/products-medicare?${queryString}`, {}, newCancelToken());
  };
  //-----------------Users------------------------------
  const getAllUsersManagement = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/users-medicare?${queryString}`, {}, newCancelToken());
  };

  const createERGuardUser = (payload: CreateERGuardUserPayload) => {
    return api.post(`/erguard-svc/v1/users-medicare`, { ...payload }, newCancelToken());
  };
  const updateERGuardUser = (payload) => {
    const { id } = payload;
    return api.put(`/erguard-svc/v1/users-medicare/${id}`, { ...payload }, newCancelToken());
  };

  const updateStatusERGuardUser = (payload) => {
    const { id } = payload;
    return api.delete(
      `/erguard-svc/v1/users-medicare/deactivate/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const getERGuardUserById = (payload: { id: string }) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/users-medicare/${id}`, {}, newCancelToken());
  };
  const resetPasswordERGuardUser = (payload: ResetPasswordByIdPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(
      `/erguard-svc/v1/users/resetPasswordByAdmin-medicare/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  //-----------------Roles------------------------------
  const getAllRole = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/roles-medicare?${queryString}`, {}, newCancelToken());
  };
  const getListRegion = () => {
    return api.get(`/account-svc/v1/region`, {});
  };
  const getListProvinces = () => {
    return api.get(`/account-svc/v1/provinces`, {});
  };
  const getListCities = () => {
    return api.get(`/account-svc/v1/cities`, {});
  };
  const getBranchOverview = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/overview-medicare?${queryString}`, {}, newCancelToken());
  };
  const getAllOrders = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/orders-medicare?${queryString}`, {}, newCancelToken());
  };
  const getOrderById = (payload: OrderDetailResponse) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/orders-medicare/${id}`, {}, newCancelToken());
  };
  const getUrlDownloadCIS = () => {
    return api.get('/erguard-svc/v1/share/download-cis-medicare', {}, newCancelToken());
  };
  const exportDigitalCard = (payload: { id: string }) => {
    const { id } = payload;
    return api.get(
      `/erguard-svc/v1/share/export-digital-card-medicare/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const sendActivationEmail = (payload: { id: string }) => {
    const { id } = payload;
    return api.get(
      `/erguard-svc/v1/share/send-activation-email-medicare/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const createOrder = (payload: OrderPayload) => {
    return api.post(`/erguard-svc/v1/orders-medicare`, { ...payload }, newCancelToken());
  };

  const deactivateCompany = (payload: Companies) => {
    const { id } = payload;
    return api.put(`/erguard-svc/v1/companies/deactivate/${id}`, { ...payload }, newCancelToken());
  };

  // create activation form
  const createActivationForm = (payload: ActivationFormPayload) => {
    return api.put(
      `/erguard-svc/v1/activate-online-order-online-shop`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };
  // Account Record
  const getAccountDetail = (payload: GetPropertiesParams) => {
    const newPayload = { ...payload };
    delete newPayload['id'];

    const queryString = stringify(newPayload);

    return api.get(
      `/sales-svc/v1/account-record/${payload.id}?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const voidTransaction = (payload: VoidTransactionPayload) => {
    return api.post(
      `/erguard-svc/v1/orders/void-requests-medicare`,
      { ...payload },
      newCancelToken()
    );
  };

  const updateOrderDetail = (payload: UpdateOrderDetailPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/orders-medicare/${id}`, { ...payload }, newCancelToken());
  };
  const getBranchById = (params: { id: BranchDetail['id'] }) => {
    return api.get(`/erguard-svc/v1/branches-medicare/${params.id}`, {}, newCancelToken());
  };
  const updateAccountRecord = (payload: UpdateAccountRecordPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/sales-svc/v1/account-record/${id}`, { ...payload }, newCancelToken());
  };

  const deleteAccountRecord = (id: DeleteAccountRecordPayload['id']) => {
    return api.delete(`/sales-svc/v1/account-record/${id}`, {}, newCancelToken());
  };

  const updateAccountStatus = (payload: UpdateStatusAccountPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(
      `/sales-svc/v1/account-record/update-status/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const getActivityLogsAccount = (params) => {
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/account-record-get-activity?${queryString}`,
      {},
      newCancelToken()
    );
  };
  const utilizedOrder = (payload: UtilizedOrderPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(
      `/erguard-svc/v1/orders/utilize-medicare/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const requestVoidTransaction = (payload: RequestVoidTransactionPayload) => {
    return api.post(`/erguard-svc/v1/void-transaction-medicare`, { ...payload }, newCancelToken());
  };

  const orderManagementUsers = () => {
    return api.get(`/account-svc/v1/order-management-users`, {}, newCancelToken());
  };

  const archiveOrder = (payload: string[]) => {
    return api.put(`/erguard-svc/v1/orders/archive-medicare`, { ...payload }, newCancelToken());
  };
  const approveVoidRequests = (payload: string[]) => {
    return api.put(
      `/erguard-svc/v1/orders/approve-void-requests`,
      { ...payload },
      newCancelToken()
    );
  };
  const activityLogsOrder = (payload: string) => {
    return api.get(`/erguard-svc/v1/orders/activity-log/${payload}`, {}, newCancelToken());
  };

  const getVoidRequest = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/orders/void-requests?${queryString}`, {}, newCancelToken());
  };

  const updateVoidTransaction = (payload: UpdateVoidTransactionPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/orders/void-requests/${id}`, { ...payload }, newCancelToken());
  };

  //Member record
  const getAllMemberRecord = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/member-record/get-member-record?${queryString}`,
      {},
      newCancelToken()
    );
  };
  const getMemberRecordDetail = (params) => {
    const { id } = params;
    return api.get(`/sales-svc/v1/member-record/${id}`, {}, newCancelToken());
  };

  const updateMemberRecordDetail = (payload: UpdateMemberRecordPayload) => {
    const { id } = payload;
    return api.put(`/sales-svc/v1/member-record/${id}`, { ...payload }, newCancelToken());
  };
  const updateMemberRecordStatus = (payload: UpdateMemberRecordStatusPayload) => {
    return api.put(`/sales-svc/v1/member-record-update-status`, { ...payload }, newCancelToken());
  };

  const getActivityLogsMember = (params) => {
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/member-record/get-member-activity?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const checkValidateHMO = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/member-record/check-validate-hmo?${queryString}`,
      {},
      newCancelToken()
    );
  };

  //Medical Dashboard
  const getMedicalDeals = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/medical-deals?${queryString}`, {}, newCancelToken());
  };

  const getMedicalDashboard = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/medical-dashboard?${queryString}`, {}, newCancelToken());
  };

  //Billing
  const getBillingListByMember = (payload: GetPropertiesParams) => {
    const { id } = payload;
    delete payload['id'];
    const queryString = stringify(payload);
    return api.get(
      `/sales-svc/v1/member-record/billing-details/${id}?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const useConfirmPaymentAccount = (payload: ConfirmPaymentPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(
      `/sales-svc/v1/account-record/confirm_payment/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  const confirmPaymentMember = (payload: ConfirmPaymentMemberPayload) => {
    delete payload['id'];
    return api.put(`/sales-svc/v1/member-record/confirm-payment`, { ...payload }, newCancelToken());
  };

  // Finance Dashboard
  const getFinanceDashboard = (params: {
    from: Finance['from'];
    to: Finance['to'];
    isAllDate: Finance['isAllDate'];
  }) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/finance-dashboard?${queryString}`, {}, newCancelToken());
  };
  const getBillingDetailAccountRecord = (params: GetPropertiesParams) => {
    const { id } = params;
    delete params['id'];
    const queryString = stringify(params);
    return api.get(
      `/sales-svc/v1/account-record/billing-details/${id}?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getListPaymentDetailAccount = (params: GetPropertiesParams) => {
    const { id } = params;
    delete params['id'];
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/account-record/confirm-payment-list/${id}?${queryString}`);
  };
  const getFinanceDeals = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/finance-deals?${queryString}`, {}, newCancelToken());
  };
  const getFinanceInitialPayments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/finance-initial-payment?${queryString}`, {}, newCancelToken());
  };
  const getFinancePayments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/finance-payment?${queryString}`, {}, newCancelToken());
  };
  const getFinanceTerms = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/finance-terms?${queryString}`, {}, newCancelToken());
  };
  //Payment
  const getAllPayment = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/order-payments?${queryString}`, {}, newCancelToken());
  };
  const massConfirmPayment = (payload: MassConfirmPaymentPayload) => {
    return api.post(`/erguard-svc/v1/payments/requests-payment`, { ...payload }, newCancelToken());
  };
  const getAllPaymentRequest = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/request-payments?${queryString}`);
  };
  const confirmationPaymentRequest = (payload: ConfirmationPayment) => {
    const { id } = payload;
    return api.put(
      `/erguard-svc/v1/payments/request-payment/${id}`,
      { ...payload },
      newCancelToken()
    );
  };

  const getAllUserPermissionPayment = () => {
    return api.get(`/account-svc/v1/payment-management-users`, {}, newCancelToken());
  };
  const getOrderPaymentDetail = (payload: OrderPaymentDetail) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/order-payments/${id}`, {}, newCancelToken());
  };
  const getOrderPaymentRequestDetail = (payload: OrderPaymentRequestDetail) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/request-payments/${id}`, {}, newCancelToken());
  };
  const getUrlExportARNumberExampleCSV = () => {
    return api.get('/sales-svc/v1/share/export-ar-number-example-csv', {}, newCancelToken());
  };

  const importARNumberOrderPayment = (payload: ImportARNumberPayload) => {
    return api.post(`/erguard-svc/v1/orders-confirmation`, { ...payload }, newCancelToken());
  };
  //unVoid
  const unvoidOrder = (payload: UnvoidPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(
      `/erguard-svc/v1/orders/unvoid-requests/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  //Configuration
  const getAllProductIDList = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/products?${queryString}`, {}, newCancelToken());
  };

  const getProductDetail = (params: GetPropertiesParams) => {
    const { id } = params;
    return api.get(`/sales-svc/v1/products/${id}`, {}, newCancelToken());
  };

  const updateProductDetail = (payload: UpdateProductPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/sales-svc/v1/products/${id}`, { ...payload }, newCancelToken());
  };

  //Benefit
  const getBenefitCategory = () => {
    return api.get('/sales-svc/v1/benefits/categories', {}, newCancelToken());
  };

  const getBenefitItemByCategory = (payload) => {
    const { code } = payload;
    return api.get(
      `/sales-svc/v1/benefits/benefit-items-by-category-code/${code}`,
      {},
      newCancelToken()
    );
  };

  const createBenefitItem = (payload: CreateBenefitItemPayload) => {
    return api.post(
      `/sales-svc/v1/benefits/benefit-item`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getBenefitList = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/benefits/benefit-items?${queryString}`, {}, newCancelToken());
  };

  const getBenefitDetail = (params: GetPropertiesParams) => {
    const { code } = params;
    return api.get(`/sales-svc/v1/benefits/benefit-item/${code}`, {}, newCancelToken());
  };

  const updateBenefitDetail = (payload: UpdateBenefitPayload) => {
    const { code } = payload;
    delete payload.code;
    return api.put(`/sales-svc/v1/benefits/${code}`, { ...payload }, newCancelToken());
  };

  const createProvider = (payload: CreateProviderPayload) => {
    return api.post(
      `/sales-svc/v1/benefits/benefit-item/provider`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteProvider = (id: DeleteProviderPayload['id']) => {
    return api.delete(`/sales-svc/v1/benefits/benefit-item/provider/${id}`, {}, newCancelToken());
  };

  // ====================== Notification ======================

  const updateTokenNotification = (payload: UpdateTokenPayload) => {
    return api.put(`/account-svc/v1/accept-push-notification`, { ...payload }, newCancelToken());
  };

  const disablePushNotification = (payload: UpdateTokenPayload) => {
    return api.put(`/account-svc/v1/disabled-push-notification`, { ...payload }, newCancelToken());
  };

  const getNotifications = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/notification?${queryString}`, {}, newCancelToken());
  };

  const markReadNotification = (payload: MarkReadNotificationPayload) => {
    const { id } = payload;
    delete payload.id;
    return api.put(`/account-svc/v1/mark-read/${id}`, { ...payload }, newCancelToken());
  };

  const markAllNotifications = () => {
    return api.put(`/account-svc/v1/mark-read-all`, {}, newCancelToken());
  };

  // ====================== System Search ======================

  const getSearchAllDocuments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/search-svc/v1/search-svc/search-all-documents?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getModuleSearch = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/search-svc/v1/search-svc/search-document?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getProductByProductCode = (params: { code: string }) => {
    return api.get(`/erguard-svc/v1/products-medicare/${params.code}`, {}, newCancelToken());
  };

  // API FOR ONLINE ORDER PAYMENT
  const getOnlineOrderPayments = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/online-order-payments?${queryString}`, {}, newCancelToken());
  };

  const getOnlineOrderPaymentDetail = (payload) => {
    const { id } = payload;
    return api.get(`/erguard-svc/v1/online-order-payments/${id}`, { ...payload }, newCancelToken());
  };

  const confirmOnlineOrderPayment = (payload) => {
    const { id } = payload;
    return api.put(`/erguard-svc/v1/online-order-payments/${id}`, { ...payload }, newCancelToken());
  };
  // ====================== Matrix ======================

  const getMatrixPlanType = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/plan-type-default?${queryString}`, {}, newCancelToken());
  };

  const getMatrixDetail = (params: GetPropertiesParams) => {
    const { id } = params;
    return api.get(`/sales-svc/v1/plan-data-default-by-plan-type/${id}`, {}, newCancelToken());
  };

  const getMatrixHistory = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/sales-svc/v1/plan-data-version-history?${queryString}`, {}, newCancelToken());
  };

  const createMatrix = (payload: CreateMatrix) => {
    return api.post(
      `/sales-svc/v1/create-new-plan-type-version`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };
  // Online Order
  const getAllOnlineOrder = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/online-orders?${queryString}`, {}, newCancelToken());
  };
  const activateOnlineOrder = (payload: ActivateOnlineOrderPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/activate-online-order/${id}`, { ...payload }, newCancelToken());
  };

  const getOnlineOrderDetail = (params: { id: OnlineOrderDetail['id'] }) => {
    const { id } = params;
    return api.get(`/erguard-svc/v1/orders-medicare/${id}`, {}, newCancelToken());
  };

  const updateOnlineOrder = (payload: UpdateOnlineOrderDetailPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/online-orders/${id}`, { ...payload }, newCancelToken());
  };

  const refundOnlineOrder = (payload: RefundOnlineOrderPayload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/refund-online-orders/${id}`, { ...payload }, newCancelToken());
  };
  const exportPolicyDocument = (payload) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(`/erguard-svc/v1/share/export-product-policy/${id}`, {});
  };
  const getMatrixByVersion = (payload) => {
    return api.get(
      `/sales-svc/v1/plan-data-version-history-by-name/`,
      { ...payload },
      newCancelToken()
    );
  };
  //ER Guard Dashboard
  const getERGuardDashBoardStatic = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/erguard-svc/v1/er-guard-dashboard?${queryString}`, {}, newCancelToken());
  };

  const getOrderERGuardDashboard = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/erguard-svc/v1/orders-er-guard-dashboard?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getTopTenBranches = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(
      `/erguard-svc/v1/er-guard-sales-performance?${queryString}`,
      {},
      newCancelToken()
    );
  };
  //Send Policy Document
  const sendPolicyContractDocuments = (payload: SendPolicyDocuments) => {
    return api.post(
      `/sales-svc/v1/deals/send-policy-contract-file-email`,
      { ...payload },
      newCancelToken()
    );
  };
  const getUrlExportHMOCardNumber = () => {
    return api.get(`/sales-svc/v1/share/export-hmo-example-csv`, {}, newCancelToken());
  };

  //Bulk HMO Card Number

  const bulkAssignHMOCard = (payload: BulkAssignHMOCardNumberPayload) => {
    return api.put(
      `/sales-svc/v1/account-record/bulk-assign-hmo-card`,
      { ...payload },
      newCancelToken()
    );
  };

  //Add Member
  const addMemberOnAccount = (payload: AddMemberPayload) => {
    return api.post(`/sales-svc/v1/add-member/create-saf-form`, { ...payload }, newCancelToken());
  };

  //Confirm Billing For CORPORATE
  const confirmBillingForCorporate = (payload: BillingInformationForCorporate) => {
    const { id } = payload;
    delete payload['id'];
    return api.put(
      `/sales-svc/v1/account-record/confirm_payment-corporate/${id}`,
      { ...payload },
      newCancelToken()
    );
  };
  return {
    getRoot,
    // ====================== Auth ======================
    // getPermission,
    confirmSignIn,
    signIn,

    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,
    // setPreferredMfa,
    completeNewPassword,

    signUp,
    resendSignUp,
    confirmSignUp,

    // ====================== File ======================
    getPresignedUserServiceUrl,
    uploadFile,
    uploadFileWithProgress,
    getDecodeUserServiceUrl,
    uploadAdditionalDoc,
    updateAdditionalDoc,
    deleteAdditonalDoc,
    uploadEnrollmentDoc,
    updateEnrollmentDoc,
    deleteEnrollmentDoc,
    getUrlExportMemberMasterList,
    getUrlExportLeadExampleCSV,
    // ====================== Notes ======================
    uploadNote,
    updateNote,
    deteleNote,
    // ====================== Content ======================
    getContents,
    getSalesManagerList,
    getSalesAgentList,
    getListRoles,
    getListSelectSales,
    getListAgentForFilter,
    // ====================== Profile ======================
    getMyProfile,
    // updateMyProfile,
    updateUserAvatar,
    updateMyProfile,
    confirmPassword,

    // ====================== System Accounts ======================
    searchUserAccounts,
    searchUserAccountsAxios,
    searchUserAccountsByOrderAxios,

    // ====================== System Accounts ======================
    getMyPermissions,

    // ====================== DEAL API ======================
    getListDeal,
    createDeal,
    convertToLead,
    getDealDetail,
    updateDeal,
    updateAssignAgent,
    archiveDeal,
    updateBillingDetail,
    sendEmail,
    getUserActivities,
    updatePolicyEffectiveDate,
    getListAccountRecord,
    linkToAccount,
    unlinkToAccount,
    createAccountRecord,
    confirmActivePlan,
    createMemberRecords,
    exportPolicyContract,
    getAllDealsKanban,
    updateSAFForm,
    // ====================== Leads Management ======================
    getAllLeads,
    getAllLeadsKanban,
    createLead,
    createMultipleLeads,
    updateLead,
    getLead,
    convertToDeal,
    archiveLead,
    assignAgent,
    updateDealDuplicateStatus,
    updateLeadDuplicateStatus,
    checkDuplicate,
    createInterest,

    // ====================== Plan data ======================
    getPlanData,
    getPlanDataDefault,
    getCoverageDefault,
    getCoverage,

    // ================= Health questionaries ============
    getHealthQuestionaries,
    getContractNo,

    //Create SAF Form
    createSAFForm,
    getSAFForm,
    createMemberMasterList,
    exportSAFForm,

    // ====================== Users Management ======================
    getUserId,
    getAllUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    searchUsers,
    searchProjects,
    changeFirstNewPassword,
    getStaffUser,

    // ====================== Users Permission ======================
    getPermissionList,
    getPermissionDefault,
    createNewRole,
    deleteRole,
    getDetailRole,
    updateRole,
    updateUserRole,

    // ====================== Teams Management ======================
    getAllTeams,
    getTeam,
    createTeam,
    updateTeam,
    addMember,
    removeMember,
    deleteTeam,
    // ====================== Account Record ======================
    getAccountDetail,
    updateAccountRecord,
    updateAccountStatus,
    deleteAccountRecord,
    getActivityLogsAccount,
    // ====================== Approval ======================
    additionalApproval,
    addNoteApproval,
    deleteAdditionalApproval,
    updateSAFReadyForReview,
    updateMarkAComplete,
    // ====================== Sales Dashboard ======================
    getLeadsInformation,
    getSaleDashboard,
    getSalesPerformance,
    getDealsInformation,
    getBirthdaysInformation,
    getPaymentsInformation,
    // ====================== Sales Dashboard ======================
    getUPDDashboard,
    getUPDDeals,
    // ====================== ER Guard ======================
    getAllCompanies,
    getCompaniesById,
    postCompanies,
    updateCompanies,
    deactivateCompany,
    createProduct,
    updateProduct,
    getProducts,
    createActivationForm,
    // ====================== ER Guard: Branch ======================
    createBranch,
    getAllBranch,
    updateBranch,
    // ====================== ER Guard: User ======================
    getAllUsersManagement,
    createERGuardUser,
    updateERGuardUser,
    updateStatusERGuardUser,
    resetPasswordERGuardUser,
    getERGuardUserById,
    // ====================== ER Guard: Role ======================
    getAllRole,
    getListRegion,
    getListProvinces,
    getListCities,
    getBranchOverview,
    getAllOrders,
    getOrderById,
    getUrlDownloadCIS,
    exportDigitalCard,
    sendActivationEmail,
    createOrder,
    voidTransaction,
    updateOrderDetail,
    getBranchById,
    // ====================== General Note ======================
    createNote,
    deleteNoteById,
    updateNoteById,
    // ====================== General Documents ======================
    uploadMultipleDocuments,
    updateDocumentById,
    deleteDocumentById,
    deleteMultipleDocuments,
    uploadMultipleDocumentsERGuard,
    //
    utilizedOrder,
    requestVoidTransaction,
    orderManagementUsers,
    archiveOrder,
    approveVoidRequests,
    activityLogsOrder,
    getVoidRequest,
    updateVoidTransaction,

    //member record
    getAllMemberRecord,
    getMemberRecordDetail,
    updateMemberRecordDetail,
    updateMemberRecordStatus,
    getActivityLogsMember,
    checkValidateHMO,

    //medical dashboard
    getMedicalDashboard,
    getMedicalDeals,

    //billing
    getBillingListByMember,
    confirmPaymentMember,
    useConfirmPaymentAccount,
    getBillingDetailAccountRecord,
    getListPaymentDetailAccount,

    // Finance Dashboard
    getFinanceDashboard,
    getFinanceDeals,
    getFinancePayments,
    getFinanceTerms,
    getFinanceInitialPayments,
    //payment
    getAllPayment,
    massConfirmPayment,
    getAllPaymentRequest,
    confirmationPaymentRequest,
    getAllUserPermissionPayment,
    getOrderPaymentDetail,
    getOrderPaymentRequestDetail,
    getUrlExportARNumberExampleCSV,
    importARNumberOrderPayment,
    //unvoid
    unvoidOrder,

    // ====================== Product ======================
    getAllProductIDList,
    getProductDetail,
    updateProductDetail,

    // ====================== Benefit ======================
    getBenefitCategory,
    getBenefitItemByCategory,
    createBenefitItem,
    getBenefitList,
    getBenefitDetail,
    updateBenefitDetail,
    createProvider,
    deleteProvider,

    // ====================== Notification ======================
    updateTokenNotification,
    disablePushNotification,
    getNotifications,
    markReadNotification,
    markAllNotifications,

    // ====================== System Search ======================
    getSearchAllDocuments,
    getModuleSearch,

    // get Product detail by product code
    getProductByProductCode,

    // online order payment
    getOnlineOrderPaymentDetail,
    getOnlineOrderPayments,
    confirmOnlineOrderPayment,
    // ====================== Matrix ======================
    getMatrixPlanType,
    getMatrixDetail,
    getMatrixHistory,
    getMatrixByVersion,
    createMatrix,

    //Online Order
    getAllOnlineOrder,
    activateOnlineOrder,
    getOnlineOrderDetail,
    updateOnlineOrder,
    refundOnlineOrder,
    exportPolicyDocument,

    //Dashboard ER Guard
    getERGuardDashBoardStatic,
    getOrderERGuardDashboard,
    getTopTenBranches,

    //Send Policy Documents
    sendPolicyContractDocuments,
    getUrlExportHMOCardNumber,

    //bulk assign hmo card number
    bulkAssignHMOCard,

    //Add member
    addMemberOnAccount,

    //Confirm Billing Corporate
    confirmBillingForCorporate,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
