import { Box } from '@mui/material';
import React from 'react';

const NoSearchIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <Box style={{ display: 'inline-flex', alignItems: 'center' }}>
      <svg
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_44176_43571)">
          <circle cx="71.2229" cy="73.5666" r="71.2229" fill="#F5FAFF" />
          <circle cx="71.2229" cy="73.5666" r="55.3956" fill="#E4F0FD" />
          <path
            d="M89.029 103.448H32.6552C31.1888 103.448 30 102.26 30 100.793V24.6552C30 23.1888 31.1888 22 32.6552 22H76.6726L91.6842 35.8569V100.793C91.6842 102.26 90.4955 103.448 89.029 103.448Z"
            fill="#F8F8F9"
            stroke="#90C0F7"
            stroke-width="2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.7851 39.0239C36.7851 38.0145 37.6034 37.1963 38.6127 37.1963H73.9995C75.0089 37.1963 75.8271 38.0145 75.8271 39.0239C75.8271 40.0332 75.0089 40.8515 73.9995 40.8515H38.6127C37.6034 40.8515 36.7851 40.0332 36.7851 39.0239ZM36.7851 50.2888C36.7851 49.2794 37.6034 48.4612 38.6127 48.4612H84.2731C85.2824 48.4612 86.1007 49.2794 86.1007 50.2888C86.1007 51.2981 85.2824 52.1164 84.2731 52.1164H38.6127C37.6034 52.1164 36.7851 51.2981 36.7851 50.2888ZM36.7851 61.5537C36.7851 60.5443 37.6034 59.7261 38.6127 59.7261H51.1693C52.1787 59.7261 52.9969 60.5443 52.9969 61.5537C52.9969 62.563 52.1787 63.3813 51.1693 63.3813H38.6127C37.6034 63.3813 36.7851 62.563 36.7851 61.5537ZM57.3323 61.5537C57.3323 60.5443 58.1505 59.7261 59.1599 59.7261H84.2731C85.2824 59.7261 86.1007 60.5443 86.1007 61.5537C86.1007 62.563 85.2824 63.3813 84.2731 63.3813H59.1599C58.1505 63.3813 57.3323 62.563 57.3323 61.5537ZM36.7851 72.8186C36.7851 71.8092 37.6034 70.991 38.6127 70.991H71.7165C72.7258 70.991 73.5441 71.8092 73.5441 72.8186C73.5441 73.8279 72.7258 74.6461 71.7165 74.6461H38.6127C37.6034 74.6461 36.7851 73.8279 36.7851 72.8186ZM77.8795 72.8186C77.8795 71.8092 78.6977 70.991 79.707 70.991H84.2731C85.2824 70.991 86.1007 71.8092 86.1007 72.8186C86.1007 73.8279 85.2824 74.6461 84.2731 74.6461H79.707C78.6977 74.6461 77.8795 73.8279 77.8795 72.8186ZM36.7851 82.957C36.7851 81.9476 37.6034 81.1294 38.6127 81.1294H80.8486C81.8579 81.1294 82.6761 81.9476 82.6761 82.957C82.6761 83.9663 81.8579 84.7846 80.8486 84.7846H38.6127C37.6034 84.7846 36.7851 83.9663 36.7851 82.957ZM36.7851 93.0954C36.7851 92.086 37.6034 91.2678 38.6127 91.2678H69.4335C70.4428 91.2678 71.261 92.086 71.261 93.0954C71.261 94.1047 70.4428 94.923 69.4335 94.923H38.6127C37.6034 94.923 36.7851 94.1047 36.7851 93.0954Z"
            fill="#B6D9FC"
          />
          <path
            d="M36.7851 39.0239C36.7851 38.0145 37.6033 37.1963 38.6127 37.1963H73.9995C75.0088 37.1963 75.8271 38.0145 75.8271 39.0239C75.8271 40.0332 75.0088 40.8515 73.9995 40.8515H38.6127C37.6033 40.8515 36.7851 40.0332 36.7851 39.0239Z"
            fill="#4684E7"
          />
          <path
            d="M77.0636 21L92.6842 35.4191H81.0636C78.8544 35.4191 77.0636 33.6282 77.0636 31.4191V21Z"
            fill="#90C0F7"
          />
          <rect
            width="17.2607"
            height="2.97599"
            rx="1.488"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 13.8719 31.6667)"
            fill="#72A8F0"
          />
          <rect
            width="17.2607"
            height="2.97599"
            rx="1.488"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 1.66669 33.771)"
            fill="#72A8F0"
          />
          <circle cx="136.65" cy="83.9153" r="4.22784" stroke="#72A8F0" stroke-width="3" />
          <circle cx="11.8477" cy="92.7071" r="1.30087" stroke="#72A8F0" stroke-width="7" />
          <path
            d="M98.1326 105.209L105.106 98.2358L133.841 122.762C137.36 125.766 137.572 131.132 134.3 134.404C131.029 137.675 125.663 137.464 122.659 133.944L98.1326 105.209Z"
            fill="#234BA6"
          />
          <path
            d="M103.084 111.01L122.659 133.945C125.663 137.464 131.029 137.676 134.3 134.404C137.572 131.132 137.36 125.766 133.841 122.762L108.239 100.91C107.209 104.625 105.436 108.044 103.084 111.01Z"
            fill="#4684E7"
          />
          <path
            d="M90.1702 63.3639L72.1461 105.209C71.8685 104.995 71.5935 104.772 71.3234 104.547C69.5813 103.074 68.0643 101.343 66.8224 99.4119L82.3909 63.2667C84.9702 62.842 87.6018 62.8749 90.1702 63.3639Z"
            fill="white"
          />
          <path
            d="M102.983 70.6235L86.0368 109.967C82.5168 109.97 79.0431 109.147 75.8844 107.559L94.3887 64.5959C97.6668 65.9065 100.607 67.9687 102.983 70.6235Z"
            fill="white"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.7346 69.1481C72.4531 71.2839 69.8538 74.3739 68.2762 78.0362C66.6984 81.699 66.2171 85.7624 66.8968 89.7097C67.5764 93.6568 69.3843 97.2998 72.0811 100.181C74.7771 103.062 78.2393 105.052 82.0254 105.911C85.8109 106.771 89.7608 106.464 93.379 105.027L93.3793 105.027C95.7828 104.072 97.9836 102.642 99.8542 100.812C101.725 98.983 103.229 96.7909 104.276 94.3593C105.324 91.9276 105.893 89.3061 105.951 86.6452C106.008 83.9842 105.552 81.3392 104.61 78.8619L104.61 78.8615C103.191 75.1304 100.727 71.9245 97.5392 69.6416C94.3524 67.3593 90.5833 66.0994 86.7086 66.0119C82.8339 65.9244 79.0152 67.013 75.7346 69.1481ZM62.2123 75.4241C64.2981 70.582 67.7467 66.4692 72.1329 63.6144C76.5201 60.759 81.6446 59.2933 86.8576 59.411C92.0706 59.5287 97.1256 61.2241 101.384 64.2736C105.641 67.3224 108.907 71.5853 110.781 76.5154C112.026 79.7893 112.627 83.2795 112.552 86.7875C112.476 90.2955 111.725 93.7559 110.34 96.9714C108.955 100.187 106.962 103.097 104.47 105.533C101.979 107.969 99.0391 109.883 95.816 111.163C90.9612 113.091 85.6523 113.505 80.5639 112.35C75.4761 111.195 70.8481 108.527 67.2603 104.693C63.6733 100.86 61.2861 96.0347 60.39 90.8301C59.4939 85.6258 60.1268 80.2655 62.2123 75.4241Z"
            fill="#4684E7"
          />
          <path
            d="M131.264 122.991L110.561 106.038C109.919 105.513 109.171 106.252 109.691 106.898L126.409 127.691C127.054 128.493 127.985 129.014 128.955 129.116L129.529 129.176C131.512 129.383 133.003 127.817 132.711 125.834L132.643 125.373C132.509 124.464 132.01 123.602 131.264 122.991Z"
            fill="#90C0F7"
          />
          <rect
            width="26.8577"
            height="4.63063"
            rx="2.31532"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 93.9913 75.0001)"
            fill="#72A8F0"
          />
          <rect
            width="26.8577"
            height="4.63063"
            rx="2.31532"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 75 78.2743)"
            fill="#72A8F0"
          />
          <rect
            width="15.0004"
            height="2.58628"
            rx="1.29314"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 130.138 45.7032)"
            fill="#72A8F0"
          />
          <rect
            width="15.0004"
            height="2.58628"
            rx="1.29314"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 119.531 47.5319)"
            fill="#72A8F0"
          />
          <rect
            width="12.722"
            height="2.19344"
            rx="1.09672"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 52.3553 127.734)"
            fill="#72A8F0"
          />
          <rect
            width="12.722"
            height="2.19344"
            rx="1.09672"
            transform="matrix(0.707107 0.707107 0.707107 -0.707107 43.3594 129.285)"
            fill="#72A8F0"
          />
        </g>
        <defs>
          <clipPath id="clip0_44176_43571">
            <rect width="150" height="150" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
};
export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default NoSearchIcon;
