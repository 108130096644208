export const MODULE_SEARCH = [
  { name: 'SYSTEM_USER', value: 'user' },
  { name: 'TEAM', value: 'team' },
  { name: 'LEAD', value: 'lead' },
  { name: 'DEAL', value: 'deal' },
  { name: 'ACCOUNT', value: 'account' },
  { name: 'MEMBER', value: 'member' },
  { name: 'ER_GUARD_ORDER', value: 'er_guard_order' },
  { name: 'ER_GUARD_BRANCH', value: 'er_guard_branch' },
  { name: 'ER_GUARD_COMPANY', value: 'er_guard_company' },
  { name: 'ER_GUARD_USER', value: 'er_guard_user' },
  { name: 'ER_GUARD_PRODUCT', value: 'er_guard_product' },
];

export const MODULE_CONTENT = [
  'team',
  'lead',
  'deal',
  'account',
  'member',
  'er_guard_order',
  'er_guard_branch',
  'er_guard_company',
  'er_guard_user',
  'er_guard_product',
];

export enum MODULE_CONTENT_KEY {
  TEAM = 'team',
  LEAD = 'lead',
  DEAL = 'deal',
  ACCOUNT = 'account',
  MEMBER = 'member',
  ORDER = 'er_guard_order',
  BRANCH = 'er_guard_branch',
  COMPANY = 'er_guard_company',
  ER_USER = 'er_guard_user',
  ER_PRODUCT = 'er_guard_product',
}

export enum SUB_TITLE_NAME {
  SYSTEM_USER = 'System User',
  TEAMS = 'Team Management',
  LEADS = 'Customers/Lead Inbox',
  DEALS = 'Customers/Deal Inbox',
  ACCOUNTS = 'Accounts & Members / Account Records',
  MEMBERS = 'Accounts & Members / Members Records',
  ER_GUARD_ORDERS = 'ER Guard / Order Management',
  ER_GUARD_BRANCH = 'ER Guard / Company Management',
  ER_GUARD_USERS = 'ER Guard / User Management',
  ER_GUARD_PRODUCT = 'ER Guard / Product Management',
}
