import { Box } from '@mui/material';
import React from 'react';
const PersonIcon: React.FC<Props> = ({
  children,
  className,
  size = 'is-normal',
  variant = 'is-primary',
  isLight = false,
  fullWidth,
  backgroundColor,
  color,
  status,
  ...props
}) => {
  return (
    <>
      <Box
        style={{
          backgroundColor: '#E0F1FF',
          width: '50px',
          height: '50px',
          borderRadius: '16px',
          padding: '17px',
        }}
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill={color}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.39504 1.01813C8.78691 0.361563 7.93754 0 7.00004 0C6.05753 0 5.20534 0.359375 4.60003 1.01187C3.98816 1.67156 3.69003 2.56813 3.76003 3.53625C3.89878 5.44625 5.35222 7 7.00004 7C8.64785 7 10.0988 5.44656 10.2397 3.53687C10.3107 2.5775 10.0107 1.68281 9.39504 1.01813Z"
            fill={color}
          />
          <path
            d="M12.5002 14H1.50018C1.3562 14.0019 1.21361 13.9716 1.08279 13.9115C0.951973 13.8513 0.836211 13.7627 0.743929 13.6522C0.540804 13.4094 0.458929 13.0778 0.519554 12.7425C0.783304 11.2794 1.60643 10.0503 2.90018 9.1875C4.04955 8.42156 5.50549 8 7.00018 8C8.49487 8 9.9508 8.42187 11.1002 9.1875C12.3939 10.05 13.2171 11.2791 13.4808 12.7422C13.5414 13.0775 13.4596 13.4091 13.2564 13.6519C13.1642 13.7625 13.0484 13.8511 12.9176 13.9113C12.7868 13.9715 12.6442 14.0018 12.5002 14Z"
            fill={color}
          />
        </svg>
      </Box>
    </>
  );
};

export type IconVariant =
  | 'is-black'
  | 'is-dark'
  | 'is-light'
  | 'is-white'
  | 'is-primary'
  | 'is-link'
  | 'is-info'
  | 'is-success'
  | 'is-warning'
  | 'is-danger'
  | 'is-customize';

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLOrSVGElement>,
  HTMLOrSVGElement
> & {
  variant?: IconVariant;
  isLight?: boolean;
  size?: 'is-normal' | 'is-medium' | 'is-large';
  fullWidth?: boolean;
  backgroundColor?: string;
  color?: string;
  className?: string;
  status?: string;
};
export default PersonIcon;
