import { Typography, useMediaQuery } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { muiResponsive } from 'src/appConfig/constants';
import EmptyTable from 'src/components/EmptyTable';
import { Accordion, Table } from 'src/components/common';
import Footer from 'src/components/common/Footer';
import { ModuleSearchData } from 'src/queries/SystemSearch/types';
import { useGetSearchDocument } from 'src/queries/SystemSearch/useGetSearchDocument';
import { PermissionsService } from 'src/services';
import {
  FILTER_GLOBAL_SEARCH_INDEX,
  MODULE_SEARCH_KEY,
  QUERY_KEY,
  SYSTEM_SEARCH_LOCALSTORAGE,
} from '../helpers';
import { allColumns } from './allColumns';
import NoPermissionSystemSearch from '../NoPermissionSystemSearch';
import NoPermission from 'src/containers/StartupContainers/NoPermission';
import dayjs from 'dayjs';

const DealSearch: React.FC<Props> = ({ searchText, isViewAll }) => {
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);

  const isTabletScreen = useMediaQuery(muiResponsive.TABLET);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const isView = PermissionsService.deal().canView;

  // const isCreate = PermissionsService.deal().canCreate;
  // const isUpdate = PermissionsService.deal().canUpdate;
  // const isDelete = PermissionsService.deal().canDelete;

  const medicalDashboard = PermissionsService.medDashboard().canView;
  const financeDashboard = PermissionsService.financeDashboard().canView;
  const medicalApproval = PermissionsService.approval().canUpdateMedical;
  const financeApproval = PermissionsService.approval().canUpdateFinance;
  const isViewByUPDManager = PermissionsService.approval().canUpdateUPDManager;
  const isViewByUPDOfficer = PermissionsService.approval().canUpdateUPDOfficer;
  const isViewByUPDSupervisor = PermissionsService.approval().canUpdateUPDSupervisor;
  const { searchResult, totalItems, setParams, isFetching } = useGetSearchDocument({});

  const convertSearchList = searchResult.map((item) => {
    return {
      id: item._id,
      name: item._source.fullName,
      organization: item._source.organization,
      totalPremium: '--',
      planType: item._source.planType,
      phoneNumber: item._source.phoneNumber,
      email: item._source.email,
      source: item._source.source,
      saleAgent: '--',
      assignedTo: '--',
      creationDate: item._source.createdAt,
      lastModifiedDate: item._source.updatedAt,
      dealStatus: item._source.status,
    };
  });

  //get filter from URL
  const filter = query.getAll(QUERY_KEY.filter);
  const lastModifiedDate = filter[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE];
  const creationDate = filter[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE];
  const status = filter[FILTER_GLOBAL_SEARCH_INDEX.STATUS];

  const sendDataToParent = (curPage, curRowsPerPage) => {
    setPage(curPage);
    setRowPerPage(curRowsPerPage);
    setParams({
      search: searchText,
      take: curRowsPerPage,
      skip: (curPage - 1) * curRowsPerPage,
      moduleName: MODULE_SEARCH_KEY.DEAL,
      createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
      lastUpdatedDate: lastModifiedDate ? dayjs(lastModifiedDate).format('YYYY-MM-DD') : undefined,
      status: status,
    });
    const systemSearch = localStorage.getItem(SYSTEM_SEARCH_LOCALSTORAGE);
    const parseSystemSearch = systemSearch ? JSON.parse(systemSearch) : {};
    const result = { ...parseSystemSearch };
    result[MODULE_SEARCH_KEY.DEAL] = {
      page: curPage,
      rowPerPage: curRowsPerPage,
    };
    localStorage.setItem(SYSTEM_SEARCH_LOCALSTORAGE, JSON.stringify(result));
  };

  const tableOptions: MUIDataTableOptions = React.useMemo(
    () => ({
      count: totalItems,
      onRowClick: () => {},
      rowHover: true,
      filter: false,
      fixedSelectColumn: false,
      searchAlwaysOpen: false,
      searchOpen: false,
      search: false,
      selectableRowsHeader: true,
      tableBodyMaxHeight: isTabletScreen ? '100%' : 'calc(100vh - 366px)', // content height
      pagination: false,
      customFooter: null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTabletScreen, convertSearchList]
  );

  const columns = React.useMemo(() => allColumns(), []);

  useEffect(() => {
    const systemSearchLocalStorage = localStorage.getItem(SYSTEM_SEARCH_LOCALSTORAGE);
    const parseSystemSearch = JSON.parse(systemSearchLocalStorage);
    if (systemSearchLocalStorage && parseSystemSearch[MODULE_SEARCH_KEY.DEAL]) {
      const newPagination = {
        page: parseSystemSearch[MODULE_SEARCH_KEY.DEAL].page,
        rowPerPage: parseSystemSearch[MODULE_SEARCH_KEY.DEAL].rowPerPage,
      };
      setPage(newPagination.page);
      setRowPerPage(newPagination.rowPerPage);
      setParams({
        search: searchText,
        take: newPagination.rowPerPage,
        skip: (newPagination.page - 1) * newPagination.rowPerPage,
        moduleName: MODULE_SEARCH_KEY.DEAL,
        createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
        lastUpdatedDate: lastModifiedDate
          ? dayjs(lastModifiedDate).format('YYYY-MM-DD')
          : undefined,
        status: status,
      });
    } else {
      setParams({
        search: searchText,
        take: rowPerPage,
        skip: (page - 1) * rowPerPage,
        moduleName: MODULE_SEARCH_KEY.DEAL,
        createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
        lastUpdatedDate: lastModifiedDate
          ? dayjs(lastModifiedDate).format('YYYY-MM-DD')
          : undefined,
        status: status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, creationDate, lastModifiedDate, status]);
  if (
    !(
      isView ||
      medicalDashboard ||
      medicalApproval ||
      financeDashboard ||
      financeApproval ||
      isViewByUPDManager ||
      isViewByUPDSupervisor ||
      isViewByUPDOfficer
    ) &&
    isViewAll
  ) {
    return (
      <NoPermissionSystemSearch
        isView={
          isView ||
          medicalDashboard ||
          medicalApproval ||
          financeDashboard ||
          financeApproval ||
          isViewByUPDManager ||
          isViewByUPDSupervisor ||
          isViewByUPDOfficer
        }
        totalItems={totalItems}
        index="Deals"
      />
    );
  }
  return (
    <React.Fragment>
      {isView ||
      medicalDashboard ||
      medicalApproval ||
      financeDashboard ||
      financeApproval ||
      isViewByUPDManager ||
      isViewByUPDSupervisor ||
      isViewByUPDOfficer ? (
        <Accordion
          title={
            <span style={{ fontSize: '18px', fontWeight: '600' }}>{`Deals (${
              totalItems ?? 0
            })`}</span>
          }
          isExpanded={!isViewAll}
          children={
            totalItems ? (
              <>
                <Table
                  noColorStyle={true}
                  title=""
                  onAction={() => {}}
                  isLoading={isFetching}
                  data={convertSearchList}
                  tableOptions={tableOptions}
                  columns={columns}
                  emptyComponent={<EmptyTable />}
                  customRowsPerPageOptions={appConfig.SECOND_ROWS_PER_PAGE_OPTIONS}
                  customRowsPerPage={appConfig.SECOND_ROWS_PER_PAGE}
                  forceUpdate={{}}
                />
                <Footer
                  totalItems={totalItems}
                  currPage={page}
                  currRowPerPage={rowPerPage}
                  sendDataToParent={sendDataToParent}
                />
              </>
            ) : (
              <Typography color={'#6D7176'} textAlign={'center'}>
                No result found
              </Typography>
            )
          }
        />
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  );
};

type Props = {
  items?: ModuleSearchData;
  searchText: string;
  isViewAll: boolean;
};

export default DealSearch;
