import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { Link } from 'react-router-dom';
import { PATHS } from 'src/appConfig/paths';
import ChipCommon from 'src/components/common/Chip';
import { convertText } from 'src/utils';
import { diffDays } from 'src/utils/handleUtils';
import { MEMBER_SEARCH_KEY } from './helpers';

export const allColumns = (): MUIDataTableColumn[] => [
  {
    name: MEMBER_SEARCH_KEY.MEMBER_ID,
    label: 'Member ID',
    options: {
      filter: false,
      sort: true,
      customBodyRender: (
        value: any,
        meta: MUIDataTableMeta | (Omit<MUIDataTableMeta, 'tableData'> & { tableData: any[] })
      ) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return (
          <Link
            to={{
              pathname: `${PATHS.accountAndMembers}${PATHS.memberRecords}/${rowData.id}`,
            }}
          >
            <Typography sx={{ fontSize: 14, color: '#4684E7' }} variant="body2">
              {value ?? '--'}
            </Typography>
          </Link>
        );
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.HMO,
    label: 'HMO Card No.',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value ?? '--'}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.NAME,
    label: 'Name',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.PLAN_TYPE,
    label: 'Plan Type',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{convertText(value)}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.MEMBER_STATUS,
    label: 'Member Status',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        return <ChipCommon typeOfChip={rowData.memberStatus} text={rowData.memberStatus} />;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.MEMBER_TYPE,
    label: 'Member Type',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{convertText(value)}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.MEMBER_PARTICIPATION,
    label: 'Member Participation',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{convertText(value)}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.ACCOUNT_NAME,
    label: 'Account Name',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return <div className={'body__medium'}>{value || '--'}</div>;
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.SUSPENSION_DATE,
    label: 'Suspension Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.CURRENT_POLICY_DATE,
    label: 'Current Policy Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.EXPIRY_DATE,
    label: 'Expiry Date',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        return (
          <div className={'body__medium'}>{value ? dayjs(value).format('MM/DD/YYYY') : '--'}</div>
        );
      },
    },
  },
  {
    name: MEMBER_SEARCH_KEY.DAY_TO_EXPIRY,
    label: 'Days to Expiry',
    options: {
      filter: false,
      sort: false,
      customBodyRender: (value: any, meta: MUIDataTableMeta) => {
        const rowData = meta.tableData[meta.rowIndex] as any;
        const expiryDate = rowData.expiryDate;
        const now = new Date(Date.now());
        return (
          <div className={'body__medium'}>{expiryDate ? diffDays(expiryDate, now) : '--'}</div>
        );
      },
    },
  },
];
