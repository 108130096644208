import { TableParams } from 'src/redux/types';

export enum SYSTEM_USER_KEY {
  NAME = 'name',
  EMAIL = 'email',
  ROLE = 'role',
  PHONE_NUMBER = 'phoneNumber',
  TEAM_NAME = 'teamName',
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[] | boolean;
};

export enum QUERY_KEY {
  SEARCH = 'gSearch',
}
