import { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import CheckMarkIcon from 'src/components/IconsCommon/CheckMarkIcon';

import './styles.scss';
import { useGetNotification } from 'src/queries/Notification/useGetNotification';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMarkReadAllNotifications } from 'src/queries/Notification/useMarkReadAllNotifications';
import { UPDATE_NOTIFICATION_KEY } from './helpers';
import BoxIcon from 'src/components/IconsCommon/BoxIcon';
import { Image, LoadingCommon } from 'src/components/common';
import { IMAGES } from 'src/appConfig/images';
import { useMarkReadNotificationById } from 'src/queries/Notification/useMarkReadNotificationById';
import { Navigator } from 'src/services';
import { QUERY_KEY } from '../../NotificationDetail/helpers';

const NotificationTableList = () => {
  const [notifications, setNotifications] = useState([]);
  const [isUnread, setIsUnread] = useState(null);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLastData, setIsLastData] = useState(false);
  const notificationRef = useRef<() => Promise<void>>();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const searchText = query.get('search') || '';
  dayjs.extend(relativeTime);

  const { markAllNotifications } = useMarkReadAllNotifications({
    onSuccess() {
      onGetNotifications();
    },
  });
  const { setParams, totalItems, onGetNotifications, isFetching } = useGetNotification({
    onSuccess(data) {
      setNotifications(data.data.data);
      setIsLoading(false);
    },
  });

  const handleChangePagination = (page, rowPerPage, newParams = {}) => {
    setParams({
      take: rowPerPage,
      skip: rowPerPage * (page - 1),
      search: searchText,
      unReadOnly: isUnread,
      isLast30Days: false,
      ...newParams,
    });
  };

  const updateNotification = async (data) => {
    switch (data.type) {
      case UPDATE_NOTIFICATION_KEY.MARK_ALL_NOTIFICATIONS:
        await markAllNotifications();
        break;
      case UPDATE_NOTIFICATION_KEY.SEE_UNREAD_NOTIFICATIONS:
        setIsUnread(true);
        handleChangePagination(1, 10, { unReadOnly: true });
        break;
      case UPDATE_NOTIFICATION_KEY.SEE_ALL_NOTIFICATIONS:
        setIsUnread(null);
        handleChangePagination(1, 10, { unReadOnly: null });
        break;
      default:
        break;
    }
  };

  const handleGetMoreNotification = async () => {
    setParams({
      take: (page + 1) * 10,
      skip: 0,
      search: searchText,
      unReadOnly: isUnread ? true : null,
    });
    setPage(page + 1);
  };

  const handleInfinitiveScroll = async () => {
    if (page * 10 > totalItems) {
      setIsLastData(true);
      return;
    }
    const wrapperContent = document.getElementById('notification-list');
    try {
      if (
        !isLoading &&
        Math.ceil(wrapperContent.scrollTop + wrapperContent.offsetHeight) + 1 >=
          wrapperContent.scrollHeight
      ) {
        setIsLoading(true);
        handleGetMoreNotification();
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleChangePagination(1, 10, { unReadOnly: isUnread, search: searchText });
  }, [searchText]);

  useEffect(() => {
    notificationRef.current = () => handleInfinitiveScroll();
  });

  useEffect(() => {
    onGetNotifications();
    document
      .getElementById('notification-list')
      ?.addEventListener('scroll', () => notificationRef.current());

    return () => {
      document
        .getElementById('notification-list')
        ?.removeEventListener('scroll', () => notificationRef.current());
    };
  }, []);
  const [isHover, setIsHover] = useState(false);
  const handleMouseOver = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const { updateMarkReadNotification } = useMarkReadNotificationById({
    onSuccess(data, variables, context) {
      onGetNotifications();
    },
  });

  const handleChangeMarkNotification = (e, item) => {
    e.stopPropagation();
    updateMarkReadNotification({
      id: item.id,
      isRead: !item.isRead,
    });
  };
  const handleChangeStatusNoti = (item) => {
    if (!item.isRead) {
      updateMarkReadNotification({
        id: item.id,
        isRead: !item.isRead,
      });
    }

    if (!item.pathType) return;
    if (item.pathType === 'ER_GUARD' && item.path === 'retail-order-payment-request') {
      Navigator.navigate(
        `/${item.pathType.replaceAll('_', '-').toLowerCase()}/${item.path}/${
          item.detailId ? item.detailId : ''
        }?${QUERY_KEY.showPopUp}=${item.detailId}`
      );
    } else {
      Navigator.navigate(
        `/${(item.pathType === 'ACCOUNT_MEMBER' ? 'ACCOUNT_AND_MEMBER' : item.pathType)
          .replaceAll('_', '-')
          .toLowerCase()}/${item.path}/${item.detailId ? item.detailId : ''}`
      );
    }
  };

  const isShowMarkAllAsRead = notifications?.some((item) => !item?.isRead);

  const renderNotificationsData = () => {
    if (!notifications?.length && !isFetching)
      return (
        <div
          style={{
            padding: 75,
            textAlign: 'center',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={IMAGES.noNotification} style={{ width: '180px', height: '180px' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography mt={1} sx={{ width: '260px' }}>
              You've <span style={{ fontWeight: 600 }}>read</span>
              {` all your notifications from the
            last 30 days.`}
            </Typography>
          </div>
        </div>
      );
    return (
      <div className="content">
        {notifications?.map((item, index) => (
          <div
            onClick={() => {
              handleChangeStatusNoti(item);
            }}
            aria-hidden="true"
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            className="notification-detail"
            key={index}
          >
            <div className="noti-detail">
              <Typography>{item.description}</Typography>
              <Typography fontSize={12} style={{ color: !item.isRead ? '#4684E7' : '#91979E' }}>
                {dayjs(item?.createdAt).fromNow()}
              </Typography>
            </div>
            <div className="right">
              <Tooltip
                arrow={true}
                title={`Mark as ${!item.isRead ? 'read' : 'unread'}`}
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      maxWidth: 170,
                      fontSize: 12,
                      fontWeight: 100,
                    },
                  },
                }}
              >
                <IconButton
                  style={{ padding: 0 }}
                  onClick={(e) => handleChangeMarkNotification(e, item)}
                >
                  <div
                    style={{
                      display: 'block',
                      width: 16,
                      height: 16,
                      border: '1px solid #CFD4D9',
                      borderColor: isLoading ? '#4684E7' : '#CFD4D9',
                      backgroundColor: 'transparent',
                      borderRadius: '50%',
                      position: 'relative',
                      visibility: isHover ? 'visible' : 'hidden',
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '50%',
                        width: 8,
                        height: 8,
                        backgroundColor: '#4684E7',
                        position: 'absolute',
                        top: 'calc(50% - 4px)',
                        left: 'calc(50% - 4px)',
                        visibility: item.isRead ? 'hidden' : 'visible',
                      }}
                    />
                  </div>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))}
        {isFetching && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
            }}
          >
            <LoadingCommon loadingStyle={6} size="medium" />
          </div>
        )}
        {isLastData && (
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: 240, textAlign: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image src={IMAGES.noNotification} />
              </div>
              <Typography mt={1}>
                You've <span style={{ fontWeight: 600 }}>read</span> all your notifications from the
                last 30 days.
              </Typography>
            </div>
          </Grid>
        )}
      </div>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Header
          updateNotification={updateNotification}
          isUnread={isUnread}
          isShowMark={isShowMarkAllAsRead}
        />
      </Grid>

      <Grid item xs={12} mt={'24px '}>
        <div className="show-list" id="notification-list">
          {renderNotificationsData()}
        </div>
      </Grid>
    </Grid>
  );
};

export default NotificationTableList;
