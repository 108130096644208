import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  tabsClasses,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MODULE_SEARCH } from 'src/components/AppBar/GlobalSearch/helpers';
import NoSearchIcon from 'src/components/IconsCommon/NoSearchIcon';
import { LoadingCommon } from 'src/components/common';
import { useGetSearchAllDocuments } from 'src/queries/SystemSearch/useGetSearchAllDocuments';
import AccountSearch from './AccountSearch';
import AllSystemSearch from './AllSystemSearch';
import DealSearch from './DealSearch';
import ERGuardBranch from './ERGuardBranch';
import ERGuardCompany from './ERGuardCompany';
import ERGuardOrder from './ERGuardOrder';
import ERGuardUser from './ERGuardUser';
import LeadSearch from './LeadSearch';
import MemberSearch from './MemberSearch';
import ProductSearch from './ProductSearch';
import SystemUser from './SystemUser';
import TeamsSearch from './TeamsSearch';
import Header from './header';
import { FILTER_GLOBAL_SEARCH_INDEX, TabSearchList } from './helpers';
import { Navigator } from 'src/services';
import { PATHS } from 'src/appConfig/paths';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

enum QUERY_KEY {
  SEARCH = 'gSearch',
  FILTER = 'filter',
}

type LocationState = {
  previousPath?: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: '24px 0' }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const GlobalSearch: React.FC<Props> = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as LocationState;
  const previousPath = state?.previousPath || '';
  const query = new URLSearchParams(location.search);
  const searchText = query.get(QUERY_KEY.SEARCH) || '';
  const [search, setSearch] = useState(searchText);
  const [tab, setTab] = useState(0);
  const [previousUrl, setPreviousUrl] = useState('');
  const filter = query.getAll(QUERY_KEY.FILTER);
  const {
    searchDocuments,
    setParams,
    isFetching: isLoading,
  } = useGetSearchAllDocuments({
    onSuccess() {},
  });

  const searchResult = MODULE_SEARCH.reduce((result, item) => result.concat(item.value), []);

  const availableSystemSearch = searchDocuments?.filter((module) =>
    searchResult.includes(module.moduleName)
  );

  const totalAllSearchItems = availableSystemSearch?.reduce(
    (sum, item) => sum + item.totalRecord,
    0
  );

  const newTabList = TabSearchList.map((tab) => {
    const selected = availableSystemSearch?.find((module) => module.moduleName === tab.key);

    return {
      ...tab,
      totalItems: selected?.totalRecord ?? 0,
    };
  });

  const handleChange = (event, value) => {
    query.set(QUERY_KEY.SEARCH, searchText);
    history.push({ search: query.toString() });
    setTab(value);
  };

  useEffect(() => {
    const currentLocation = history.location;
    const unlisten = history.listen((location, action) => {
      setPreviousUrl(`${currentLocation.pathname}${currentLocation.search}`);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const handlePreviousPage = () => {
    if (previousUrl) {
      Navigator.navigate(previousUrl);
    } else {
      Navigator.navigate(PATHS.systemSearch);
    }
  };
  const lastModifiedDate = filter[FILTER_GLOBAL_SEARCH_INDEX.LAST_MODIFIED_DATE];
  const creationDate = filter[FILTER_GLOBAL_SEARCH_INDEX.CREATION_DATE];
  const status = filter[FILTER_GLOBAL_SEARCH_INDEX.STATUS];
  useEffect(() => {
    setSearch(searchText);
    setParams({
      search: searchText,
      createdDate: creationDate ? dayjs(creationDate).format('YYYY-MM-DD') : undefined,
      lastUpdatedDate: lastModifiedDate ? dayjs(lastModifiedDate).format('YYYY-MM-DD') : undefined,
      status: status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, creationDate, lastModifiedDate, status]);

  return (
    <Box py={1} maxHeight={'100vh'}>
      <Container maxWidth="xl">
        <Card>
          <Grid container style={{ height: isLoading ? 'calc(100vh - 150px)' : 'auto' }}>
            {isLoading ? (
              <LoadingCommon
                loadingStyle={6}
                size="normal"
                style={{
                  minHeight: '28px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              />
            ) : totalAllSearchItems ? (
              <>
                <Grid item xs={12}>
                  <Header searchText={search} totalItems={totalAllSearchItems} />
                </Grid>
                <Grid item xs={12} mt={'24px'}>
                  <div className="search-list" id="notification-list">
                    <div
                      style={{ borderBottom: '1px solid', borderColor: 'rgba(0, 0, 0, 0.12)' }}
                      id="tab-search"
                    >
                      <Tabs
                        value={tab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        TabIndicatorProps={{
                          style: { background: '#4684E7' },
                        }}
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3, display: 'none' },
                          },
                          '&.MuiTabs-indicator': {
                            backgroundColor: '#4684E7',
                          },
                          '&.MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                          },
                        }}
                        ScrollButtonComponent={(props) => {
                          return (
                            <ButtonBase component="div" {...props}>
                              {props?.direction === 'left' ? (
                                <KeyboardArrowLeft fontSize="large" color="secondary" />
                              ) : (
                                <KeyboardArrowRight fontSize="large" color="secondary" />
                              )}
                            </ButtonBase>
                          );
                        }}
                      >
                        {newTabList.map((item) => (
                          <Tab
                            key={item.id}
                            label={`${item.label}${
                              item.label === 'All' ? '' : ` (${item.totalItems})`
                            }`}
                            id={`simple-tab-${item.id}`}
                            aria-controls={`simple-tabpanel-${item.id}`}
                          />
                        ))}
                      </Tabs>
                    </div>
                    <TabPanel value={tab} index={0}>
                      <AllSystemSearch searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                      <SystemUser isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={2}>
                      <TeamsSearch isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={3}>
                      <LeadSearch isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={4}>
                      <DealSearch isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={5}>
                      <AccountSearch isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={6}>
                      <MemberSearch isViewAll={false} searchText={search} />
                    </TabPanel>
                    <TabPanel value={tab} index={7}>
                      <ERGuardOrder isViewAll={false} />
                    </TabPanel>
                    <TabPanel value={tab} index={8}>
                      <ERGuardBranch isViewAll={false} />
                    </TabPanel>
                    <TabPanel value={tab} index={9}>
                      <ERGuardCompany isViewAll={false} />
                    </TabPanel>
                    <TabPanel value={tab} index={10}>
                      <ERGuardUser isViewAll={false} />
                    </TabPanel>
                    <TabPanel value={tab} index={11}>
                      <ProductSearch isViewAll={false} searchText={searchText} />
                    </TabPanel>
                  </div>
                </Grid>
              </>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: '50vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NoSearchIcon />
                <Typography
                  color={'#2D2F31'}
                  fontSize={16}
                  fontWeight={600}
                  my={1}
                >{`No Results Found for “${searchText}”`}</Typography>
                <Typography fontSize={14} color={'#484C4F'} fontWeight={400} my={1}>
                  Try different keywords, fewer of them, or ones that are more general.
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ margin: '10px 0' }}
                  onClick={handlePreviousPage}
                >
                  Click here to return to the previous page
                </Button>
              </div>
            )}
          </Grid>
        </Card>
      </Container>
    </Box>
  );
};

type Props = { children?: any };

export default GlobalSearch;
