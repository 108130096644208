import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { DatePicker, Input, InputPhone, Select } from 'src/components/common';
import { useGetListCities } from 'src/queries/Interest/useGetListCities';
import { useGetListProvinces } from 'src/queries/Interest/useGetListProvinces';
import { useGetListRegion } from 'src/queries/Interest/useGetListRegion';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { IRootState } from 'src/redux/rootReducer';
import { _calculateAge, daysFromToday, objectToCitiesOptions } from 'src/utils';
import { isEmpty } from 'src/validations';
import {
  CIVIL_STATUS_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
  ER_GUARD_ACTIVATION_FORM_KEY,
  GENDER_OPTIONS,
  getErrorMessage,
  objectToAddressOptions,
  objectToProvinceOptions,
} from '../helpers';

const FormActivation = (props: Props) => {
  const { formikProps } = props;

  const { values, errors, touched, getFieldProps, setFieldValue, setFieldTouched } = formikProps;
  const _getErrorMessage = (fieldName: ER_GUARD_ACTIVATION_FORM_KEY) => {
    return getErrorMessage(fieldName, { touched, errors });
  };
  const { regions: listRegions, onGetListRegions } = useGetListRegion({
    onSuccess(data) {
      setRegions(data?.data?.data);
    },
  });
  const { provinces: listProvinces, onGetListProvinces } = useGetListProvinces({
    onSuccess(data) {
      setProvinces(data?.data?.data);
    },
  });
  const { cities: listCities, onGetListCities } = useGetListCities({
    onSuccess(data) {
      setCities(data?.data?.data);
    },
  });
  const [cities, setCities] = useState(listCities?.data?.data);
  const [provinces, setProvinces] = useState<any>(listProvinces?.data?.data);
  const [regions, setRegions] = useState<any>(listRegions?.data?.data);

  useEffect(() => {
    onGetListRegions();
    onGetListCities();
    onGetListProvinces();
  }, [onGetListCities, onGetListProvinces, onGetListRegions]);

  const radioOptions = [
    {
      label: 'Yes',
      value: 'YES',
    },
    {
      label: 'No',
      value: 'NO',
    },
  ];
  const handleChangeRadio = (value) => {
    setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.IS_THIS_A_GIFT, value?.target?.value);
  };

  const handleOnchangeCity = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY, null);
    } else {
      if (value.regionId || value.region.id) {
        setRegions(listRegions?.data?.data.filter((x) => x.id === value.regionId));
        setCities(
          listCities?.data?.data.filter(
            (x) => x.regionId === listRegions?.data?.data.find((x) => x.id === value.regionId).id
          )
        );
      }
    }
    if (value?.provinceId || value?.province?.id) {
      setProvinces(
        listProvinces?.data?.data?.filter((item) =>
          item?.city?.filter((city) => city?.id === value?.id)
        )
      );
      setCities(listCities?.data?.data?.filter((item) => item?.provinceId === value?.provinceId));
    }
    if (!isEmpty(values.region) && !isEmpty(values.province)) {
      setCities(
        listCities?.data?.data.filter(
          (x) => x.regionId === values.region.id && x.provinceId === values.province.id
        )
      );
    }
  };
  const handleOnchangeProvince = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY, null);
    }
  };

  const handleOnchangeRegion = (name, value) => {
    setFieldValue(name, value);
    if (value === null) {
      setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY, null);
      setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.PROVINCE, null);
      setCities(listCities?.data?.data);
      setProvinces(listProvinces?.data?.data);
      setRegions(listRegions?.data?.data);
    }
  };

  useEffect(() => {
    if (!isEmpty(values.municipality) && !isEmpty(values.region)) {
      if (values.municipality.province === null) {
        setProvinces([]);
      } else {
        setProvinces(
          listProvinces?.data?.data.filter(
            (x) =>
              x.id ===
              listProvinces?.data?.data
                .filter((x) => x.regionId === values.region.id)
                .map((x) => x.city)
                .flat(1)
                .find((x) => x.id === values.municipality.id).provinceId
          )
        );
        setFieldValue(
          ER_GUARD_ACTIVATION_FORM_KEY.PROVINCE,
          listProvinces?.data?.data.find(
            (x) =>
              x.id ===
              listProvinces?.data?.data
                .filter((x) => x.regionId === values.region.id)
                .map((x) => x.city)
                .flat(1)
                .find((x) => x.id === values.municipality.id).provinceId
          )
        );
      }
    } else if (!isEmpty(values.province) && !isEmpty(values.region)) {
      setCities(
        listCities?.data?.data.filter(
          (x) => x.regionId === values.region.id && x.provinceId === values.province.id
        )
      );
    } else if (!isEmpty(values.municipality) && !isEmpty(values.province)) {
      setRegions(listRegions?.data?.data.filter((x) => x.id === values.municipality.id));
      setFieldValue(
        ER_GUARD_ACTIVATION_FORM_KEY.REGION,
        listRegions?.data?.data.find((x) => x.id === values.municipality.id)
      );
    } else if (isEmpty(values.municipality) && isEmpty(values.region) && isEmpty(values.province)) {
      setCities(listCities?.data?.data);
      setProvinces(listProvinces?.data?.data);
      setRegions(listRegions?.data?.data);
    } else if (
      isEmpty(values.municipality) &&
      isEmpty(values.province) &&
      !isEmpty(values.region)
    ) {
      setProvinces(listProvinces?.data?.data.filter((x) => x.regionId === values.region.id));
      setCities(listCities?.data?.data.filter((x) => x.regionId === values.region.id));
    } else if (
      isEmpty(values.municipality) &&
      !isEmpty(values.province) &&
      isEmpty(values.region)
    ) {
      setCities(listCities?.data?.data.filter((x) => x.provinceId === values?.province.id));
      setRegions(
        listRegions?.data?.data.filter((x) =>
          x.province.map((x) => x.id).includes(values?.province?.id)
        )
      );
      setFieldValue(
        ER_GUARD_ACTIVATION_FORM_KEY.REGION,
        listRegions?.data?.data.find((x) =>
          x.province.map((x) => x.id).includes(values?.province?.id)
        )
      );
    } else if (
      !isEmpty(values.municipality) &&
      isEmpty(values.province) &&
      isEmpty(values.region)
    ) {
      setFieldValue(
        ER_GUARD_ACTIVATION_FORM_KEY.REGION,
        listRegions?.data?.data.find((x) => x.id === values?.municipality?.regionId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.region, values.municipality, values.province]);

  const handleChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/; //rules
    if (e.currentTarget.value === '' || re.test(e.currentTarget.value)) {
      setFieldValue(ER_GUARD_ACTIVATION_FORM_KEY.SECURITY_CODE, e.currentTarget.value);
    }
  };
  useEffect(() => {
    if (dayjs(values.birthday, 'MM/DD/YYYY', true).isValid()) {
      if (
        !(
          daysFromToday(new Date(values.birthday)) > 15 &&
          _calculateAge(new Date(values.birthday)) < 60
        )
      ) {
        setFieldTouched(ER_GUARD_ACTIVATION_FORM_KEY.BIRTHDAY);
      }
    }
  }, [setFieldTouched, values.birthday]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '80px' }}>
          <Typography variant="h2" textAlign={'center'}>
            ER Guard Activation Form
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingTop: '8px' }}>
          <div
            className="gradient-line-bar-css"
            style={{
              backgroundImage: `url(${IMAGES.linearGradientBackground})`,
              width: '100%',
              height: '17px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="Reference Number"
            required
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.REFERENCE_NUMBER)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.REFERENCE_NUMBER)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="Security Code"
            required
            value={values?.securityCode}
            onChange={handleChangeInput}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.SECURITY_CODE)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.SECURITY_CODE)}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" fontWeight={'600'}>
            Cardholder's Details
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="First Name"
            required
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.FIRST_NAME)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.FIRST_NAME)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="Middle Name"
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.MIDDLE_NAME)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.MIDDLE_NAME)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="Last Name"
            required
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.LAST_NAME)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.LAST_NAME)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            name={ER_GUARD_ACTIVATION_FORM_KEY.GENDER}
            options={GENDER_OPTIONS}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.GENDER)}
            label={'Gender'}
            hideSearchIcon
            required
            onChange={(name, option) => setFieldValue(name, option)}
            onBlur={(name) => setFieldTouched(name)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.GENDER)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <DatePicker
            required
            formatISODate
            label="Birthday"
            placeholder="MM/DD/YYYY"
            name={ER_GUARD_ACTIVATION_FORM_KEY.BIRTHDAY}
            maxDate={new Date(Date.now())}
            selected={values?.birthday ? new Date(values?.birthday) : undefined}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.BIRTHDAY)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.BIRTHDAY)}
            onChange={setFieldValue}
            onBlur={setFieldTouched}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            label="Email Address"
            required
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.EMAIL)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.EMAIL)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <InputPhone
            label="Phone Number"
            required
            placeholder="Phone Number"
            name={ER_GUARD_ACTIVATION_FORM_KEY.CONTACT_NUMBER}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.CONTACT_NUMBER)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.CONTACT_NUMBER)}
            onChange={setFieldValue}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" className="heading-xs" fontWeight={'600'} marginBottom={1}>
            Address
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.BLK)}
            className="cs-input-cls"
            label={'Blk/Lot No.'}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.BLK)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.STREET)}
            label={'Street'}
            placeholder=""
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.STREET)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.BARANGAY)}
            label={'Barangay'}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.BARANGAY)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            required
            name={ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY}
            hideSearchIcon
            label="City/Municipality"
            options={objectToCitiesOptions(cities)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.MUNICIPALITY)}
            onChange={handleOnchangeCity}
            onBlur={setFieldTouched}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            required
            name={ER_GUARD_ACTIVATION_FORM_KEY.PROVINCE}
            hideSearchIcon
            label="Province"
            options={objectToProvinceOptions(provinces)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.PROVINCE)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.PROVINCE)}
            onChange={handleOnchangeProvince}
            onBlur={setFieldTouched}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            required
            name={ER_GUARD_ACTIVATION_FORM_KEY.REGION}
            hideSearchIcon
            label="Region"
            options={objectToAddressOptions(regions)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.REGION)}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.REGION)}
            onChange={handleOnchangeRegion}
            onBlur={setFieldTouched}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.ZIP_CODE)}
            label="Zip Code"
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.ZIP_CODE)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12} />
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            name={ER_GUARD_ACTIVATION_FORM_KEY.CIVIL_STATUS}
            options={CIVIL_STATUS_OPTIONS}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.CIVIL_STATUS)}
            label={'Civil Status'}
            hideSearchIcon
            onChange={(name, option) => setFieldValue(name, option)}
            onBlur={(name) => setFieldTouched(name)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.CIVIL_STATUS)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.NATIONALITY)}
            label="Nationality"
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.NATIONALITY)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Select
            name={ER_GUARD_ACTIVATION_FORM_KEY.EMPLOYMENT_TYPE}
            options={EMPLOYMENT_TYPE_OPTIONS}
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.EMPLOYMENT_TYPE)}
            label={'Employment Type'}
            hideSearchIcon
            onChange={(name, option) => setFieldValue(name, option)}
            onBlur={(name) => setFieldTouched(name)}
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.EMPLOYMENT_TYPE)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Input
            errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.COMPANY_NAME)}
            label="Company Name"
            {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.COMPANY_NAME)}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          {'Is this a gift?'}
          <RadioGroup onChange={(value) => handleChangeRadio(value)}>
            {radioOptions.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <FormControlLabel
                    value={item.value}
                    control={<Radio sx={{ '&.Mui-checked': { color: '#4684E7' } }} />}
                    label={item.label}
                  />
                </React.Fragment>
              );
            })}
          </RadioGroup>
          {values.isThisAGift === 'YES' && (
            <Input
              errorMessage={_getErrorMessage(ER_GUARD_ACTIVATION_FORM_KEY.NAME_OF_GIVER)}
              label="Name of Giver"
              {...getFieldProps(ER_GUARD_ACTIVATION_FORM_KEY.NAME_OF_GIVER)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export interface FormActivationProps {
  formikProps: any;
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & FormActivationProps;

const mapStateToProps = (state: IRootState) => ({
  //contents: state.common.contents,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
};
export default connect(mapStateToProps, mapDispatchToProps)(FormActivation);
