/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'react-redux';
import { useContents } from 'src/queries';
import { useGetSalesAgentList } from 'src/queries/Contents/useGetSalesAgentList';
import { useGetListSalesManager } from 'src/queries/Contents/useGetSalesManagerList';
import { setAssignAgentList, setContents, setSalesManagerList } from 'src/redux/common/commonSlice';
// import { getAllEventsAsync, getMyEventsAsync } from 'src/redux/eventsRedux/actions';
import { IRootState } from 'src/redux/rootReducer';

const ContentContainer: React.FC<Props> = ({
  onSetContents,
  isAuthenticated,
  contents,
  onSetAssignAgentList,
  onSetSalesManagerList,
  agentList,
  managerList,
}) => {
  useContents({
    enabled: !!isAuthenticated,
    onSuccess: async (data) => {
      onSetContents(data);
      // When called api success we will get the id of reponse in here and use is for call api get list
    },
  });

  // useGetListRoles({
  //   enabled: !!isAuthenticated,
  //   onSuccess: async (data) => {
  //   },
  // });

  useGetListSalesManager({
    roles: contents.roleSaleManagerId,
    onSuccess: async (data) => {
      onSetSalesManagerList(data);
    },
  });

  useGetSalesAgentList({
    roles: contents.roleSaleAgentId,
    onSuccess: async (data) => {
      onSetAssignAgentList(data);
    },
  });

  return null;
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  contents: state.common.contents,
  agentList: state.common.assignAgentList,
  managerList: state.common.salesManagerList,
});

const mapDispatchToProps = {
  onSetContents: setContents,
  onSetAssignAgentList: setAssignAgentList,
  onSetSalesManagerList: setSalesManagerList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
