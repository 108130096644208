import React from 'react';

const RoundUserIcons = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_19667_47973)">
          <path
            d="M10 0.25C4.62391 0.25 0.25 4.62391 0.25 10C0.25 15.3761 4.62391 19.75 10 19.75C15.3761 19.75 19.75 15.3761 19.75 10C19.75 4.62391 15.3761 0.25 10 0.25ZM7.64594 5.72594C8.23984 5.09641 9.07563 4.75 10 4.75C10.9244 4.75 11.7527 5.09875 12.3489 5.73156C12.9531 6.37281 13.247 7.23437 13.1777 8.16062C13.0389 10 11.6139 11.5 10 11.5C8.38609 11.5 6.95828 10 6.82234 8.16016C6.75344 7.22641 7.04688 6.36203 7.64594 5.72594ZM10 18.25C8.89866 18.2507 7.80838 18.0303 6.79382 17.6018C5.77927 17.1732 4.86109 16.5453 4.09375 15.7553C4.53323 15.1286 5.0932 14.5957 5.74094 14.1878C6.93578 13.4219 8.44797 13 10 13C11.552 13 13.0642 13.4219 14.2577 14.1878C14.9059 14.5955 15.4664 15.1284 15.9062 15.7553C15.139 16.5454 14.2208 17.1734 13.2062 17.6019C12.1917 18.0304 11.1014 18.2508 10 18.25Z"
            fill="#6D7176"
          />
        </g>
        <defs>
          <clipPath id="clip0_19667_47973">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default RoundUserIcons;
